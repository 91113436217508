import React, { FC, useEffect } from "react";
import "./Input.css";

interface SimpleInputProps {
    label: string;
    inputRef: React.RefObject<HTMLInputElement>;
    type?: string;
    name?: string;
    className?: string;
    defaultValue?: string;
    required?: boolean;
    hideLabel?: boolean;
    afterText?: string;
}

const SimpleInput: FC<SimpleInputProps> = ({
    label,
    type = "text",
    inputRef,
    name,
    className = "",
    defaultValue,
    required = false,
    hideLabel = false,
    afterText = "",
}) => {
    const inputID = label.toLowerCase().replace(/ /g, "-");

    useEffect(() => {
        if (defaultValue && inputRef.current) {
            inputRef.current.value = defaultValue;
        }
    }, [defaultValue, inputRef]);

    return (
        <div
            className={`inputWrapper simpleInput ${className} ${
                hideLabel ? "hideLabel" : ""
            } ${afterText ? "hasAfterText" : ""}`}
        >
            <input
                required={required}
                name={name || inputID}
                ref={inputRef}
                type={type}
                placeholder={label}
                id={inputID + "-input"}
            ></input>
            <label htmlFor={inputID + "-input"}>{label}</label>
            {afterText && <span className="afterText">{afterText}</span>}
        </div>
    );
};

export default SimpleInput;

// import React, { useEffect } from "react";

// function SimpleInput({ label, type, ref, defaultValue }) {
//     const inputID = label.toLowerCase().replace(/ /g, "-");
//     useEffect(() => {
//         if (defaultValue) {
//             ref.current.value = defaultValue;
//         }
//     }, []);

//     return (
//         <div className="inputWrapper">
//             <input
//                 ref={ref}
//                 type={type}
//                 placeholder={label}
//                 id={inputID + "-input"}
//             ></input>
//             <label htmlFor={inputID + "-input"}>{label}</label>
//         </div>
//     );
// }

// export default SimpleInput;
