import React, { FC, useState } from "react";
import { formateDate } from "../../util/helper";
import "./File.css";
import ButtonGroup from "../Buttons/ButtonGroup";
import Button from "../Buttons/Button";
import Card from "../Card/Card";
import Popup from "../Popup/Popup";
import ImageEditor from "../ImageEditor/ImageEditor";
import { useFolder } from "../../context/folderContext";
import { BsFile } from "../../types/Folder";

interface FileProps {
    file: BsFile;
    isSelected: boolean;
    setSelectFile: () => void;
    isOwner?: boolean;
}

const File: FC<FileProps> = ({
    file,
    isSelected = false,
    setSelectFile,
    isOwner = false,
}) => {
    const fileType = file.fileType.split("/")[0];
    const fileExtension = file.fileType.split("/")[1];
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isEditPopupOpen, setEditIsPopupOpen] = useState(false);
    const { handleDeleteFile } = useFolder();

    return (
        <Card
            name={file.fileName}
            className={
                "file" +
                (isSelected ? " selected" : "") +
                (file.isGenerated ? " generated" : "")
            }
            hero={
                fileType === "image" || fileType === "video" ? (
                    <img src={file.thumbnailUrl} alt={file.fileName} />
                ) : (
                    <span className="fileExtension">{fileExtension}</span>
                )
            }
            buttonGroup={
                <>
                    <Button
                        icon="download"
                        onClick={(e) => {
                            e.preventDefault();
                            const link = document.createElement("a");
                            link.href = file.fileUrl;
                            link.setAttribute("download", file.fileName);
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                        }}
                        tooltip="Download"
                    />
                    {isOwner && (
                        <>
                            <Button
                                icon="hide"
                                tooltip="Make Public"
                                onClick={() => {}}
                            />

                            {fileType === "image" && (
                                <Button
                                    icon="edit"
                                    tooltip="Edit"
                                    onClick={() => setEditIsPopupOpen(true)}
                                />
                            )}
                            {!file.isGenerated && (
                                <Button
                                    icon="delete"
                                    tooltip="Delete"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleDeleteFile([file._id]);
                                    }}
                                />
                            )}
                        </>
                    )}
                </>
            }
            onClick={() => {
                setSelectFile();
            }}
            onDoubleClick={() => {
                setIsPopupOpen(true);
            }}
        >
            <Popup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
                <div className="filePopup">
                    <div className="filePopup_hero">
                        {fileType === "image" || fileType === "video" ? (
                            <img src={file.fileUrl} alt={file.fileName} />
                        ) : (
                            <span className="fileExtension">
                                {fileExtension}
                            </span>
                        )}
                    </div>
                    <div className="filePopup_content">
                        <h3>{file.fileName}</h3>
                        <p className="smallText">
                            UD {formateDate(file.uploaded)}
                            <br />
                            LM {formateDate(file.lastModified)}
                        </p>
                        <ButtonGroup className="left">
                            <Button
                                name="Download"
                                icon="download"
                                onClick={() => {}}
                            />
                            <Button
                                name="Make Public"
                                icon="hide"
                                onClick={() => {}}
                            />
                            {fileType === "image" && (
                                <Button
                                    name="Crop"
                                    icon="edit"
                                    onClick={() => {
                                        setIsPopupOpen(false);
                                        setEditIsPopupOpen(true);
                                    }}
                                />
                            )}
                            <Button
                                name="Delete"
                                icon="delete"
                                onClick={() => {}}
                            />
                        </ButtonGroup>
                    </div>
                </div>
            </Popup>
            {fileType === "image" && (
                <Popup
                    isOpen={isEditPopupOpen}
                    onClose={() => setEditIsPopupOpen(false)}
                >
                    <ImageEditor
                        src={file.fileUrl}
                        onClose={() => setEditIsPopupOpen(false)}
                    />
                </Popup>
            )}
        </Card>
    );
};

export default File;
