import React from "react";
import { useCompanyStyle } from "../../context/styleContext";
import "./PortalLogo.css";

function PortalLogo() {
    const { brand } = useCompanyStyle();

    if (!brand) return null;
    return (
        <div className="portalLogo">
            {brand.style.logo ? (
                <img src={brand.style.logo} alt={brand.name} />
            ) : (
                <h1 className="h3Text">{brand.name}</h1>
            )}
        </div>
    );
}

export default PortalLogo;
