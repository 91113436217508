import React, { FC } from "react";
import Page from "./Page";
import PortalLogo from "./PortalLogo";
import "./Portal.css";

interface PortalProps {
    title: string;
    hero?: React.ReactNode;
    children?: React.ReactNode;
    className?: string;
}

const Portal: FC<PortalProps> = ({ title, hero, children, className = "" }) => {
    return (
        <Page title={title} hideHeader={true} className={`portal ${className}`}>
            <div className="portalBody">
                <PortalLogo />
                {children}
            </div>
            <div className="portalHero">
                {hero ? (
                    hero
                ) : (
                    <div className="portalHeroContent">
                        <h1>Create. Generate. Deliver.</h1>
                        <p>
                            Optimize your design agency's workflow with Brand
                            Set's automated brand guide generator and
                            easy-to-use asset sharing platform.
                        </p>
                    </div>
                )}
            </div>
        </Page>
    );
};

export default Portal;
