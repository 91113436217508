import React from "react";
import { Routes, Route } from "react-router-dom";
import ProjectProvider from "../../context/projectContext";
import Folder from "./Folder";
import Colors from "./Colors";
import BrandGuide from "./BrandGuide";
import ProjectInfo from "./ProjectInfo";
import FolderProvider from "../../context/folderContext";

function ProjectRouter() {
    return (
        <ProjectProvider>
            <Routes>
                <Route path="/" element={<ProjectInfo />} />
                <Route path="/brandguide" element={<BrandGuide />} />
                <Route path="/colors" element={<Colors />} />
                <Route
                    path="/:folderSlug/:subFolderSlug?"
                    element={
                        <FolderProvider>
                            <Folder />
                        </FolderProvider>
                    }
                />
            </Routes>
        </ProjectProvider>
    );
}

export default ProjectRouter;
