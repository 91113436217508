import axios from "axios";
import { confirm } from "../util/helper";

export const getCompany = async () => {
    const res = await axios.get("/company");
    return res.data;
};

type GetCompanyStyleRequest = {
    customDomain: string;
    isDomain: boolean;
};

export const getCompanyStyle = async ({
    customDomain,
    isDomain,
}: GetCompanyStyleRequest) => {
    const res = await axios.get(`/company/style/${customDomain}/${isDomain}`);
    return res.data;
};

type CreateCompanyRequest = {
    name: string;
    subdomain: string;
};

export const createCompany = async (data: CreateCompanyRequest) => {
    return await axios.post("/company", data);
};

type CreateClientCompanyRequest = {
    name: string;
};

export const createClientCompany = async (data: CreateClientCompanyRequest) => {
    return await axios.post("/company/client", data);
};

export const getClientCompanies = async () => {
    const res = await axios.get("/company/clients");
    return res.data;
};

type UpdateCompanyRequest = {
    name: string;
    companyID: string;
};

export const updateCompany = async (data: UpdateCompanyRequest) => {
    return await axios.put("/company/update", data);
};

type UpdateCompanyDomainRequest = {
    companyID: string;
    domain: string;
};

export const updateDomain = async (data: UpdateCompanyDomainRequest) => {
    return await axios.put("/company/update-domain", data);
};

type RemoveCompanyDomainRequest = {
    companyID: string;
};

export const removeDomain = async (data: RemoveCompanyDomainRequest) => {
    return await axios.put("/company/remove-domain", data);
};

type UpdateCompanyStyleRequest = {
    companyID: string;
    style: any;
    logo: any;
    logoInverse: any;
    favicon: any;
};

export const updateCompanyStyle = async (
    data: UpdateCompanyStyleRequest,
    config: any
) => {
    return await axios.put("/company/update-style", data, config);
};

type DeleteCompanyRequest = {
    companyID: string;
};

export const deleteCompany = async (data: DeleteCompanyRequest) => {
    if (!confirm("Are you sure you want to delete this company?")) return;
    return await axios.put("/company/delete", data);
};

export const getMemberOfCompanies = async () => {
    const res = await axios.get("/company/memberof");
    return res.data;
};

export const getCompanyClients = async () => {
    const res = await axios.get("/company/clients");
    return res.data;
};

export default axios;
