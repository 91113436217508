import React, { FC } from "react";
import "./Button.css";

type RadioButtonProps = {
    name: string;
    onChange: (value: string) => void;
    value?: string;
    label?: string;
    icon?: string;
    iconOnly?: boolean;
    className?: string;
    hasTooltip?: boolean;
    checked?: boolean;
};

const RadioButton: FC<RadioButtonProps> = ({
    onChange,
    name,
    value = name,
    label,
    className = "",
    icon = "",
    iconOnly = true,
    hasTooltip = false,
    checked = false,
    ...rest
}) => {
    return (
        <label
            className={`button radio ${className} ${
                hasTooltip ? "hasTooltip" : ""
            } ${checked ? "active" : ""}`}
            aria-label={value}
            data-tooltip={value}
            {...rest}
        >
            <input
                type="radio"
                name={name}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                checked={checked}
            />
            {!!icon && <span className={`icon csic csic-${icon}`}></span>}
            {!!label && <span className="text">{label}</span>}
        </label>
    );
};

export default RadioButton;
