import React from "react";
import { NavLink } from "react-router-dom";
import { deleteFolder } from "../../api/folderApi";
import { useProjects } from "../../context/projectsContext";
import { useUser } from "../../context/userContext";
import Button from "../Buttons/Button";
import NavLinkButton from "../Buttons/NavLinkButton";
import "./ProjectList.css";
import { switchColor } from "../../util/colorHelper";
import { folderIcon } from "../../util/File/helper";
import AddFolder from "../../Popups/AddFolder";
import { useCompany } from "../../context/companyContext";

function ProjectList() {
    const {
        projects,
        projectsStatus,
        sharedProjects,
        sharedProjectsStatus,
        fetchProjectBySlug,
    } = useProjects();
    const { user } = useUser();
    const { company } = useCompany();

    function handleDeleteFolder(
        e: React.MouseEvent<HTMLButtonElement>,
        folderID: string,
        projectSlug: string
    ) {
        e.preventDefault();
        deleteFolder(folderID).then((res) => {
            if (res) {
                fetchProjectBySlug(projectSlug);
            }
        });
    }

    if (
        !user ||
        !company ||
        !projects ||
        projectsStatus === "loading" ||
        !sharedProjects ||
        sharedProjectsStatus === "loading"
    ) {
        return (
            <li className="addPadding">
                <p>Loading...</p>
            </li>
        );
    } else if (projectsStatus === "error") {
        return (
            <li className="addPadding">
                <p>Error</p>
            </li>
        );
    } else if (projects.length === 0 && sharedProjects.length === 0) {
        return (
            <li className="addPadding">
                <p>No Project fount :(</p>
            </li>
        );
    } else
        return (
            <>
                {[...projects, ...sharedProjects].map((project, i) => {
                    return (
                        <li key={project._id} className="projectMenuItem">
                            <NavLink
                                to={`/project/${project.slug}`}
                                className="button navLinkButton"
                            >
                                <span
                                    className="projectIcon"
                                    style={{
                                        backgroundColor: project.colors.primary,
                                        color: switchColor(
                                            project.colors.primary
                                        ),
                                    }}
                                >
                                    {project.name[0] +
                                        (project.name.split(" ")[1]?.[0] || "")}
                                </span>
                                <span className="text">{project.name}</span>
                            </NavLink>
                            {/* <Button icon="arrow-d" onClick={(e) => switchProjectTab(i)} /> */}
                            <ul className="projectSubMenu">
                                <li>
                                    <NavLinkButton
                                        link={`/project/${project.slug}/brandguide`}
                                        icon="grid"
                                    >
                                        Brand Guide
                                    </NavLinkButton>
                                </li>
                                <li>
                                    <NavLinkButton
                                        link={`/project/${project.slug}/colors`}
                                        icon="colors"
                                    >
                                        Colors
                                    </NavLinkButton>
                                </li>
                                {project.folder.map((folder) => {
                                    if (folder.parentFolderID) return null;
                                    return (
                                        <li
                                            key={folder._id}
                                            className={`floatingContainer`}
                                        >
                                            <NavLinkButton
                                                link={`/project/${project.slug}/${folder.slug}`}
                                                icon={folderIcon(folder.slug)}
                                            >
                                                {folder.name}
                                            </NavLinkButton>
                                            {!["logos", "pictures"].includes(
                                                folder.slug
                                            ) && (
                                                <Button
                                                    icon="delete"
                                                    className="remove floating"
                                                    onClick={(e) =>
                                                        handleDeleteFolder(
                                                            e,
                                                            folder.folderID,
                                                            project.slug
                                                        )
                                                    }
                                                />
                                            )}
                                        </li>
                                    );
                                })}
                                {!company.isClientOrg && (
                                    <li>
                                        <AddFolder
                                            projectID={project._id}
                                            projectSlug={project.slug}
                                            className="navLinkButton"
                                        />
                                    </li>
                                )}
                            </ul>
                        </li>
                    );
                })}
            </>
        );
}

export default ProjectList;
