import React from "react";
import Page from "../../components/Templates/Page";
import { useUser } from "../../context/userContext";
import { useCompany } from "../../context/companyContext";
import Loading from "../../components/Elements/Loading";
import NavLinkButton from "../../components/Buttons/NavLinkButton";
import { Route, Routes } from "react-router-dom";
import EditProfile from "./EditProfile";
import Billing from "./Billing";
import CustomizePortal from "../Settings/CustomizePortal";
import CompanyAdmin from "./CompanyAdmin";
import "./Settings.css";
import Security from "./Security";
import CustomDomain from "./CustomDomain";
import Team from "./Team";

const Settings = () => {
    const { user } = useUser();
    const { company } = useCompany();

    if (!user) return <Loading />;

    return (
        <Page
            title="Settings"
            subtitle={company?.name || "Brand Set"}
            className="settingsPage"
        >
            <div className="settingsMenu">
                {!!company && (
                    <>
                        {!company.isClientOrg && (
                            <>
                                <NavLinkButton
                                    link="/settings/org-profile"
                                    icon="settings"
                                >
                                    Organization Details
                                </NavLinkButton>
                                <NavLinkButton
                                    link="/settings/branding"
                                    icon="edit"
                                >
                                    Branding
                                </NavLinkButton>

                                <NavLinkButton
                                    link="/settings/domain"
                                    icon="web"
                                >
                                    Custom Domain
                                </NavLinkButton>
                                <NavLinkButton
                                    link="/settings/billing"
                                    icon="card"
                                >
                                    Billing
                                </NavLinkButton>
                            </>
                        )}
                        <NavLinkButton link="/settings/team" icon="users">
                            Team
                        </NavLinkButton>
                    </>
                )}
                <p className="userSettingsTitle">
                    {user.firstName} {user.lastName}
                </p>
                <NavLinkButton link="/settings/user-profile" icon="user">
                    Your Account
                </NavLinkButton>
                <NavLinkButton link="/settings/security" icon="lock">
                    Security
                </NavLinkButton>
            </div>
            <div className="settingsContent">
                <Routes>
                    <Route index element={<EditProfile />} />
                    <Route path="user-profile" element={<EditProfile />} />
                    <Route path="security" element={<Security />} />
                    <Route path="org-profile" element={<CompanyAdmin />} />
                    <Route path="branding" element={<CustomizePortal />} />
                    <Route path="domain" element={<CustomDomain />} />
                    <Route path="billing" element={<Billing />} />
                    <Route path="team" element={<Team />} />
                </Routes>
            </div>
        </Page>
    );
};

export default Settings;
