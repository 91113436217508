export default function createImageThumbnail(src, size = 300) {
    return new Promise((resolve, reject) => {
        if (!src) {
            reject("Invalid arguments");
            return;
        }
        const img = new Image();
        img.onload = function () {
            const canvas = document.createElement("canvas");
            canvas.width = size;
            canvas.height = size;
            const ctx = canvas.getContext("2d");
            const scale = Math.max(size / img.width, size / img.height);
            const x = (size - img.width * scale) / 2;
            const y = (size - img.height * scale) / 2;
            ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
            canvas.toBlob((blob) => resolve(blob), "image/jpeg", 0.8);
        };
        img.onerror = (e) => reject("Image load failed");
        img.src = src;
    });
}
