import React, { FC, useState, useRef, useEffect } from "react";
import "./OTPInput.css";

interface OTPInputProps {
    inputRef: React.RefObject<HTMLInputElement>;
    className?: string;
}

const OTPInput: FC<OTPInputProps> = ({ inputRef, className }) => {
    const [otp, setOTP] = useState<string[]>(["", "", "", "", "", ""]);
    const otpRef = useRef<HTMLDivElement>(null);

    function handleOTPChange(
        e: React.ChangeEvent<HTMLInputElement>,
        i: number
    ) {
        const value = e.target.value.replace(/\D/g, "");

        setOTP((prev) => {
            const newOTP = [...prev];
            newOTP[i] = value;
            return newOTP;
        });
        if (value && i < 5) {
            handleFocus(i + 1);
        }
    }

    function handleKeyDown(
        e: React.KeyboardEvent<HTMLInputElement>,
        i: number
    ) {
        if (["Backspace", "Delete", "ArrowLeft"].includes(e.key) && i > 0) {
            if (e.key === "Backspace" || e.key === "Delete") {
                e.preventDefault();
                setOTP((prev) => {
                    const newOTP = [...prev];
                    newOTP[i] = "";
                    return newOTP;
                });
            }
            handleFocus(i - 1);
        } else if (e.key === "ArrowRight" && i < 5) {
            handleFocus(i + 1);
        }
    }

    function handleFocus(i: number) {
        const input = otpRef.current?.querySelectorAll("input")[i];
        if (input) {
            input.focus();
        }
    }

    function handlePaste(e: React.ClipboardEvent<HTMLInputElement>) {
        e.preventDefault();
        const paste = e.clipboardData.getData("text");

        if (paste) {
            const newOTP = parseInt(paste).toString().split("").slice(0, 6);
            if (newOTP.length !== 6) {
                for (let i = newOTP.length; i < 6; i++) {
                    newOTP.push("");
                }
            }
            setOTP(newOTP);
        }
    }

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.value = otp.join("");
        }
    }, [otp, inputRef]);

    return (
        <div className={`inputWrapper otp ${className}`}>
            <div ref={otpRef}>
                <span>-</span>
                {otp.map((digit, i) => (
                    <input
                        key={i}
                        type="tel"
                        maxLength={1}
                        value={digit}
                        onChange={(e) => handleOTPChange(e, i)}
                        onPaste={handlePaste}
                        onKeyDown={(e) => handleKeyDown(e, i)}
                        autoComplete="off"
                    />
                ))}
            </div>
            <input type="hidden" ref={inputRef} />
            <span className="smallText">Enter 6 digit OTP code</span>
        </div>
    );
};

export default OTPInput;
