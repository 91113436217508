import React, { useState, useEffect } from "react";
import ColorCard from "../../components/Color/ColorCard";
import GradientCard from "../../components/Color/GradientCard";
import FontPreview from "../../components/Font/FontPreview";
import Loading from "../../components/Elements/Loading";
import Page from "../../components/Templates/Page";
import { useProject } from "../../context/projectContext";
import { calculateTypeStyle } from "../../util/Font/calculateTypeStyle";
import { loadFont } from "../../util/helper";
import TypeScalePreview from "../../components/Font/TypeScalePreview";
import SVG from "../../components/Elements/SVG";
import ProjectBlock from "../../components/Project/ProjectBlock";
import ImgBlock from "../../components/Project/ImgBlock";
import CSSCode from "../../components/Templates/CSSCode";
import { toLinearGradient } from "../../util/Color/getGradients";
import "./BrandGuide.css";
import ButtonGroup from "../../components/Buttons/ButtonGroup";
import Button from "../../components/Buttons/Button";
import { downloadZip } from "../../util/SVG/download";
import { useNotification } from "../../context/notificationContext";

function BrandGuide() {
    const { project, brandguide, logos, isLoading, isValidSlug } = useProject();
    const [typeStyle, setTypeStyle] = useState<any>();
    const { sendNotification, updateNotification } = useNotification();

    useEffect(() => {
        if (brandguide && project) {
            loadFont(brandguide.headingFont);
            loadFont(brandguide.subHeadingFont);
            loadFont(brandguide.bodyFont);
            if (brandguide.typescale) {
                setTypeStyle(calculateTypeStyle(brandguide.typescale));
            }
        }
    }, [project, brandguide, logos]);

    if (
        isLoading ||
        !project ||
        !brandguide ||
        logos.length === 0 ||
        !typeStyle
    ) {
        return <Loading />;
    }

    if (!isValidSlug) {
        return <Page title="404">Project not found</Page>;
    }

    return (
        <Page
            title="Brandguide"
            subtitle={project.name}
            className="brandGuidePage"
            style={
                {
                    "--primary": brandguide.colors[0].code,
                    "--secondary":
                        brandguide.colors[1]?.code || brandguide.colors[0].code,
                    "--heading_font": brandguide.headingFont.family,
                    "--sub_heading_font": brandguide.subHeadingFont.family,
                    "--paragraph_font": brandguide.bodyFont.family,
                    "--body_size": brandguide.typescale.fontSize,
                    "--h1_size": typeStyle.h1.em + "rem",
                    "--h2_size": typeStyle.h2.em + "rem",
                    "--h3_size": typeStyle.h3.em + "rem",
                    "--h4_size": typeStyle.h4.em + "rem",
                    "--h5_size": typeStyle.h5.em + "rem",
                    "--caption_size": typeStyle.caption.em + "rem",
                    "--small_size": typeStyle.small.em + "rem",
                } as React.CSSProperties
            }
        >
            <ProjectBlock boxed={false} className="cover centeredLogo">
                <ImgBlock src={logos[0].fileUrl} alt="logo" />
            </ProjectBlock>
            <ProjectBlock
                header={<h2>Logo</h2>}
                className="logoVariations"
                contentC="col col-2"
            >
                <ImgBlock src={logos[0].fileUrl} alt="Logo" />
                <ImgBlock src={logos[1].fileUrl} alt="Logo Primary" />
                <ImgBlock src={logos[1].fileUrl} alt="Logo White" />
                <ImgBlock src={logos[2].fileUrl} alt="Logo Black" />
            </ProjectBlock>

            {!!logos[3] && (
                <ProjectBlock
                    header={<h2>Logo Mark</h2>}
                    className="logoVariations logomark"
                    contentC="col col-2"
                >
                    <ImgBlock src={logos[3].fileUrl} alt="Logomark" />
                    <ImgBlock src={logos[4].fileUrl} alt="Logomark Primary" />
                    <ImgBlock src={logos[4].fileUrl} alt="Logomark White" />
                    <ImgBlock src={logos[5].fileUrl} alt="Logomark Black" />
                </ProjectBlock>
            )}

            <ProjectBlock
                header={
                    <>
                        <h2>Safe Zone</h2>
                        <p>
                            The logo should always be placed within the safe
                            zone. This is the area where the logo will not be
                            cropped.
                        </p>
                    </>
                }
                className="safeZone"
            >
                {/* <SVG src={logos[0].fileUrl as string} /> */}
                <div className="safeZoneContainer">
                    <div className="safeZoneSpacer">
                        <div>
                            <img src={logos[0].fileUrl} alt="safezone" />
                        </div>
                    </div>
                </div>
            </ProjectBlock>

            <ProjectBlock
                header={<h2>Prohibited use of the logo</h2>}
                className="useOfLogo"
                contentC="col col-3"
            >
                <div className="color">
                    <p>
                        <span>Do not use</span>
                        Other colors then the original
                    </p>
                    <SVG src={logos[0].fileUrl as string} />
                </div>
                <div className="skew">
                    <p>
                        <span>Do not use</span>
                        Skewed or warped the logo
                    </p>
                    <SVG src={logos[0].fileUrl as string} />
                </div>
                <div className="rotate">
                    <p>
                        <span>Do not use</span>
                        Rotated version of the logo
                    </p>
                    <SVG src={logos[0].fileUrl as string} />
                </div>
                <div className="bg">
                    <p>
                        <span>Do not use</span>
                        Similar background colors
                    </p>
                    <SVG src={logos[0].fileUrl as string} />
                </div>
                <div className="shadow">
                    <p>
                        <span>Do not use</span>
                        Shadow or glow effects
                    </p>
                    <SVG src={logos[0].fileUrl as string} />
                </div>
                <div className="filter">
                    <p>
                        <span>Do not use</span>
                        Filter or other effects
                    </p>
                    <SVG src={logos[0].fileUrl as string} />
                </div>
            </ProjectBlock>

            {brandguide.colors.length > 0 && (
                <ProjectBlock
                    header={
                        <>
                            <h2>Colors</h2>
                            <p>
                                Our primary color is {brandguide.colors[0].name}
                                . It is supported by{" "}
                                {brandguide.colors
                                    .slice(1)
                                    .map((color) => color.name)
                                    .join(", ")}
                                .
                            </p>
                        </>
                    }
                    options={
                        <CSSCode
                            name="CSS Color Code"
                            css={`
                                .primary {
                                    color: ${brandguide.colors[0].code};
                                }

                                .secondary {
                                    color: ${brandguide.colors[1].code};
                                }
                                ${brandguide.colors.length > 2
                                    ? brandguide.colors
                                          .slice(2)
                                          .map(
                                              (color, i) => `
                                .color-${i} {
                                    color: ${color.code};
                                }`
                                          )
                                          .join("\n")
                                    : ""}
                            `}
                            cssvars={`
                            :root{
                                --primary: ${brandguide.colors[0].code};
                                --secondary: ${brandguide.colors[1].code};${
                                brandguide.colors.length > 2
                                    ? brandguide.colors
                                          .slice(2)
                                          .map(
                                              (color, i) => `
                                --color-${i}: ${color.code};`
                                          )
                                          .join("")
                                    : ""
                            }
                            }`}
                            saas={`$primary: ${
                                brandguide.colors[0].code
                            };\n$secondary: ${brandguide.colors[1].code};\n${
                                brandguide.colors.length > 2
                                    ? brandguide.colors
                                          .slice(2)
                                          .map(
                                              (color, i) =>
                                                  "$color-" +
                                                  i +
                                                  ": " +
                                                  color.code +
                                                  ";"
                                          )
                                          .join("\n")
                                    : ""
                            }`}
                            tailwind={`
                            // tailwind.config.js
                            module.exports = {
                                theme: {
                                    extend: {
                                        colors: {
                                            'primary': '${
                                                brandguide.colors[0].code
                                            }',
                                            'secondary': '${
                                                brandguide.colors[1].code
                                            }',${
                                brandguide.colors.length > 2
                                    ? brandguide.colors
                                          .slice(2)
                                          .map(
                                              (color, i) => `
                                            'color-${i}': '${color.code}',`
                                          )
                                          .join("")
                                    : ""
                            }
                                        }
                                    }
                                }
                            }`}
                        />
                    }
                    className="colors"
                    contentC="col col-3"
                >
                    {brandguide.colors.map((color, i) => {
                        return <ColorCard key={"color" + i} color={color} />;
                    })}
                </ProjectBlock>
            )}

            {brandguide.gradients.length > 0 && (
                <ProjectBlock
                    header={<h2>Gradients</h2>}
                    className="gradients"
                    contentC="col col-3"
                    options={
                        <CSSCode
                            name="CSS Color Code"
                            cssvars={`:root{${brandguide.gradients
                                .map((g, i) => {
                                    return `\n\t--gradient-${i}: ${toLinearGradient(
                                        g
                                    )};`;
                                })
                                .join("")}\n}`}
                            css={`
                                ${brandguide.gradients
                                    .map(
                                        (g, i) => `
                                .gradient-${i} {
                                    background-image: ${toLinearGradient(g)};
                                }`
                                    )
                                    .join("\n")}
                            `}
                            saas={`${brandguide.gradients
                                .map(
                                    (g, i) =>
                                        `$gradient-${i}: ${toLinearGradient(
                                            g
                                        )};`
                                )
                                .join("\n")}`}
                            tailwind={`
                            // tailwind.config.js
                            module.exports = {
                                theme: {
                                    extend: {
                                        backgroundImage: {${brandguide.gradients
                                            .map(
                                                (g, i) => `
                                            'gradient-${i}': '${toLinearGradient(
                                                    g
                                                )}',`
                                            )
                                            .join("")}
                                        }
                                    }
                                }
                            }`}
                        />
                    }
                >
                    {brandguide.gradients.map((gradient, i) => {
                        return (
                            <GradientCard
                                key={"gradient" + i}
                                gradient={gradient}
                            />
                        );
                    })}
                </ProjectBlock>
            )}
            <ProjectBlock
                header={<h2>Fonts</h2>}
                className="fonts"
                contentC="col col-3"
                options={
                    <ButtonGroup>
                        <Button
                            tooltip="Download fonts"
                            icon="download"
                            onClick={() => {
                                downloadZip(
                                    [
                                        brandguide.headingFont.link || "",
                                        brandguide.subHeadingFont.link || "",
                                        brandguide.bodyFont.link || "",
                                    ],
                                    "Fonts",
                                    sendNotification,
                                    updateNotification,
                                    [
                                        brandguide.headingFont.family +
                                            "-Heading",
                                        brandguide.subHeadingFont.family +
                                            "-Subheading",
                                        brandguide.bodyFont.family + "-Body",
                                    ]
                                );
                            }}
                        />
                        <CSSCode
                            name="CSS Font Code"
                            cssvars={`
                        :root{
                            --heading_font: '${brandguide.headingFont.family}';
                            --sub_heading_font: '${brandguide.subHeadingFont.family}';
                            --body_font: '${brandguide.bodyFont.family}';
                        }`}
                            css={`
                                body .body_font {
                                    font-family: "${brandguide.bodyFont
                                        .family}";
                                }

                                ${"h1, h2, h3, h4, h5, "}.heading_font {
                                    font-family: "${brandguide.headingFont
                                        .family}";
                                }

                                .sub_heading_font {
                                    font-family: "${brandguide.subHeadingFont
                                        .family}";
                                }
                            `}
                            saas={`
                        $heading_font: '${brandguide.headingFont.family}';
                        $sub_heading_font: '${brandguide.subHeadingFont.family}';
                        $body_font: '${brandguide.bodyFont.family}';
                        `}
                            tailwind={`
                        // tailwind.config.js
                        module.exports = {
                            theme: {
                                fontFamily: {
                                    'heading': '${brandguide.headingFont.family}',
                                    'sub-heading': '${brandguide.subHeadingFont.family}',
                                    'body': '${brandguide.bodyFont.family}',
                                }
                            }
                        }`}
                        />
                    </ButtonGroup>
                }
            >
                <FontPreview
                    name={brandguide.headingFont.family}
                    // type="For Headlines"
                />
                <FontPreview
                    name={brandguide.subHeadingFont.family}
                    // type="For Headlines & Subheadlines"
                />
                <FontPreview
                    name={brandguide.bodyFont.family}
                    // type="For Body Text"
                />
            </ProjectBlock>
            <ProjectBlock
                header={<h2>Typographic Scale</h2>}
                className="typographicScale"
                options={
                    <CSSCode
                        name="CSS Font Code"
                        cssvars={`
                        :root{
                            --body_size: ${brandguide.typescale.fontSize}px;
                            --h1_size: ${typeStyle.h1.em}rem;
                            --h2_size: ${typeStyle.h2.em}rem;
                            --h3_size: ${typeStyle.h3.em}rem;
                            --h4_size: ${typeStyle.h4.em}rem;
                            --h5_size: ${typeStyle.h5.em}rem;
                            --caption_size: ${typeStyle.caption.em}rem;
                            --small_size: ${typeStyle.small.em}rem;
                        }`}
                        css={`
                            body {
                                font-family: "${brandguide.bodyFont.family}";
                                font-size: ${brandguide.typescale.fontSize}px;
                                font-weight: ${brandguide.bodyFont.weight};
                                line-height: 1.65em;
                            }

                            ${"h1, h2, h3, h4, h5, "}.heading_font {
                                font-family: "${brandguide.headingFont.family}";
                                font-weight: ${brandguide.headingFont.weight};
                            }

                            ${"h1, .h1"} {
                                font-size: ${typeStyle.h1.em}rem;
                            }

                            ${"h2, .h2"} {
                                font-size: ${typeStyle.h2.em}rem;
                            }

                            ${"h3, .h3"} {
                                font-size: ${typeStyle.h3.em}rem;
                            }

                            ${"h4, .h4"} {
                                font-size: ${typeStyle.h4.em}rem;
                            }

                            ${"h5, .h5"} {
                                font-size: ${typeStyle.h5.em}rem;
                            }

                            .caption {
                                font-size: ${typeStyle.caption.em}rem;
                            }

                            .small {
                                font-size: ${typeStyle.small.em}rem;
                            }
                        `}
                        saas={`
                        $text-size-xs: ${typeStyle.small.em}rem;
                        $text-size-sm: ${typeStyle.caption.em}rem;
                        $text-size-base: ${brandguide.typescale.fontSize}px;
                        $text-size-lg: ${typeStyle.h5.em}rem;
                        $text-size-xl: ${typeStyle.h4.em}rem;
                        $text-size-2xl: ${typeStyle.h3.em}rem;
                        $text-size-3xl: ${typeStyle.h2.em}rem;
                        $text-size-4xl: ${typeStyle.h1.em}rem;
                        `}
                        tailwind={`
                        // tailwind.config.js
                        module.exports = {
                            theme: {
                                fontSize: {
                                    'sm': '${typeStyle.small.em}rem',
                                    'tiny': '${typeStyle.caption.em}rem',
                                    'base': '${brandguide.typescale.fontSize}px',
                                    'lg': '${typeStyle.h5.em}rem',
                                    'xl': '${typeStyle.h4.em}rem',
                                    '2xl': '${typeStyle.h3.em}rem',
                                    '3xl': '${typeStyle.h2.em}rem',
                                    '4xl': '${typeStyle.h1.em}rem',
                                }
                            }
                        }`}
                    />
                }
            >
                <TypeScalePreview
                    typeStyle={typeStyle}
                    typescale={brandguide.typescale}
                />
            </ProjectBlock>
            <div className="stickyOptions">
                {/* <PrintProject brandguide={brandguide} project={project} /> */}
            </div>
        </Page>
    );
}

export default BrandGuide;
