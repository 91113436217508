import React, { FC, useState } from "react";
import Button from "../Buttons/Button";
import Popup from "../Popup/Popup";
import ButtonGroup from "../Buttons/ButtonGroup";
import "./CSSCode.css";
import { copy } from "../../util/helper";
import { useNotification } from "../../context/notificationContext";

type CSSCodeProps = {
    name: string;
    cssvars: string;
    css: string;
    saas: string;
    tailwind: string;
};

function dedent(str: string) {
    // Match the smallest indentation
    const match = str.match(/^[ \t]*(?=\S)/gm);
    if (!match) return str;

    const indent = Math.min(...match.map((x) => x.length));
    const re = new RegExp(`^[ \\t]{${indent}}`, "gm");
    str = indent > 0 ? str.replace(re, "") : str;

    // Remove the first line break if there is any
    str = str.replace(/^[\r\n]+/, "");

    return str;
}

const CSSCode: FC<CSSCodeProps> = ({ name, css, cssvars, saas, tailwind }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [tab, setTab] = useState(0);
    const { sendNotification } = useNotification();

    return (
        <>
            <Button
                tooltip="Code"
                icon="code"
                onClick={() => setIsOpen(true)}
            />
            <Popup
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                style={{ "--width": "600px" } as React.CSSProperties}
            >
                <ButtonGroup className="tabs">
                    <Button
                        name="CSS Vars"
                        className={`${tab === 0}`}
                        onClick={() => setTab(0)}
                    />
                    <Button
                        name="CSS"
                        className={`${tab === 1}`}
                        onClick={() => setTab(1)}
                    />
                    <Button
                        name="SCSS"
                        className={`${tab === 2}`}
                        onClick={() => setTab(2)}
                    />
                    <Button
                        name="Tailwind"
                        className={`${tab === 3}`}
                        onClick={() => setTab(3)}
                    />
                </ButtonGroup>
                <div className="tabContainer floatingContainer">
                    <code className={`tab ${tab === 0}`}>
                        <Button
                            tooltip="Copy"
                            icon="clipboard"
                            onClick={() => {
                                copy(cssvars, sendNotification);
                                setIsOpen(false);
                            }}
                            className="floating top_right"
                        />
                        <pre>{dedent(cssvars)}</pre>
                    </code>
                    <code className={`tab ${tab === 1}`}>
                        <Button
                            tooltip="Copy"
                            icon="clipboard"
                            onClick={() => {
                                copy(css, sendNotification);
                                setIsOpen(false);
                            }}
                            className="floating top_right"
                        />
                        <pre>{dedent(css)}</pre>
                    </code>
                    <code className={`tab ${tab === 2}`}>
                        <Button
                            tooltip="Copy"
                            icon="clipboard"
                            onClick={() => {
                                copy(saas, sendNotification);
                                setIsOpen(false);
                            }}
                            className="floating top_right"
                        />
                        <pre>{dedent(saas)}</pre>
                    </code>
                    <code className={`tab ${tab === 3}`}>
                        <Button
                            tooltip="Copy"
                            icon="clipboard"
                            onClick={() => {
                                copy(tailwind, sendNotification);
                                setIsOpen(false);
                            }}
                            className="floating top_right"
                        />
                        <pre>{dedent(tailwind)}</pre>
                    </code>
                </div>
            </Popup>
        </>
    );
};

export default CSSCode;
