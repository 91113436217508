import axios from "axios";
import { confirm } from "../util/helper";

export const createProject = async (data: any, config: any) => {
    return await axios.post("/project/", data, config);
};

export const getProject = async (slug: string) => {
    const res = await axios.get(`/project/slug/${slug}`);
    return res.data;
};

export const getBrandGuide = async (projectID: string) => {
    const res = await axios.get(`/project/brand-guide/${projectID}`);
    return res.data;
};

type UpdateProject = {
    name: string;
    slug: string;
    projectID: string;
};
export const updateProject = async (data: UpdateProject) => {
    return await axios.put(`/project/update`, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const deleteProject = async (projectID: string) => {
    if (!confirm("Are you sure you want to delete the project?")) return;
    return await axios.delete(`/project/delete/${projectID}`);
};

export const getAllProjects = async ({ pageParam = 1 }) => {
    const res = await axios.get("/project/all/?page=" + pageParam);
    return res.data;
};

export const getAllProjectsPageCount = async () => {
    const res = await axios.get("/project/all/page-count");
    return res.data;
};

export const getSharedProjects = async ({ pageParam = 1 }) => {
    const res = await axios.get("/project/shared/?page=" + pageParam);
    return res.data;
};

export const getSharedProjectsPageCount = async () => {
    const res = await axios.get("/project/shared/page-count");
    return res.data;
};

type ShareProject = {
    projectID: string;
    companyID: string;
};

export const shareProject = async (data: ShareProject) => {
    return await axios.put("/project/share", data);
};

type RemoveAccess = {
    projectID: string;
    companyID: string;
};

export const removeAccess = async (data: RemoveAccess) => {
    return await axios.put("/project/remove-access", data);
};

export default axios;
