import React, { useEffect, useRef } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { createCompany } from "../../api/companyApi";
import SimpleInput from "../../components/Inputs/SimpleInput";
import Portal from "../../components/Templates/Portal";
import Content from "../../components/Templates/Content";
import CForm from "../../components/Inputs/CForm";
import { handleSlugInput, isTextValid } from "../../util/validator";
import { useNotification } from "../../context/notificationContext";

export default function CreateCompany() {
    const newName = useRef<HTMLInputElement>(null);
    const newSubdomain = useRef<HTMLInputElement>(null);

    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { sendNotification } = useNotification();

    async function handleNewCompany() {
        const data = {
            name: newName.current?.value ?? "",
            subdomain: newSubdomain.current?.value ?? "",
        };

        if (!isTextValid(data.name) && !isTextValid(data.subdomain)) {
            sendNotification(
                "Please enter a valid name and subdomain",
                "error"
            );
            return;
        }

        await createCompany(data).then(() => {
            queryClient.invalidateQueries(["user"]);
            navigate("/");
        });
    }

    useEffect(() => {
        if (!newName.current || !newSubdomain.current) return;
        const cleanup = handleSlugInput(newName.current, newSubdomain.current);
        return () => cleanup();
    }, []);

    return (
        <Portal title="Create Company">
            <Content>
                <h3>Setup your company</h3>
                <p className="captionText">
                    You need to setup your company account to get started
                </p>
            </Content>
            <CForm onSubmit={handleNewCompany} button="Create Company">
                <SimpleInput label="Company Name" inputRef={newName} />
                <SimpleInput
                    label="Subdomain"
                    inputRef={newSubdomain}
                    afterText=".brandset.io"
                />
                <p className="smallText">
                    You can later configure <b>your own custom domain</b>{" "}
                    instead of .brandset.io
                </p>
            </CForm>
        </Portal>
    );
}
