import axios from "axios";
import { ClientInvite } from "../types/User";

export const memberInvites = async () => {
    const res = await axios.get("/invite/members");
    return res.data;
};

export const clientInvites = async () => {
    const res = await axios.get("/invite/clients");
    return res.data as ClientInvite[];
};

type InviteMember = {
    email: string;
};

export const inviteMember = async (data: InviteMember) => {
    return await axios.post("/invite/member", data);
};

type InviteClient = {
    email: string;
    companyID: string;
};
export const inviteClient = async (data: InviteClient) => {
    return await axios.post("/invite/client", data);
};

type AcceptInvite = {
    inviteID: string;
};

export const acceptInvite = async (data: AcceptInvite) => {
    const res = await axios.post("/invite/accept", data);
    if (!res.data.token) return res;
    if (res.data.remember) {
        localStorage.setItem("brandset_token", res.data.token);
    } else {
        sessionStorage.setItem("brandset_token", res.data.token);
    }
    return res;
};

type ResendInvite = {
    inviteID: string;
};
export const resendInvite = async (data: ResendInvite) => {
    return await axios.post("/invite/resend", data);
};

export const cancelInvite = async (token: string) => {
    return await axios.delete(`/invite/cancel/${token}`);
};

export default axios;
