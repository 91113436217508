import React, { FC } from "react";
import { debounce } from "../../util/helper";
import "./ColorInput.css";

interface ColorInputProps {
    label: string;
    color: string | undefined;
    setColor: (value: any) => void;
    className?: string;
}

const ColorInput: FC<ColorInputProps> = ({
    label,
    color,
    setColor,
    className,
}) => {
    const handleChange = debounce((val: string) => {
        setColor(val);
    }, 10);

    return (
        <label
            className={`ColorInput ${className}`}
            style={{ backgroundColor: color }}
        >
            <input
                name={label}
                value={color}
                onChange={(e) => handleChange(e.target.value)}
                type="color"
            ></input>
        </label>
    );
};

export default ColorInput;

// import React, { useEffect } from "react";

// function ColorInput({ label, ref, defaultValue }) {
//     const inputID = label.toLowerCase().replace(/ /g, "-");
//     useEffect(() => {
//         if (defaultValue) {
//             ref.current.value = defaultValue;
//         }
//     }, []);

//     return (
//         <div className="simpleColorInput">
//             <input
//                 name={label}
//                 ref={ref}
//                 type="color"
//                 id={inputID + "-input"}
//             ></input>
//             <label htmlFor={inputID + "-input"}></label>
//         </div>
//     );
// }

// export default ColorInput;
