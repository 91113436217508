import React, { FC } from "react";
import "./FontPreview.css";

interface FontPreviewProps {
    name: string;
}

const FontPreview: FC<FontPreviewProps> = ({ name }) => {
    return (
        <div className="fontPreview" style={{ fontFamily: name }}>
            <div className="aa">Aa</div>
            <p>{name}</p>
            <div className="fontExample">
                <div>Aa</div>
                <div>Bb</div>
                <div>Cc</div>
                <div>Dd</div>
                <div>Ee</div>
                <div>Ff</div>
                <div>Gg</div>
                <div>Hh</div>
                <div>Ii</div>
                <div>Jj</div>
                <div>Kk</div>
                <div>Ll</div>
                <div>Mm</div>
                <div>Nn</div>
                <div>Oo</div>
                <div>Pp</div>
                <div>Qq</div>
                <div>Rr</div>
                <div>Ss</div>
                <div>Tt</div>
                <div>Uu</div>
                <div>Vv</div>
                <div>Ww</div>
                <div>Xx</div>
                <div>Yy</div>
                <div>Zz</div>
            </div>
        </div>
    );
};

export default FontPreview;
