import React, { FC, useEffect } from "react";
import ButtonGroup from "../Buttons/ButtonGroup";
import RadioButton from "../Buttons/RadioButton";
import CheckBox from "../Inputs/CheckBox";
import RangeSlider from "../Inputs/RangeSlider";
import SvgDropZone from "../DropZone/SvgDropZone";
import SVG from "../Elements/SVG";
import { optimizeSvg } from "../../util/SVG/svg";
import changeSvgColor from "../../util/SVG/changeSvgColor";
import { InvertedLogo } from "../../types/Project";

type LogoVariantsProps = {
    logo: File | undefined;
    inverted: InvertedLogo;
    setInverted: React.Dispatch<React.SetStateAction<InvertedLogo>>;
};

const LogoVariants: FC<LogoVariantsProps> = ({
    logo,
    inverted,
    setInverted,
}) => {
    useEffect(() => {
        invertLogo();
    }, [logo]);

    function setInvertedType(type: string) {
        inverted.type = type as InvertedLogo["type"];
        if (type === "custom") {
            inverted.white = undefined;
            inverted.black = undefined;
        } else {
            invertLogo();
        }
        setInverted({ ...inverted });
    }

    function invertLogo() {
        if (!logo || inverted.type === "custom") return;
        logo.text().then((svgString) => {
            inverted.white = new File(
                [
                    optimizeSvg(
                        changeSvgColor(
                            svgString,
                            "#ffffff",
                            inverted.type === "solid",
                            inverted.threshold,
                            inverted.transparency
                        )
                    ),
                ],
                "Logo White.svg",
                {
                    type: "image/svg+xml",
                }
            );
            inverted.black = new File(
                [
                    optimizeSvg(
                        changeSvgColor(
                            svgString,
                            "#000000",
                            inverted.type === "solid",
                            inverted.threshold,
                            inverted.transparency
                        )
                    ),
                ],
                "Logo Black.svg",
                {
                    type: "image/svg+xml",
                }
            );
            setInverted({ ...inverted });
        });
    }

    function setInvertedThreshold(threshold: number) {
        inverted.threshold = threshold;
        invertLogo();
        setInverted({ ...inverted });
    }

    function setInvertedTransparency(transparency: boolean) {
        inverted.transparency = transparency;
        invertLogo();
        setInverted({ ...inverted });
    }

    function setInvertedWhite(file: File) {
        inverted.white = file;
        setInverted({ ...inverted });
    }

    function setInvertedBlack(file: File) {
        inverted.black = file;
        setInverted({ ...inverted });
    }

    return (
        <>
            <div className="col col-2">
                <ButtonGroup className="left">
                    <RadioButton
                        name="invertedType"
                        value="solid"
                        label="Solid"
                        checked={inverted.type === "solid"}
                        onChange={setInvertedType}
                    />
                    <RadioButton
                        name="invertedType"
                        value="gradient"
                        label="Gradient"
                        checked={inverted.type === "gradient"}
                        onChange={setInvertedType}
                    />
                    <RadioButton
                        name="invertedType"
                        value="custom"
                        label="Custom"
                        checked={inverted.type === "custom"}
                        onChange={setInvertedType}
                    />
                </ButtonGroup>
                {inverted.type === "gradient" && (
                    <ButtonGroup>
                        <CheckBox
                            name="transparency"
                            checked={inverted.transparency}
                            onChange={(e) =>
                                setInvertedTransparency(e.target.checked)
                            }
                            tooltip="Use transparency"
                        />
                        <RangeSlider
                            name="Threshold"
                            value={inverted.threshold}
                            step={0.1}
                            onChange={(e) =>
                                setInvertedThreshold(e.target.valueAsNumber)
                            }
                        />
                    </ButtonGroup>
                )}
            </div>
            <div className="col col-2">
                {inverted.type === "custom" ? (
                    <>
                        <SvgDropZone
                            name="White Logo"
                            text="White Logo SVG"
                            svg={inverted.white as File}
                            setSvg={setInvertedWhite}
                            className="logoVariation white"
                        />
                        <SvgDropZone
                            name="Black Logo"
                            text="Black Logo SVG"
                            svg={inverted.black as File}
                            setSvg={setInvertedBlack}
                            className="logoVariation black"
                        />
                    </>
                ) : (
                    <>
                        <div className="logoVariation white generated">
                            {inverted.white && (
                                <SVG
                                    src={URL.createObjectURL(
                                        inverted.white as File
                                    )}
                                />
                            )}
                        </div>
                        <div className="logoVariation black generated">
                            {inverted.black && (
                                <SVG
                                    src={URL.createObjectURL(
                                        inverted.black as File
                                    )}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default LogoVariants;
