import React, { FC } from "react";
import "./FileUpload.css";

type FileUploadProps = {
    name: string;
    accept: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
    label?: string;
    className?: string;
    multiple?: boolean;
};

const FileUpload: FC<FileUploadProps> = ({
    name,
    accept,
    onChange,
    label,
    required = false,
    className = "",
    multiple = false,
}) => {
    return (
        <label className={`fileUpload ${className}`} aria-label={name}>
            <input
                type="file"
                accept={accept}
                onChange={onChange}
                required={required}
                multiple={multiple}
            ></input>
            <span className="icon csic csic-upload"></span>
            <span className="text">{label}</span>
        </label>
    );
};

export default FileUpload;
