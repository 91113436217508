import React, { FC } from "react";
import Heading from "./Heading";
import ButtonGroup from "../Buttons/ButtonGroup";
import "./List.css";

type ListProps = {
    title?: string;
    buttonGroup?: React.ReactNode;
    children?: React.ReactNode;
    headingClassName?: string;
    listClassName?: string;
};

const List: FC<ListProps> = ({
    title,
    buttonGroup,
    children,
    headingClassName = "",
    listClassName = "",
}) => {
    return (
        <>
            {!!title && (
                <Heading className={headingClassName}>
                    <h4 className="inline">{title}</h4>
                    <ButtonGroup>{buttonGroup}</ButtonGroup>
                </Heading>
            )}
            <ul className={`list ${listClassName}`}>{children}</ul>
        </>
    );
};

export default List;
