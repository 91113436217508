import React from "react";
import { Helmet } from "react-helmet";
import { useCompanyStyle } from "../../context/styleContext";

function ParentHelmet() {
    const { brand } = useCompanyStyle();

    return (
        <Helmet>
            <title>{brand.style.metaTitle}</title>
            <meta name="description" content={brand.style.metaDescription} />
            <link rel="icon" href={brand.style.favicon} />
            <style type="text/css">
                {`:root {
            --primary: ${brand.style.primaryColor};
            --secondary: ${brand.style.secondaryColor};}`}
            </style>
        </Helmet>
    );
}

export default ParentHelmet;
