import React, { FC, useState, useEffect } from "react";
import Button from "../components/Buttons/Button";
import { useNotification } from "../context/notificationContext";
import { useCompany } from "../context/companyContext";
import { Project } from "../context/projectContext";
import { useQueryClient } from "@tanstack/react-query";
import { shareProject } from "../api/projectApi";
import Popup from "../components/Popup/Popup";
import CForm from "../components/Inputs/CForm";
import SelectDropdown from "../components/Inputs/SelectDropdown";
import CreateClientOrg from "./CreateClientOrg";

type ShareProjectProps = {
    project: Project;
    icon?: string;
    onComplete?: () => void;
    btnName?: string;
    btnTooltip?: string;
};

const ShareProject: FC<ShareProjectProps> = ({
    project,
    icon = "plus",
    onComplete,
    btnName = "",
    btnTooltip = "Share Project",
}) => {
    const { sendNotification } = useNotification();
    const { clientOrgs } = useCompany();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [options, setOptions] = useState<Array<Array<string>>>([
        ["Select...", ""],
    ]);
    const [selectedOption, setSelectedOption] = useState<string>("");
    const queryClient = useQueryClient();

    useEffect(() => {
        if (!clientOrgs) return;
        setOptions([
            ["Select...", ""],
            ...clientOrgs
                .filter((org) => !project.sharedWith.includes(org._id))
                .map((org) => [org.name, org._id]),
        ]);
    }, [clientOrgs]);

    async function handleShare() {
        if (selectedOption === "") {
            sendNotification("Please select a client org", "error");
            return;
        }
        const data = {
            projectID: project._id,
            companyID: selectedOption,
        };

        await shareProject(data).then((res) => {
            queryClient.invalidateQueries(["projects"]);
        });

        if (onComplete) onComplete();
    }

    return (
        <>
            <Button
                icon={icon}
                tooltip={btnTooltip}
                name={btnName}
                onClick={() => setIsPopupOpen(true)}
            />
            <Popup
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
                title="Share Project"
                className="small"
            >
                {options.length > 1 ? (
                    <CForm
                        className="shareProject"
                        onSubmit={handleShare}
                        button="Share"
                    >
                        <p>
                            Share <strong>{project.name}</strong> with a client
                            organization
                        </p>
                        <SelectDropdown
                            name="Client org"
                            options={options}
                            value={selectedOption}
                            onChange={setSelectedOption}
                        />
                    </CForm>
                ) : (
                    <>
                        {clientOrgs?.length === 0 ? (
                            <p>
                                You have no client organizations to share this
                                project with yet.
                            </p>
                        ) : (
                            <p>
                                You have shared this project with all of your
                                client organizations.
                            </p>
                        )}
                        <CreateClientOrg isStyled={true} />
                    </>
                )}
            </Popup>
        </>
    );
};

export default ShareProject;
