import React, {
    FC,
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useProjects } from "./projectsContext";
import { Color, Gradient, InvertedLogo } from "../types/Project";
import { Font, TypeScaleType } from "../types/Font";
import { loadSVG } from "../util/helper";
import { generateSvgFile, loadSvgFile } from "../util/SVG/svg";
import { getBrandGuide, shareProject } from "../api/projectApi";
import { BsFile, FolderRef } from "../types/Folder";
import { generateBsFile } from "../util/File/helper";
import { url } from "inspector";
import chroma from "chroma-js";

export type Project = {
    _id: string;
    name: string;
    slug: string;
    company: string;
    sharedWith: string[];
    logo: string;
    colors: {
        primary: string;
        secondary: string;
    };
    folder: FolderRef[];
    createdAt: string;
    updatedAt: string;
};

type Brandguide = {
    _id: string;
    project: string;
    invertedLogo: InvertedLogo;
    logomark: string | null;
    invertedLogomark: InvertedLogo | null;
    headingFont: Font;
    subHeadingFont: Font;
    bodyFont: Font;
    typescale: TypeScaleType;
    colors: Color[];
    gradients: Gradient[];
    createdAt: string;
    updatedAt: string;
    __v: number;
};

// type Logos = {
//     logo: BsFile | undefined;
//     logoWhite: BsFile | undefined;
//     logoBlack: BsFile | undefined;
//     logomark: BsFile | undefined;
//     logomarkWhite: BsFile | undefined;
//     logomarkBlack: BsFile | undefined;
// };

const ProjectContext = createContext<{
    project: Project | undefined | null;
    brandguide: Brandguide | undefined | null;
    logos: BsFile[];
    isLoading: boolean;
    isValidSlug: boolean;
}>({
    project: null,
    brandguide: null,
    logos: [],
    isLoading: false,
    isValidSlug: false,
});

type ProjectProviderProps = {
    children: React.ReactNode;
};

const ProjectProvider: FC<ProjectProviderProps> = ({ children }) => {
    const { projects, sharedProjects, fetchProjectBySlug } = useProjects();
    const [project, setProject] = useState<Project | null>(null);
    const [logos, setLogos] = useState<BsFile[]>([]);
    const { slug } = useParams();

    const [projectState, setProjectState] = useState({
        isLoading: true,
        isValidSlug: false,
    });

    useEffect(() => {
        const getProjectBySlug = async (slug: string) => {
            if (!!projects?.length) {
                const p = projects.find((project) => project.slug === slug);
                if (p) return p;
            }
            if (!!sharedProjects?.length) {
                const p = sharedProjects.find(
                    (project) => project.slug === slug
                );
                if (p) return p;
            }
            await fetchProjectBySlug(slug);
            if (projects?.length === 0 && sharedProjects?.length === 0) return;
            if (!!projects?.length) {
                const p = projects.find((project) => project.slug === slug);
                if (p) return p;
            }
            if (!!sharedProjects?.length) {
                const p = sharedProjects.find(
                    (project) => project.slug === slug
                );
                if (p) return p;
            }
        };

        const fetchProject = async (slug: string | undefined) => {
            if (!slug) return;
            setProjectState({
                isLoading: true,
                isValidSlug: false,
            });
            setProject(null);
            const temp = await getProjectBySlug(slug);
            if (temp) {
                setProjectState({
                    isLoading: false,
                    isValidSlug: true,
                });
                setProject(temp);
            } else {
                setProjectState({
                    isLoading: false,
                    isValidSlug: false,
                });
            }
        };
        fetchProject(slug);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug, projects, sharedProjects]);

    const { data: brandguide, isSuccess: loadedBrandguide } =
        useQuery<Brandguide>(
            ["brandguide", project?._id],
            () => getBrandGuide(project?._id as string),
            {
                enabled: projectState.isValidSlug,
            }
        );

    useEffect(() => {
        async function generateLogoBsFiles() {
            if (logos.length > 0) {
                URL.revokeObjectURL(logos[0].fileUrl);
                URL.revokeObjectURL(logos[1].fileUrl);
                URL.revokeObjectURL(logos[2].fileUrl);
                if (!!logos[3]) {
                    URL.revokeObjectURL(logos[3].fileUrl);
                    URL.revokeObjectURL(logos[4].fileUrl);
                    URL.revokeObjectURL(logos[5].fileUrl);
                }
            }

            if (!brandguide || !project) return;

            setProjectState({
                isLoading: false,
                isValidSlug: true,
            });

            setLogos([]);

            const temp: BsFile[] = [];
            const logoSvg = await loadSVG(project.logo);
            temp[0] = generateBsFile(
                new File([logoSvg], "Logo.svg", {
                    type: "image/svg+xml",
                })
            );
            if (brandguide.invertedLogo.type === "custom") {
                temp[1] = generateBsFile(
                    await loadSvgFile(
                        "Logo White",
                        brandguide.invertedLogo.white
                    )
                );
                temp[2] = generateBsFile(
                    await loadSvgFile(
                        "Logo Black",
                        brandguide.invertedLogo.black
                    )
                );
            } else {
                temp[1] = generateBsFile(
                    generateSvgFile(
                        "Logo White",
                        logoSvg,
                        "#ffffff",
                        brandguide.invertedLogo.type === "solid",
                        brandguide.invertedLogo.threshold,
                        brandguide.invertedLogo.transparency
                    )
                );
                temp[2] = generateBsFile(
                    generateSvgFile(
                        "Logo Black",
                        logoSvg,
                        "#000000",
                        brandguide.invertedLogo.type === "solid",
                        brandguide.invertedLogo.threshold,
                        brandguide.invertedLogo.transparency
                    )
                );
            }

            if (!!brandguide.logomark) {
                const logomarkSvg = await loadSVG(brandguide.logomark);
                temp[3] = generateBsFile(
                    new File([logomarkSvg], "Logomark.svg", {
                        type: "image/svg+xml",
                    })
                );
                if (brandguide.invertedLogomark?.type === "custom") {
                    temp[4] = generateBsFile(
                        await loadSvgFile(
                            "Logomark White",
                            brandguide.invertedLogomark.white
                        )
                    );
                    temp[5] = generateBsFile(
                        await loadSvgFile(
                            "Logomark Black",
                            brandguide.invertedLogomark.black
                        )
                    );
                } else {
                    temp[4] = generateBsFile(
                        generateSvgFile(
                            "Logomark White",
                            logomarkSvg,
                            "#ffffff",
                            brandguide.invertedLogomark?.type === "solid",
                            brandguide.invertedLogomark?.threshold,
                            brandguide.invertedLogomark?.transparency
                        )
                    );
                    temp[5] = generateBsFile(
                        generateSvgFile(
                            "Logomark Black",
                            logomarkSvg,
                            "#000000",
                            brandguide.invertedLogomark?.type === "solid",
                            brandguide.invertedLogomark?.threshold,
                            brandguide.invertedLogomark?.transparency
                        )
                    );
                }
            }
            setLogos(temp);
        }

        if (loadedBrandguide) {
            generateLogoBsFiles();
        }
    }, [project, brandguide, loadedBrandguide]);

    return (
        <ProjectContext.Provider
            value={{
                project,
                brandguide,
                logos,
                isLoading: projectState.isLoading,
                isValidSlug: projectState.isValidSlug,
            }}
        >
            {children}
        </ProjectContext.Provider>
    );
};

export default ProjectProvider;

export function useProject() {
    const context = useContext(ProjectContext);
    if (context === undefined) {
        throw new Error("useProject in not within ProjectProvider");
    }
    return context;
}
