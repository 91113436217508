import React from "react";
import { useQuery } from "@tanstack/react-query";
import { clientInvites } from "../../api/inviteApi";
import { getClients } from "../../api/userApi";
import ClientOrg from "../../components/Admin/ClientOrg";
import Loading from "../../components/Elements/Loading";
import CreateClientOrg from "../../Popups/CreateClientOrg";
import { useCompany } from "../../context/companyContext";
import Page from "../../components/Templates/Page";

function Clients() {
    const { clientOrgs, isClientOrgs } = useCompany();

    const { data: clients, isSuccess: loadedClients } = useQuery({
        queryKey: ["clients"],
        queryFn: () => getClients(),
        enabled: isClientOrgs,
    });

    const { data: invites, isSuccess: loadedInvites } = useQuery({
        queryKey: ["client-invites"],
        queryFn: () => clientInvites(),
        enabled: isClientOrgs,
    });

    return (
        <Page title={"Client Organizations"} buttons={<CreateClientOrg />}>
            {isClientOrgs && clientOrgs ? (
                loadedClients && loadedInvites ? (
                    clientOrgs.map((clientOrg) => {
                        return (
                            <ClientOrg
                                key={clientOrg.subdomain}
                                org={clientOrg}
                                clients={clients.filter((client) =>
                                    client.memberOf.includes(clientOrg._id)
                                )}
                                invites={invites.filter((invite) => {
                                    return invite.company === clientOrg._id;
                                })}
                            />
                        );
                    })
                ) : (
                    <Loading />
                )
            ) : (
                <p>Invite your first client!</p>
            )}
        </Page>
    );
}

export default Clients;
