import React, { FC, useRef, useState } from "react";
import { optimizeSvgFile, RenderSVG } from "../../util/SVG/svg";
import SVG from "../Elements/SVG";
import "./DropZone.css";

interface SvgDropZoneProps {
    name: string;
    text?: string;
    svg: File | undefined;
    setSvg: (svg: File) => void;
    className?: string;
}

const SvgDropZone: FC<SvgDropZoneProps> = ({
    name,
    text,
    svg,
    setSvg,
    className = "",
}) => {
    const svgRef = useRef<HTMLInputElement>(null);
    const [zoneActive, setZoneActive] = useState(false);

    const handleDrag = function (e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setZoneActive(true);
        } else if (e.type === "dragleave") {
            setZoneActive(false);
        }
    };

    const handleDrop = async function (e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        setZoneActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setSvg(await optimizeSvgFile(e.dataTransfer.files[0]));
        }
    };

    return (
        <div
            className={`svgDropZone dropZone${
                zoneActive ? " active" : ""
            } ${name} ${className}`}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
        >
            <label>
                <input
                    name={name}
                    ref={svgRef}
                    type="file"
                    accept="image/svg+xml"
                    onChange={async (e) =>
                        setSvg(await optimizeSvgFile(e.target.files?.[0]))
                    }
                ></input>
                {svg ? (
                    <SVG src={URL.createObjectURL(svg)} />
                ) : (
                    <p>{text ? text : "Drop file here or click to upload"}</p>
                )}
            </label>
        </div>
    );
};

export default SvgDropZone;
