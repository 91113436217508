import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getMemberOfCompanies } from "../../api/companyApi";
import {
    cancelInvite,
    inviteMember,
    memberInvites,
    resendInvite,
} from "../../api/inviteApi";
import { deleteUserCompanyMember, getMembers } from "../../api/userApi";
import Loading from "../../components/Elements/Loading";
import Popup from "../../components/Popup/Popup";
import SimpleInput from "../../components/Inputs/SimpleInput";
import Page from "../../components/Templates/Page";
import { useCompany } from "../../context/companyContext";
import { useUser } from "../../context/userContext";
import { copy } from "../../util/helper";
import { useNotification } from "../../context/notificationContext";
import CForm from "../../components/Inputs/CForm";
import List from "../../components/Templates/List";
import ButtonGroup from "../../components/Buttons/ButtonGroup";
import Button from "../../components/Buttons/Button";
import Setting from "../../components/Templates/Setting";

function Team() {
    const { user, lvl } = useUser();
    const { company, isCompany } = useCompany();

    const { data: members, isSuccess: loadedMembers } = useQuery({
        queryKey: ["members"],
        queryFn: () => getMembers(),
        enabled: isCompany,
    });

    const { data: invites, isSuccess: loadedInvites } = useQuery({
        queryKey: ["member-invites"],
        queryFn: () => memberInvites(),
        enabled: isCompany,
    });

    const { data: memberOf, isSuccess: loadedMemberOf } = useQuery({
        queryKey: ["member-of"],
        queryFn: () => getMemberOfCompanies(),
        enabled: !!user ? user.memberOf.length > 0 : false,
    });

    // exclude the current company from the list of companies the user is a member of
    // const memberOfCompanies = memberOf?.filter(
    //     (company) => company._id !== user.company
    // );

    const [isInviteFormOpen, setIsInviteFormOpen] = useState(false);
    const queryClient = useQueryClient();
    const inviteEmail = useRef<HTMLInputElement>(null);
    const { sendNotification } = useNotification();

    async function handleCreateInvite() {
        if (!inviteEmail.current?.value) {
            sendNotification("Please enter a valid email", "error");
            return;
        }

        const data = {
            email: inviteEmail.current.value,
        };

        await inviteMember(data).then((res) => {
            if (res) {
                queryClient.invalidateQueries(["member-invites"]);
                inviteEmail.current!.value = "";
                setIsInviteFormOpen(false);
            }
        });
    }

    async function handleCancelInvite(inviteId: string) {
        await cancelInvite(inviteId).then((res) => {
            if (res) {
                queryClient.invalidateQueries(["member-invites"]);
            }
        });
    }

    async function handleRemoveMember(userID: string) {
        if (!company) return;
        const data = {
            userID,
            companyID: company._id,
        };

        await deleteUserCompanyMember(data).then((res) => {
            if (res) {
                queryClient.invalidateQueries(["members"]);
            }
        });
    }

    async function handleLeaveCompany(companyID: string) {
        if (!user) return;
        const data = {
            userID: user._id,
            companyID,
        };
        await deleteUserCompanyMember(data).then((res) => {
            if (res) {
                queryClient.invalidateQueries(["user"]);
                queryClient.invalidateQueries(["member-of"]);
            }
        });
    }

    if (!user || !company) return <Loading />;

    return (
        <Setting title="Team">
            <List
                title="Team Members"
                buttonGroup={
                    <>
                        <Button
                            icon="plus"
                            tooltip="Invite Member"
                            onClick={() => setIsInviteFormOpen(true)}
                        />
                        <Popup
                            isOpen={isInviteFormOpen}
                            onClose={() => setIsInviteFormOpen(false)}
                            title="Send Invite"
                        >
                            <CForm onSubmit={handleCreateInvite} button="Send">
                                <SimpleInput
                                    label="Member Email"
                                    type="email"
                                    inputRef={inviteEmail}
                                />
                            </CForm>
                        </Popup>
                    </>
                }
                listClassName="compact"
            >
                {members ? (
                    members.map((member) => {
                        return (
                            <li key={member._id}>
                                <p>{member.email}</p>
                                <ButtonGroup>
                                    <Button
                                        tooltip="Remove Member"
                                        icon="delete"
                                        disabled={user.email === member.email}
                                        onClick={() =>
                                            handleRemoveMember(member._id)
                                        }
                                    />
                                </ButtonGroup>
                            </li>
                        );
                    })
                ) : (
                    <li>No members</li>
                )}
            </List>

            {invites?.length > 0 && (
                <List title="Invites" listClassName="compact">
                    {invites.map((invite: any) => (
                        <li key={invite._id}>
                            <p>{invite.email}</p>
                            <ButtonGroup>
                                <Button
                                    tooltip="Copy Link"
                                    icon="clipboard"
                                    onClick={() => {
                                        copy(
                                            `${window.location.origin}/?join=${invite._id}`,
                                            sendNotification
                                        );
                                    }}
                                />
                                <Button
                                    tooltip="Resend Invite"
                                    icon="refresh"
                                    onClick={() =>
                                        resendInvite({
                                            inviteID: invite._id,
                                        })
                                    }
                                />
                                <Button
                                    tooltip="Cancel Invite"
                                    icon="delete"
                                    onClick={() =>
                                        handleCancelInvite(invite._id)
                                    }
                                />
                            </ButtonGroup>
                        </li>
                    ))}
                </List>
            )}

            {memberOf?.length > 0 && (
                <List title="You are also a member of" listClassName="compact">
                    {memberOf.map((mCompany: any) => (
                        <li key={mCompany._id}>
                            <p>{mCompany.name}</p>
                            <ButtonGroup>
                                <Button
                                    icon="delete"
                                    tooltip="Leave Company"
                                    onClick={() =>
                                        handleLeaveCompany(mCompany._id)
                                    }
                                />
                            </ButtonGroup>
                        </li>
                    ))}
                </List>
            )}
        </Setting>
    );
}

export default Team;
