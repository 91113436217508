import React, { useEffect, useRef, useState } from "react";
import webfonts from "../../util/Font/webfonts.json";
import SelectFont from "../Font/SelectFont";
import { Font, GoogleFont } from "../../types/Font";

type SelectFontsProps = {
    headingFont: Font;
    setHeadingFont: (font: Font) => void;
    headingFontFile: File | undefined;
    setHeadingFontFile: (file: File | undefined) => void;
    subHeadingFont: Font;
    setSubHeadingFont: (font: Font) => void;
    subHeadingFontFile: File | undefined;
    setSubHeadingFontFile: (file: File | undefined) => void;
    bodyFont: Font;
    setBodyFont: (font: Font) => void;
    bodyFontFile: File | undefined;
    setBodyFontFile: (file: File | undefined) => void;
};

const SelectFonts: React.FC<SelectFontsProps> = ({
    headingFont,
    setHeadingFont,
    headingFontFile,
    setHeadingFontFile,
    subHeadingFont,
    setSubHeadingFont,
    subHeadingFontFile,
    setSubHeadingFontFile,
    bodyFont,
    setBodyFont,
    bodyFontFile,
    setBodyFontFile,
}) => {
    const loadedFonts = useRef([]);
    const [fontOptions, setFontOptions] = useState<GoogleFont[]>([]);

    useEffect(() => {
        if (fontOptions.length === 0) {
            setFontOptions(
                webfonts.items.map((font, i) => ({
                    value: i,
                    label: font.family,
                }))
            );
        }
    }, []);

    return (
        <>
            <SelectFont
                name={"heading"}
                previewText={"Select a heading font"}
                font={headingFont}
                setFont={setHeadingFont}
                fontFile={headingFontFile}
                setFontFile={setHeadingFontFile}
                options={fontOptions}
                loadedFonts={loadedFonts}
            />

            <SelectFont
                name={"sub-heading"}
                previewText={"Select a sub-heading font"}
                font={subHeadingFont}
                setFont={setSubHeadingFont}
                fontFile={subHeadingFontFile}
                setFontFile={setSubHeadingFontFile}
                options={fontOptions}
                loadedFonts={loadedFonts}
            />

            <SelectFont
                name={"body"}
                previewText={"Select a body font"}
                font={bodyFont}
                setFont={setBodyFont}
                fontFile={bodyFontFile}
                setFontFile={setBodyFontFile}
                options={fontOptions}
                loadedFonts={loadedFonts}
            />
        </>
    );
};

export default SelectFonts;
