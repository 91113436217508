import React, { FC, useEffect } from "react";
import SimpleInput from "../Inputs/SimpleInput";
import SvgDropZone from "../DropZone/SvgDropZone";
import { handleSlugInput } from "../../util/validator";

interface ProjectDetailsProps {
    name: React.RefObject<HTMLInputElement>;
    url: React.RefObject<HTMLInputElement>;
    logo: File | undefined;
    setLogo: React.Dispatch<React.SetStateAction<File | undefined>>;
    logomark: File | undefined;
    setLogomark: React.Dispatch<React.SetStateAction<File | undefined>>;
}

const ProjectDetails: FC<ProjectDetailsProps> = ({
    name,
    url,
    logo,
    setLogo,
    logomark,
    setLogomark,
}) => {
    useEffect(() => {
        if (!name.current || !url.current) return;
        const cleanup = handleSlugInput(name.current, url.current);
        return () => cleanup();
    }, []);

    return (
        <fieldset>
            <legend>Name of the brand</legend>
            <SimpleInput label="Brand Name" inputRef={name} required={true} />
            <SimpleInput label="Slug" inputRef={url} required={true} />
            <p className="smallText">
                Example https://app.brandset.co/project/project-name
            </p>
            <div className="col col-2">
                <SvgDropZone
                    name="logo"
                    text="Logo SVG"
                    svg={logo}
                    setSvg={setLogo}
                />
                <SvgDropZone
                    name="logomark"
                    text="Logomark SVG (optional)"
                    svg={logomark}
                    setSvg={setLogomark}
                />
            </div>
        </fieldset>
    );
};

export default ProjectDetails;
