import React from "react";
import Page from "../../components/Templates/Page";
import { useProject } from "../../context/projectContext";
import Loading from "../../components/Elements/Loading";
import GradientCard from "../../components/Color/GradientCard";
import ColorCard from "../../components/Color/ColorCard";
import Heading from "../../components/Templates/Heading";
import ButtonGroup from "../../components/Buttons/ButtonGroup";
import Button from "../../components/Buttons/Button";
import Content from "../../components/Templates/Content";
import "./Logo.css";
import CSSCode from "../../components/Templates/CSSCode";
import { toLinearGradient } from "../../util/Color/getGradients";

function Colors() {
    const { project, brandguide, isLoading, isValidSlug } = useProject();

    if (isLoading || !brandguide || !project) {
        return <Loading />;
    }

    if (!isValidSlug) {
        return <Page title="404">Project not found</Page>;
    }

    return (
        <Page title={"Colors"} subtitle={project.name}>
            {brandguide.colors.length > 0 && (
                <>
                    <Heading>
                        <h5>Colors used in this project</h5>
                        <ButtonGroup className="left">
                            <CSSCode
                                name="CSS Color Code"
                                css={`
                                    .primary {
                                        color: ${brandguide.colors[0].code};
                                    }

                                    .secondary {
                                        color: ${brandguide.colors[1].code};
                                    }
                                    ${brandguide.colors.length > 2
                                        ? brandguide.colors
                                              .slice(2)
                                              .map(
                                                  (color, i) => `
                                    .color-${i} {
                                        color: ${color.code};
                                    }`
                                              )
                                              .join("\n")
                                        : ""}
                                `}
                                cssvars={`
                            :root{
                                --primary: ${brandguide.colors[0].code};
                                --secondary: ${brandguide.colors[1].code};${
                                    brandguide.colors.length > 2
                                        ? brandguide.colors
                                              .slice(2)
                                              .map(
                                                  (color, i) => `
                                --color-${i}: ${color.code};`
                                              )
                                              .join("")
                                        : ""
                                }
                            }`}
                                saas={`$primary: ${
                                    brandguide.colors[0].code
                                };\n$secondary: ${
                                    brandguide.colors[1].code
                                };\n${
                                    brandguide.colors.length > 2
                                        ? brandguide.colors
                                              .slice(2)
                                              .map(
                                                  (color, i) =>
                                                      "$color-" +
                                                      i +
                                                      ": " +
                                                      color.code +
                                                      ";"
                                              )
                                              .join("\n")
                                        : ""
                                }`}
                                tailwind={`
                            // tailwind.config.js
                            module.exports = {
                                theme: {
                                    extend: {
                                        colors: {
                                            'primary': '${
                                                brandguide.colors[0].code
                                            }',
                                            'secondary': '${
                                                brandguide.colors[1].code
                                            }',${
                                    brandguide.colors.length > 2
                                        ? brandguide.colors
                                              .slice(2)
                                              .map(
                                                  (color, i) => `
                                            'color-${i}': '${color.code}',`
                                              )
                                              .join("")
                                        : ""
                                }
                                        }
                                    }
                                }
                            }`}
                            />
                        </ButtonGroup>
                    </Heading>
                    <Content className="col col-3">
                        {brandguide.colors.map((color, i) => {
                            return (
                                <ColorCard key={"color-" + i} color={color} />
                            );
                        })}
                    </Content>
                </>
            )}

            {brandguide.gradients.length > 0 && (
                <>
                    <Heading>
                        <h5>Gradients used in this project</h5>
                        <ButtonGroup className="left">
                            <CSSCode
                                name="CSS Color Code"
                                cssvars={`:root{${brandguide.gradients
                                    .map((g, i) => {
                                        return `\n\t--gradient-${i}: ${toLinearGradient(
                                            g
                                        )};`;
                                    })
                                    .join("")}\n}`}
                                css={`
                                    ${brandguide.gradients
                                        .map(
                                            (g, i) => `
                                .gradient-${i} {
                                    background-image: ${toLinearGradient(g)};
                                }`
                                        )
                                        .join("\n")}
                                `}
                                saas={`${brandguide.gradients
                                    .map(
                                        (g, i) =>
                                            `$gradient-${i}: ${toLinearGradient(
                                                g
                                            )};`
                                    )
                                    .join("\n")}`}
                                tailwind={`
                            // tailwind.config.js
                            module.exports = {
                                theme: {
                                    extend: {
                                        backgroundImage: {${brandguide.gradients
                                            .map(
                                                (g, i) => `
                                            'gradient-${i}': '${toLinearGradient(
                                                    g
                                                )}',`
                                            )
                                            .join("")}
                                        }
                                    }
                                }
                            }`}
                            />
                        </ButtonGroup>
                    </Heading>
                    <Content className="col col-3">
                        {brandguide.gradients.map((gradient, i) => {
                            return (
                                <GradientCard
                                    key={"gradient-" + i}
                                    gradient={gradient}
                                />
                            );
                        })}
                    </Content>
                </>
            )}
        </Page>
    );
}

export default Colors;
