import React, { useRef, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { createClientCompany } from "../api/companyApi";
import Popup from "../components/Popup/Popup";
import SimpleInput from "../components/Inputs/SimpleInput";
import Button from "../components/Buttons/Button";
import CForm from "../components/Inputs/CForm";

function CreateClientOrg({ icon = "plus", isStyled = false }) {
    const orgName = useRef<HTMLInputElement>(null);
    const queryClient = useQueryClient();

    const [isOpen, setIsOpen] = useState(false);

    async function handleCreateClientOrg() {
        if (!orgName.current) {
            return;
        }

        const data = {
            name: orgName.current.value,
        };
        await createClientCompany(data).then((res) => {
            if (res) {
                queryClient.invalidateQueries(["company"]);
                queryClient.invalidateQueries(["client-orgs"]);
                orgName.current!.value = "";
                setIsOpen(false);
            }
        });
    }

    return (
        <>
            <Button
                onClick={() => setIsOpen(!isOpen)}
                icon="plus"
                tooltip={!isStyled ? "Create Client Org" : undefined}
                className={isStyled ? "styled" : undefined}
                name={isStyled ? "Create Client Org" : undefined}
            />
            <Popup
                title="Create Org"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                className="small"
            >
                <CForm button="Create" onSubmit={handleCreateClientOrg}>
                    <SimpleInput
                        label="Organization Name"
                        type="text"
                        inputRef={orgName}
                    />
                </CForm>
            </Popup>
        </>
    );
}

export default CreateClientOrg;
