import React, { FC } from "react";
import "./Card.css";
import ButtonGroup from "../Buttons/ButtonGroup";

interface CardProps {
    name?: string;
    className?: string;
    hero?: React.ReactNode;
    heroClasses?: string;
    children?: React.ReactNode;
    childrenClasses?: string;
    buttonGroup?: React.ReactNode;
    buttonGroupClasses?: string;
    onClick?: () => void;
    onDoubleClick?: () => void;
}

const Card: FC<CardProps> = ({
    name = "",
    className = "",
    hero,
    heroClasses = "",
    children,
    childrenClasses = "",
    buttonGroup,
    buttonGroupClasses = "",
    onClick,
    onDoubleClick,
}) => {
    return (
        <div className={`card ${className}`} tabIndex={0}>
            {hero && (
                <div
                    onClick={onClick}
                    onDoubleClick={onDoubleClick}
                    className={`card__hero ${heroClasses}`}
                >
                    {hero}
                </div>
            )}
            <div className={`card__content ${childrenClasses}`}>
                {name && (
                    <p
                        onClick={onClick}
                        onDoubleClick={onDoubleClick}
                        className="card__name hasTooltip ttl"
                        data-tooltip={name}
                    >
                        <span className="lineClamp">{name}</span>
                    </p>
                )}
                {children}
            </div>
            {buttonGroup && (
                <ButtonGroup className={`card__options ${buttonGroupClasses}`}>
                    {buttonGroup}
                </ButtonGroup>
            )}
        </div>
    );
};

export default Card;
