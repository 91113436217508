import React, { FC, useRef } from "react";
import Portal from "../../components/Templates/Portal";
import CForm from "../../components/Inputs/CForm";
import SimpleInput from "../../components/Inputs/SimpleInput";
import { Link, useNavigate } from "react-router-dom";
import Content from "../../components/Templates/Content";
import { useNotification } from "../../context/notificationContext";
import { isEmailValid } from "../../util/validator";
import { forgotPassword } from "../../api/userApi";

interface ForgotPasswordProps {}

const ForgotPassword: FC<ForgotPasswordProps> = ({}) => {
    const userEmail = useRef<HTMLInputElement>(null);
    const { sendNotification } = useNotification();
    const navigate = useNavigate();

    async function handleForgotPassword() {
        const data = {
            email: userEmail.current?.value ?? "",
        };

        if (!isEmailValid(data.email)) {
            sendNotification("Please enter a valid email", "error");
            return;
        }

        await forgotPassword(data).then((res) => {
            if (res) {
                navigate("/reset-password");
            }
        });
    }

    return (
        <Portal title="Forgot Password">
            <Content className="test">
                <h3>Forgot your password?</h3>
                <p>
                    Enter your email address below and we'll send you a link to
                    reset your password.
                </p>
            </Content>
            <CForm onSubmit={handleForgotPassword} button="Submit">
                <SimpleInput label="Email" type="email" inputRef={userEmail} />
            </CForm>
            <Link to="/">Back to Login</Link>
        </Portal>
    );
};

export default ForgotPassword;
