import React, { FC, useEffect } from "react";
import ReactDOM from "react-dom";
import Button from "../Buttons/Button";
import "./Popup.css";

interface PopupProps {
    children: React.ReactNode;
    onClose: () => void;
    isOpen: boolean;
    title?: string;
    className?: string;
    style?: React.CSSProperties;
    isForm?: boolean;
}

const Popup: FC<PopupProps> = ({
    children,
    onClose,
    isOpen,
    title,
    className = "",
    style,
}) => {
    useEffect(() => {
        if (isOpen) {
            const firstInput = document.querySelector(
                ".popup_container input"
            ) as HTMLInputElement;

            if (firstInput) {
                firstInput.focus();
            } else {
                const firstButton = document.querySelector(
                    ".popup_container button"
                ) as HTMLButtonElement;
                firstButton?.focus();
            }
        }
    }, [isOpen]);

    if (!isOpen) return null;
    return ReactDOM.createPortal(
        <div
            className={`popup ${className}`}
            style={style}
            onKeyDown={(e) => {
                if (e.key === "Escape") {
                    onClose();
                }
            }}
        >
            <div className="popup_overlay" onClick={onClose} />
            <div className="popup_container">
                <Button
                    tooltip="Close"
                    icon="close"
                    className="close floating"
                    onClick={onClose}
                />
                {title && <h2 className="popup_title">{title}</h2>}
                {children}
            </div>
        </div>,
        document.getElementById("portal") as Element
    );
};

export default Popup;
