import React, { useEffect, useState } from "react";
import Loading from "../../components/Elements/Loading";
import Page from "../../components/Templates/Page";
import { useProject } from "../../context/projectContext";
import File from "../../components/File/File";
import ButtonGroup from "../../components/Buttons/ButtonGroup";
import Button from "../../components/Buttons/Button";
import "./Folder.css";
import Heading from "../../components/Templates/Heading";
import { useFolder } from "../../context/folderContext";
import FileUpload from "../../components/Inputs/FileUpload";
import AddFolder from "../../Popups/AddFolder";
import NavLinkButton from "../../components/Buttons/NavLinkButton";
import { NavLink } from "react-router-dom";
import JSZip from "jszip";
import { useNotification } from "../../context/notificationContext";
import { BsFile } from "../../types/Folder";
import ContextMenu from "../../components/ContextMenu/ContextMenu";
import FolderLink from "../../components/Folder/FolderLink";
import { useCompany } from "../../context/companyContext";
import { useUser } from "../../context/userContext";

function Folder() {
    const { user } = useUser();
    const { company } = useCompany();
    const { project, logos, isLoading, isValidSlug } = useProject();
    const {
        folder,
        parentFolder,
        subFolders,
        loadingFolder,
        isValidFolder,
        addToUploadQueue,
        UploadQueue,
        handleDeleteFile,
    } = useFolder();
    const [files, setFiles] = useState<BsFile[]>([]);
    const { sendNotification, updateNotification, removeNotification } =
        useNotification();
    const [folderView, setFolderView] = useState("grid col col-3");

    useEffect(() => {
        if (folder) {
            if (folder.slug === "logos") setFiles([...logos, ...folder.files]);
            else setFiles(folder.files);
        }
    }, [folder, logos]);

    const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
    const [lastFileIndex, setLastFileIndex] = useState<number | null>(null);
    const [keyStates, setKeyStates] = useState({
        shift: false,
        control: false,
    });

    const handleKeyEvent = (event: KeyboardEvent) => {
        if (event.key === "Shift" || event.key === "Control") {
            setKeyStates({
                ...keyStates,
                [event.key.toLowerCase()]: event.type === "keydown",
            });
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", handleKeyEvent);
        window.addEventListener("keyup", handleKeyEvent);
        return () => {
            window.removeEventListener("keydown", handleKeyEvent);
            window.removeEventListener("keyup", handleKeyEvent);
        };
    }, [keyStates]);

    const selectFile = (index: number) => {
        if (!folder) return;

        if (keyStates.shift && lastFileIndex !== null) {
            const selectedfiles = files.slice(
                Math.min(lastFileIndex, index),
                Math.max(lastFileIndex, index) + 1
            );
            setSelectedFiles(selectedfiles.map((file) => file._id));
        } else if (keyStates.control) {
            if (selectedFiles.includes(files[index]._id)) {
                setSelectedFiles(
                    selectedFiles.filter((id) => id !== files[index]._id)
                );
            } else {
                setSelectedFiles([...selectedFiles, files[index]._id]);
            }
        } else {
            setSelectedFiles([files[index]._id]);
        }
        setLastFileIndex(index);
    };

    const handleDeleteSelected = async () => {
        if (!folder) return;
        const nID = sendNotification("Deleting files...", "loading");
        const fileIDs: string[] = [];

        if (selectedFiles.length === 0) {
            fileIDs.push(...folder.files.map((file) => file._id));
        } else {
            const temp = folder.files.filter((file) =>
                selectedFiles.includes(file._id)
            );
            fileIDs.push(...temp.map((file) => file._id));
        }

        if (fileIDs.length === 0) {
            updateNotification(nID, "error", "No files to delete");
            return;
        }

        handleDeleteFile(fileIDs).then(() => {
            setSelectedFiles([]);
            setLastFileIndex(null);
            removeNotification(nID);
        });
    };

    const handleDownloadSelected = async () => {
        if (!folder) return;
        if (files.length === 0) {
            sendNotification("No files to download", "error");
            return;
        }
        const nID = sendNotification("Downloading files...", "loading");
        const dFiles: BsFile[] = [];

        if (selectedFiles.length === 0) {
            dFiles.push(...files);
        } else {
            const temp = files.filter((file) =>
                selectedFiles.includes(file._id)
            );
            dFiles.push(...temp);
        }

        const zip = new JSZip();
        const downloadPromises = dFiles.map(async (file) => {
            const res = await fetch(file.fileUrl);
            const blob = await res.blob();
            zip.file(file.fileName, blob);
        });
        await Promise.all(downloadPromises);
        const blob = await zip.generateAsync({ type: "blob" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${folder.name}.zip`;
        a.click();
        URL.revokeObjectURL(url);

        updateNotification(nID, "success", "Files downloaded");
    };

    if (!project || isLoading || loadingFolder || !folder) {
        return <Loading />;
    }

    if (!isValidSlug || !isValidFolder) {
        return <Page title="404">Folder not found</Page>;
    }
    return (
        <Page
            title={folder.name}
            subtitle={project.name}
            subtitleNode={
                <>
                    <NavLink to={`/project/${project.slug}`}>
                        {project.name}
                    </NavLink>
                    {!!parentFolder && (
                        <>
                            {" > "}
                            <NavLink
                                to={`/project/${project.slug}/${parentFolder.slug}`}
                            >
                                {parentFolder.name}
                            </NavLink>
                        </>
                    )}
                </>
            }
            buttons={
                <>
                    <AddFolder
                        projectID={project._id}
                        projectSlug={project.slug}
                        parentID={folder._id}
                    />
                    <FileUpload
                        name="upload-file"
                        label="Upload Files"
                        multiple={true}
                        onChange={(e) => addToUploadQueue(e, folder._id)}
                        accept="image/*, video/*, audio/*, .pdf, .ai, .psd, .eps, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .csv"
                    />
                </>
            }
        >
            <Heading>
                <ButtonGroup>
                    <Button
                        icon="grid"
                        className={folderView.match("grid") ? "active" : ""}
                        tooltip="Grid View"
                        onClick={() => setFolderView("grid col col-3")}
                    />
                    <Button
                        icon="list-2"
                        className={folderView.match("list") ? "active" : ""}
                        tooltip="List View"
                        onClick={() => setFolderView("list")}
                    />
                    <Button icon="list" tooltip="Sort" />
                </ButtonGroup>
                <ButtonGroup>
                    <Button
                        icon="download"
                        tooltip={
                            selectedFiles.length > 0
                                ? "Download selected files"
                                : "Download all"
                        }
                        onClick={handleDownloadSelected}
                    />
                    <Button icon="share" tooltip="Share" />
                    <Button icon="edit" tooltip="Edit" />
                    <Button
                        icon="delete"
                        tooltip="Delete"
                        onClick={handleDeleteSelected}
                    />
                </ButtonGroup>
            </Heading>
            {subFolders && subFolders.length > 0 && (
                <div className={`folders folderView ${folderView}`}>
                    {subFolders.map((f) => (
                        <FolderLink
                            key={f._id}
                            folder={f}
                            projectSlug={project.slug}
                            isOwner={!company?.isClientOrg}
                        />
                    ))}
                </div>
            )}
            <div className={`files folderView ${folderView} ${folder.slug}`}>
                {files.map((file, i) => (
                    <File
                        key={file._id}
                        file={file}
                        isSelected={selectedFiles.includes(file._id)}
                        setSelectFile={() => selectFile(i)}
                        isOwner={!company?.isClientOrg}
                    />
                ))}
            </div>

            <UploadQueue />
        </Page>
    );
}

export default Folder;
