export function calculateTypeStyle(typescale) {
    function cleanNum(n) {
        return parseFloat(Number(n).toFixed(2))
    }

    const h1 = Math.pow(typescale.scaleRatio, 5).toFixed(2),
        h2 = cleanNum(Math.pow(typescale.scaleRatio, 4)),
        h3 = cleanNum(Math.pow(typescale.scaleRatio, 3)),
        h4 = cleanNum(Math.pow(typescale.scaleRatio, 2)),
        h5 = cleanNum(typescale.scaleRatio),
        Body = 1,
        Caption = cleanNum(1 / typescale.scaleRatio),
        Small = cleanNum((1 / typescale.scaleRatio) / typescale.scaleRatio);

    return {
        h1: {
            em: h1,
            px: cleanNum(h1 * typescale.fontSize)
        },
        h2: {
            em: h2,
            px: cleanNum(h2 * typescale.fontSize)
        },
        h3: {
            em: h3,
            px: cleanNum(h3 * typescale.fontSize)
        },
        h4: {
            em: h4,
            px: cleanNum(h4 * typescale.fontSize)
        },
        h5: {
            em: h5,
            px: cleanNum(h5 * typescale.fontSize)
        },
        body: {
            em: Body,
            px: cleanNum(Body * typescale.fontSize)
        },
        caption: {
            em: Caption,
            px: cleanNum(Caption * typescale.fontSize)
        },
        small: {
            em: Small,
            px: cleanNum(Small * typescale.fontSize)
        }
    }
}