import "./Default.css";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import axios from "axios";
import domain from "./util/domain";
import { useUser } from "./context/userContext";
import CompanyProvider from "./context/companyContext";
import ProjectsProvider from "./context/projectsContext";
import Header from "./components/Header/Header";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Login from "./Pages/User/Login";
import SignUp from "./Pages/User/SignUp";
import CreateCompany from "./Pages/User/CreateCompany";
import Loading from "./components/Elements/Loading";
import { acceptInvite } from "./api/inviteApi";
import { useEffect, useRef, useState } from "react";
import ParentHelmet from "./components/Templates/ParentHelmet";
import ForgotPassword from "./Pages/User/ForgotPassword";
import ResetPassword from "./Pages/User/ResetPassword";
import { verifyEmail } from "./api/userApi";
import { useQueryClient } from "@tanstack/react-query";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = domain;

function App() {
    const { user, fetchingUser, setToken } = useUser();
    const queryClient = useQueryClient();
    const [checkedParams, setCheckedParams] = useState(false);
    const urlParams = new URLSearchParams(window.location.search);
    const inviteID = urlParams.get("join");
    const verifyID = urlParams.get("verify");

    useEffect(() => {
        if (!checkedParams) {
            if (!!inviteID) {
                acceptInvite({ inviteID }).then((res) => {
                    setToken(res.data.token);
                });
            } else if (!!verifyID) {
                const data = {
                    email: verifyID.split("~:~")[0],
                    token: verifyID.split("~:~")[1],
                };
                verifyEmail(data).then((res) => {
                    if (res) queryClient.invalidateQueries(["user"]);
                });
            }

            window.history.replaceState({}, document.title, "/");
            setCheckedParams(true);
        }
    }, []);

    if (!checkedParams || fetchingUser) return <Loading />;

    if (!user)
        return (
            <>
                <ParentHelmet />
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route
                        path="/forgot-password"
                        element={<ForgotPassword />}
                    />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="*" element={<Login />} />
                </Routes>
            </>
        );
    else if (user && !user.company)
        return (
            <>
                <ParentHelmet />
                <CreateCompany />
            </>
        );
    else
        return (
            <>
                <ParentHelmet />
                <CompanyProvider>
                    <ProjectsProvider>
                        <Header />
                        <Dashboard />
                    </ProjectsProvider>
                </CompanyProvider>
            </>
        );
}

export default App;
