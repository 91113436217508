import React, { FC } from "react";
import "./TypeScalePreview.css";

interface TypeScalePreviewProps {
    typeStyle: any;
    typescale: any;
}

const TypeScalePreview: FC<TypeScalePreviewProps> = ({
    typeStyle,
    typescale,
}) => {
    return (
        <div className="textScalePreview">
            {Object.keys(typeStyle).map((type, i) => {
                return (
                    <div key={i} className={"textScalePreview__item item-" + i}>
                        <span className="smallText">
                            {type} {typeStyle[type].em}em | {typeStyle[type].px}
                            px
                        </span>
                        <p
                            style={{
                                fontSize: typeStyle[type].px + "px",
                            }}
                        >
                            {typescale.previewText}
                        </p>
                    </div>
                );
            })}
        </div>
    );
};

export default TypeScalePreview;
