export const isEmailValid = (email: string): boolean => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    return emailRegex.test(email) && email.length <= 50;
};

export const isPasswordValid = (password: string): boolean => {
    return (
        password.length >= 8 && password.length <= 20 && password.length >= 8
    );
};

export const isTextValid = (text: string): boolean => {
    return text.length <= 20 && text.length >= 3;
};

export const textToSlug = (text: string): string => {
    return text
        .trim()
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/^[^a-zA-Z]+|[^a-zA-Z0-9-]+/g, "");
};

export const handleSlugInput = (
    name: HTMLInputElement,
    slug: HTMLInputElement
) => {
    function handleSlug() {
        slug.value = textToSlug(name.value);
    }
    name.addEventListener("input", handleSlug);

    function handleSlugInput() {
        slug.value = textToSlug(slug.value);
    }
    slug.addEventListener("input", handleSlugInput);

    return () => {
        name.removeEventListener("input", handleSlug);
        slug.removeEventListener("input", handleSlugInput);
    };
};
