import chroma from "chroma-js";
import React, { FC, useMemo } from "react";
import { copy } from "../../util/helper";
import { useNotification } from "../../context/notificationContext";
import Card from "../Card/Card";
import { switchColor } from "../../util/colorHelper";
import { Color } from "../../types/Project";
import "./ColorCard.css";

type ColorCardProps = {
    color: Color;
};

const ColorCard: FC<ColorCardProps> = ({ color }) => {
    const { sendNotification } = useNotification();

    const memo = useMemo(() => {
        return {
            rgb: chroma(color.code).rgb().join(", "),
            oklch: chroma(color.code)
                .oklch()
                .map((v) => v.toFixed(2))
                .join(", "),
            variations: chroma.scale(["#fff", color.code]).colors(9),
        };
    }, [color.code]);

    return (
        <Card
            className="color"
            hero={
                <>
                    <div
                        className="preview"
                        style={
                            {
                                backgroundColor: color.code,
                                "--text_color": switchColor(color.code),
                            } as React.CSSProperties
                        }
                    >
                        <h5>{color.name}</h5>
                        <span
                            className="copy uc"
                            onClick={() => copy(color.code, sendNotification)}
                        >
                            {color.code}
                        </span>
                        <span
                            className="copy uc"
                            onClick={() => copy(memo.rgb, sendNotification)}
                        >
                            RGB {memo.rgb}
                        </span>
                        <span
                            className="copy uc"
                            onClick={() => copy(memo.oklch, sendNotification)}
                        >
                            OKLCH {memo.oklch}
                        </span>
                    </div>
                    <div className="variations">
                        {memo.variations.map((color, i) => {
                            if (i > 0) {
                                return (
                                    <span
                                        key={color}
                                        className="copy"
                                        style={{
                                            backgroundColor: color,
                                        }}
                                        onClick={() =>
                                            copy(color, sendNotification)
                                        }
                                    ></span>
                                );
                            }
                            return null;
                        })}
                    </div>
                </>
            }
        ></Card>
    );
};

export default ColorCard;
