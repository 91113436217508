import colorHexArr from "./colorHexArr.json"
import colorNameArr from "./colorNameArr.json"

function hexToRgb(hex) {
    return hex.slice(1)
        .replace(/^(.)(.)(.)$/gi, "$1$1$2$2$3$3")
        .match(/.{2}/g)
        .map(c => parseInt(c, 16));
}
function distance(a, b) {
    return Math.sqrt(Math.pow(a[0] - b[0], 2) + Math.pow(a[1] - b[1], 2) + Math.pow(a[2] - b[2], 2));
}

export default function colorName(hex) {

    let lowest = Number.POSITIVE_INFINITY;
    let tmp
    let index = 0
    let color = hexToRgb(hex)

    colorHexArr.forEach((c, i) => {
        tmp = distance(color, c)
        if (tmp < lowest) {
            lowest = tmp;
            index = i;
        };
    })

    return colorNameArr[index]
}

