import React, { FC } from "react";
import { Helmet } from "react-helmet";
import ButtonGroup from "../Buttons/ButtonGroup";
import "./Page.css";

interface PageProps {
    title: string;
    subtitle?: string;
    subtitleNode?: React.ReactNode;
    buttons?: React.ReactNode;
    className?: string;
    children?: React.ReactNode;
    footer?: React.ReactNode;
    hideHeader?: boolean;
    style?: React.CSSProperties;
}

const Page: FC<PageProps> = ({
    title,
    subtitle,
    subtitleNode,
    buttons,
    className = "",
    children,
    footer,
    hideHeader = false,
    style,
}) => {
    return (
        <>
            <Helmet>
                <title>
                    {title} {subtitle ? `- ${subtitle.toString()}` : ""}
                </title>
            </Helmet>
            <div className={`page ${className}`} style={style ?? {}}>
                {!hideHeader && (
                    <div className="pageHeader">
                        <div className="pageTitle">
                            <h2 className="captionText">
                                {subtitleNode ? subtitleNode : subtitle}
                            </h2>
                            <h1>{title}</h1>
                        </div>
                        {buttons && <ButtonGroup>{buttons}</ButtonGroup>}
                    </div>
                )}
                <div className="pageBody">{children}</div>
                {footer && <div className="pageFooter">{footer}</div>}
            </div>
        </>
    );
};

export default Page;
