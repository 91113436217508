import React, { FC, useRef, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { inviteClient } from "../api/inviteApi";
import SimpleInput from "../components/Inputs/SimpleInput";
import CForm from "../components/Inputs/CForm";
import Popup from "../components/Popup/Popup";
import Button from "../components/Buttons/Button";

type CreateClientInviteProps = {
    companyID: string;
};

const CreateClientInvite: FC<CreateClientInviteProps> = ({ companyID }) => {
    const queryClient = useQueryClient();
    const inviteEmail = useRef<HTMLInputElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    async function handleCreateInvite() {
        if (!inviteEmail.current) {
            return;
        }
        const data = {
            email: inviteEmail.current.value,
            companyID: companyID,
        };
        await inviteClient(data).then((res) => {
            if (res) {
                queryClient.invalidateQueries(["client-invites"]);
                inviteEmail.current!.value = "";
                setIsOpen(false);
            }
        });
    }

    return (
        <>
            <Button
                icon="adduser"
                className="ttc"
                tooltip="Invite Client"
                onClick={() => setIsOpen(true)}
            />
            <Popup
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                className="small"
                title="Invite Client"
            >
                <p>
                    We will send an email to the client with a link to create an
                    account.
                </p>
                <CForm button="Send Invite" onSubmit={handleCreateInvite}>
                    <SimpleInput
                        label="Client Email"
                        type="email"
                        inputRef={inviteEmail}
                    />
                </CForm>
            </Popup>
        </>
    );
};

export default CreateClientInvite;
