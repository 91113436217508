import React, { FC, MouseEventHandler } from "react";
import "./Button.css";
import Loading from "../Elements/Loading";
import { comingSoon } from "../../util/helper";

interface ButtonProps {
    name?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    icon?: string;
    className?: string;
    type?: "button" | "submit" | "reset";
    tooltip?: string;
    copy?: string;
    style?: any;
    disabled?: boolean;
    loading?: boolean;
}

const Button: FC<ButtonProps> = ({
    onClick,
    name,
    className = "",
    icon = "",
    type,
    tooltip = "",
    style,
    disabled = false,
    loading = false,
}) => {
    return (
        <button
            className={`button ${className}${!!tooltip ? " hasTooltip" : ""}${
                loading ? " isLoading" : ""
            }`}
            onClick={onClick || type === "submit" ? onClick : comingSoon}
            aria-label={name ? name : tooltip ? tooltip : ""}
            data-tooltip={tooltip}
            disabled={loading || disabled}
            {...(type && { type })}
            {...(style && { style })}
        >
            {!!icon && <span className={`icon csic csic-${icon}`}></span>}
            {!!loading && <Loading />}
            {!!name && <span className="text">{name}</span>}
        </button>
    );
};

export default Button;
