import { BsFile } from "../../types/Folder";

export const folderIcon = (folder: string) => {
    switch (folder) {
        case "logos":
            return "logo";
        case "pictures":
            return "image";
        default:
            return "file";
    }
};

export const generateBsFile = (file: File) => {
    const { name, size, type } = file;
    const [fileType, fileExtension] = type.split("/");
    const fileUrl = URL.createObjectURL(file);
    return {
        _id: name.replace(/\s/g, "-").toLowerCase(),
        fileName: name,
        fileType: fileType,
        uploaded: new Date().toISOString(),
        lastModified: new Date().toISOString(),
        fileSize: `${size} bytes`,
        fileUrl: fileUrl,
        thumbnailUrl: fileUrl,
        isGenerated: true,
    } as BsFile;
};
