import { useCompany } from "../../context/companyContext";
import { useUser } from "../../context/userContext";
import ProjectList from "./ProjectList";
import Loading from "../Elements/Loading";
import NavLinkButton from "../Buttons/NavLinkButton";
import "./Header.css";
import Button from "../Buttons/Button";
import UserSection from "./UserSection";
import { NavLink } from "react-router-dom";
import { resendVerificationEmail } from "../../api/userApi";

export default function Header() {
    const { user } = useUser();
    const { company, isCompany } = useCompany();
    if (!isCompany || !user || !company) return <Loading />;

    return (
        <header className="headerBar">
            <Button
                icon="arrow-l"
                onClick={(e) => {
                    e.preventDefault();
                    document.body.classList.toggle("hideNav");
                }}
                className="navToggle"
            />
            <nav>
                <h1 className="h3Text addPadding">Brand Set</h1>
                <section className="mainNavSection">
                    <ul>
                        <li>
                            <NavLinkButton link="/" icon="home">
                                Home
                            </NavLinkButton>
                        </li>
                        {!company.isClientOrg && (
                            <li>
                                <NavLinkButton link="/clients" icon="building">
                                    Clients
                                </NavLinkButton>
                            </li>
                        )}
                    </ul>
                </section>
                <section className="projectsSection">
                    <h5>Projects</h5>
                    <div className="buttonGroup">
                        {!company.isClientOrg && (
                            <NavLinkButton
                                icon="plus"
                                link="/create"
                                tooltip="Create Project"
                                className="createProject"
                            />
                        )}
                    </div>
                    <ul className="projectList">
                        <ProjectList />
                    </ul>
                </section>
                <div className="messages">
                    {!user.firstName && (
                        <p className="message">
                            Complete your user profile{" "}
                            <NavLink to="/settings/user-profile">here</NavLink>
                        </p>
                    )}
                    {!user.isVerified && (
                        <p className="message">
                            Your email is not verified check your inbox for a
                            verification email or{" "}
                            <span
                                className="link"
                                onClick={resendVerificationEmail}
                            >
                                resend verification email
                            </span>
                        </p>
                    )}
                    {user.isGeneratedPassword && (
                        <p className="message">
                            Your password was automatically generated please
                            <NavLink to="/settings/security">
                                {" "}
                                create a new password
                            </NavLink>
                        </p>
                    )}
                </div>
                <UserSection />
            </nav>
        </header>
    );
}
