import chroma from "chroma-js";
import { extractColors, findAndReplaceAll } from "./svg";

const changeSvgColor = (
    svg: string,
    color: string,
    isSolid: boolean = true,
    threshold: number = 0.5,
    transparency: boolean = true
): string => {
    let newSvg = svg;
    const colors = extractColors(svg);
    const invert = chroma(color).luminance() > 0.5 ? "#000000" : "#ffffff";

    newSvg = findAndReplaceAll(newSvg, "<svg", `<svg fill="${color}"`);

    colors.sort((a, b) => {
        const colorA = chroma.contrast(a, "#fff");
        const colorB = chroma.contrast(b, "#fff");
        return colorA - colorB;
    });

    colors.forEach((c, i) => {
        newSvg = findAndReplaceAll(newSvg, c, colorId(i));
    });

    function colorId(i: number) {
        return `#color--${i}`;
    }

    if (isSolid) {
        colors.forEach((c, i) => {
            const hex = chroma(c).hex();
            if (hex === "#fff" || hex === "#ffffff") {
                newSvg = findAndReplaceAll(newSvg, colorId(i), invert);
            } else {
                newSvg = findAndReplaceAll(newSvg, colorId(i), color);
            }
        });
        return newSvg;
    }

    const hex = chroma(colors[0]).hex().toLowerCase();
    if (hex === "#fff" || hex === "#ffffff") {
        newSvg = findAndReplaceAll(newSvg, colorId(0), invert);
    } else {
        newSvg = findAndReplaceAll(newSvg, colorId(0), color);
    }

    if (colors.length > 1) {
        for (let i = 1; i < colors.length; i++) {
            let newColor;
            const hex = chroma(colors[i]).hex().toLowerCase();
            if (hex === "#fff" || hex === "#ffffff") {
                newColor = invert;
            } else {
                const intensity =
                    threshold + (1 - threshold) * (i / colors.length);
                if (transparency) {
                    newColor = chroma(color).alpha(intensity);
                } else {
                    newColor = chroma.mix(invert, color, intensity);
                }
            }
            newSvg = findAndReplaceAll(newSvg, colorId(i), newColor);
        }
    }

    return newSvg;
};

export default changeSvgColor;
