import React, { useState, useEffect, MouseEvent } from "react";

export interface MenuState {
    x: number;
    y: number;
    enabled: boolean;
    show: boolean;
}

const useContextMenu = () => {
    const [menu, setMenu] = useState<MenuState>({
        x: 0,
        y: 0,
        enabled: false,
        show: false,
    });

    const showMenu = (e: MouseEvent, offset = false) => {
        e.preventDefault();
        const target = e.target as HTMLElement;
        let x = e.pageX;
        let y = e.pageY;

        setMenu({
            x: 0,
            y: 0,
            show: false,
            enabled: true,
        });

        setTimeout(() => {
            const menuBB = document
                .getElementById("contextmenu")
                ?.getBoundingClientRect();
            if (!menuBB) return;

            if (offset) {
                const bb = target.getBoundingClientRect();
                x = bb.right - menuBB.width;
                if (bb.right < menuBB.width) {
                    x = bb.left + window.scrollX;
                }
                y = bb.bottom + window.scrollY + 5;
                if (bb.bottom + menuBB.width > window.innerHeight) {
                    y = bb.top - menuBB.height - 5 + window.scrollY;
                }
            }

            setMenu({
                x: x,
                y: y,
                show: true,
                enabled: true,
            });
        }, 0);
    };

    const closeMenu = () => {
        setMenu({
            x: 0,
            y: 0,
            show: false,
            enabled: false,
        });
    };

    useEffect(() => {
        const handleMouseDown = (e: MouseEvent | any) => {
            if (menu.enabled) {
                const target = e.target as HTMLElement;
                const constextMenu = document.getElementById("contextmenu");
                if (constextMenu?.contains(target)) return;
                closeMenu();
            }
        };

        if (menu.enabled) {
            document.addEventListener("mousedown", handleMouseDown);
        }

        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [menu.enabled]);

    return [menu, showMenu, closeMenu] as const;
};

export default useContextMenu;
