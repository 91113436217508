import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter } from "react-router-dom";
import UserProvider from "./context/userContext";
import StyleProvider from "./context/styleContext";
import App from "./App";
import NotificationProvider from "./context/notificationContext";
import Notifications from "./components/Notification/Notifications";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 600 * 1000,
            cacheTime: 600 * 1000,
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    // <React.StrictMode>
    <QueryClientProvider client={queryClient}>
        <BrowserRouter>
            <NotificationProvider>
                <StyleProvider>
                    <UserProvider>
                        <App />
                    </UserProvider>
                </StyleProvider>
                <Notifications />
            </NotificationProvider>
        </BrowserRouter>
        <ReactQueryDevtools />
    </QueryClientProvider>
    // </React.StrictMode>
);
