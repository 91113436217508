import React, { FC } from "react";
import "./Progress.css";

interface ProgressProps {
    value: number;
    className?: string;
    disableLoading?: boolean;
}

const Progress: FC<ProgressProps> = ({
    value,
    className = "",
    disableLoading = false,
}) => {
    return (
        <div
            className={`progress ${className} ${value > 0 || disableLoading} ${
                value === 100 ? "complete" : ""
            }`}
        >
            <div
                role="progressbar"
                style={{ width: `${value}%` }}
                aria-valuenow={value}
                aria-valuemin={0}
                aria-valuemax={100}
            >
                <span className="screenReaderOnly">{value}%</span>
            </div>
        </div>
    );
};

export default Progress;
