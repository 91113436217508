import axios from "axios";
import { CurrentUser, User } from "../types/User";
import { confirm } from "../util/helper";

type CreateUser = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
};

export const createUser = async (data: CreateUser) => {
    const res = await axios.post("/user/create", data);
    if (!res.data.token) return res;
    sessionStorage.setItem("brandset_token", res.data.token);
    return res;
};

type LoginUser = {
    email: string;
    password: string;
    remember: boolean;
};

export const loginUser = async (data: LoginUser) => {
    const res = await axios.put("/user/login", data);
    if (!res.data.token) return res;
    if (res.data.remember) {
        localStorage.setItem("brandset_token", res.data.token);
    } else {
        sessionStorage.setItem("brandset_token", res.data.token);
    }
    return res;
};

type VerifyEmail = {
    email: string;
    token: string;
};

export const verifyEmail = async (data: VerifyEmail) => {
    return await axios.put("/user/verify-email", data);
};

export const resendVerificationEmail = async () => {
    return await axios.get("/user/resend-verification-email");
};

type ForgotPassword = {
    email: string;
};
export const forgotPassword = async (data: ForgotPassword) => {
    return await axios.put("/user/forgot-password", data);
};

type ResetPassword = {
    email: string;
    otp: string;
    password: string;
};

export const resetPassword = async (data: ResetPassword) => {
    return await axios.put("/user/reset-password", data);
};

type SetPassword = {
    password: string;
    currentPassword?: string;
};

export const setPassword = async (data: SetPassword) => {
    return await axios.put("/user/set-password", data);
};

type UpdateUser = {
    firstName: string;
    lastName: string;
    email: string;
    userID: string;
};

export const updateUser = async (data: UpdateUser) => {
    return await axios.put("/user/update", data);
};

type UpdateUserPreferences = {
    preferences: any;
};

export const updateUserPreferences = async (data: UpdateUserPreferences) => {
    return await axios.put("/user/update-preferences", data);
};

export const getUser = async () => {
    const res = await axios.get("/user");
    return res.data as CurrentUser;
};

export const userLogout = async () => {
    localStorage.removeItem("brandset_token");
    sessionStorage.removeItem("brandset_token");
    return await axios.get("/user/logout");
};

export const deleteUser = async () => {
    if (!confirm("Are you sure you want to delete the user account?")) return;
    return await axios.delete("/user/delete");
};

export const getClients = async () => {
    const res = await axios.get("/user/clients");
    return res.data as User[];
};

export const getMembers = async () => {
    const res = await axios.get("/user/members");
    return res.data as User[];
};

type DeleteUserCompanyMember = {
    companyID: string;
    userID: string;
};
export const deleteUserCompanyMember = async (
    data: DeleteUserCompanyMember
) => {
    if (!confirm("Are you sure you want to remove the member?")) return;
    return await axios.put("/user/delete/company-member", data);
};

export const getNewLemonsqueezeUser = async (key: string) => {
    const res = await axios.get(`/user/new-lemonsqueeze/${key}`);
    if (!res.data.token) return res;
    if (res.data.remember) {
        localStorage.setItem("brandset_token", res.data.token);
    } else {
        sessionStorage.setItem("brandset_token", res.data.token);
    }
    return res;
};

export default axios;
