import React, { FC, ChangeEvent } from "react";
import "./Input.css";

interface InputProps {
    value: any;
    handleChange: (value: any) => void;
    label: string;
    type?: string;
    name?: string;
    className?: string;
    hideLabel?: boolean;
    afterText?: string;
}

const Input: FC<InputProps> = ({
    label,
    value,
    handleChange,
    name,
    type = "text",
    className = "",
    hideLabel = false,
    afterText = "",
}) => {
    const inputID = label.toLowerCase().replace(/ /g, "-");

    return (
        <div
            className={`inputWrapper input ${className} ${
                hideLabel ? "hideLabel" : ""
            } ${afterText ? "hasAfterText" : ""}`}
        >
            <input
                name={name || inputID}
                value={value}
                onChange={(e) => handleChange(e.target.value)}
                type={type}
                placeholder={label}
                id={inputID + "-input"}
            ></input>
            <label htmlFor={inputID + "-input"}>{label}</label>
            {afterText && <span className="afterText">{afterText}</span>}
        </div>
    );
};

export default Input;
