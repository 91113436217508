import React, { FC } from "react";
import "./RangeSlider.css";

type RangeSliderProps = {
    name: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value?: number;
    min?: number;
    max?: number;
    step?: number;
    className?: string;
};

const RangeSlider: FC<RangeSliderProps> = ({
    name,
    onChange,
    min = 0,
    max = 1,
    step = 0.01,
    value = 0,
    className = "",
}) => {
    return (
        <label
            className={`rangeSlider ${className}`}
            style={
                {
                    "--value": value,
                    "--min": min,
                    "--max": max,
                } as React.CSSProperties
            }
        >
            <output>
                <span>{name}</span>
                <span>{value}</span>
            </output>
            <input
                type="range"
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={(e) => onChange(e)}
            />
        </label>
    );
};

export default RangeSlider;
