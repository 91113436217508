import React, { FC } from "react";
import { ReactSVG } from "react-svg";
import "./SVG.css";

type SVGProps = {
    src: string;
    classNames?: string;
    style?: React.CSSProperties;
};

const SVG: FC<SVGProps> = ({ src, classNames = "", style }) => {
    return (
        <ReactSVG
            style={style}
            renumerateIRIElements={false}
            className={`svgContainer ${classNames}`}
            afterInjection={(error, svg) => {
                if (error) {
                    console.error(error);
                    return;
                }
            }}
            src={src}
        />
    );
};

export default SVG;
