import React, { FC } from "react";

interface ButtonGroupProps {
    className?: string;
    children: React.ReactNode;
}

const ButtonGroup: FC<ButtonGroupProps> = ({ children, className = "" }) => {
    return <div className={`buttonGroup ${className}`}>{children}</div>;
};

export default ButtonGroup;
