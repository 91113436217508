import React, { FC, useEffect, useRef, useState } from "react";
import Select from "react-select";
import webfonts from "../../util/Font/webfonts.json";
import ButtonGroup from "../Buttons/ButtonGroup";
import RadioButton from "../Buttons/RadioButton";
import Button from "../Buttons/Button";
import { loadFont } from "../../util/helper";
import FileUpload from "../Inputs/FileUpload";
import "./SelectFont.css";
import { Font, GoogleFont } from "../../types/Font";

type SelectFontProps = {
    name: string;
    previewText: string;
    font: Font;
    setFont: (font: Font) => void;
    fontFile: File | undefined;
    setFontFile: (file: File | undefined) => void;
    options: any;
    loadedFonts: any;
};

type FontWeight = {
    hasItalic: boolean;
    variants: string[];
};

type FontFiles = {
    [key: string]: string | undefined;
};

const SelectFont: FC<SelectFontProps> = ({
    name,
    previewText,
    font,
    setFont,
    fontFile,
    setFontFile,
    options,
    loadedFonts,
}) => {
    const defaultWeight = useRef(font.weight);
    const [fontWeights, setFontWeights] = useState<FontWeight>();

    useEffect(() => {
        setGoogleFont({ value: font.index, label: font.family });
    }, []);

    useEffect(() => {
        if (font.isGoogle) {
            setFontWeight(defaultWeight.current);
        }
    }, [fontWeights]);

    function setGoogleFont(e: GoogleFont) {
        const fontData = webfonts.items[e.value];

        const hasItalic = !!fontData.variants.find((el) =>
            el.includes("italic")
        );

        const variants = fontData.variants.filter(
            (el) => !el.includes("italic")
        );

        setFontWeights({
            hasItalic,
            variants,
        });

        setFont({
            isGoogle: true,
            family: fontData.family,
            weight: "regular",
            isItalic: false,
            index: e.value,
            link: fontData.files["regular"],
        });
    }

    async function handleFontUpload(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        e.stopPropagation();
        if (!e.target.files) return;
        const file = e.target.files[0];
        const name = file.name.split(".");

        font.family = name[0];
        font.fileName = file.name;
        font.link = "custom";
        font.weight = "regular";
        font.isItalic = false;

        const arrayBuffer = await file.arrayBuffer();
        const fontFace = new FontFace(name[0], arrayBuffer);

        await fontFace.load();
        document.fonts.add(fontFace);

        setFont({ ...font });
        setFontFile(file);
    }

    function setFontWeight(variant: string) {
        if (!fontWeights) return;
        if (!fontWeights.variants.includes(variant)) {
            variant = fontWeights.variants[0];
        }
        font.weight = variant;
        const fontData = webfonts.items[font.index];
        if (!fontData) return;

        if (font.weight === "regular" && font.isItalic) {
            font.link = fontData.files["italic"];
        } else {
            const variantName = font.weight + (font.isItalic ? "italic" : "");
            font.link = (fontData.files as FontFiles)[variantName];
        }
        loadFont(font);
        setFont({ ...font });
    }

    function setIsItalic() {
        font.isItalic = !font.isItalic;
        const fontData = webfonts.items[font.index];
        if (font.weight === "regular" && font.isItalic) {
            font.link = fontData.files["italic"];
        } else {
            const variantName = font.weight + (font.isItalic ? "italic" : "");
            font.link = (fontData.files as FontFiles)[variantName];
        }
        loadFont(font);
        setFont({ ...font });
    }

    function setIsGoogle(isGoogle: boolean) {
        if (isGoogle) {
            font.isGoogle = true;
            setFontFile(undefined);
        } else {
            font.isGoogle = false;
        }
        setFont({ ...font });
    }

    const setFontStyle: {
        control: (styles: any, state: any) => any;
        option: (styles: any, state: any) => any;
    } = {
        control: (styles: any, state: any) => ({
            ...styles,
            borderColor: "var(--border_color)",
            backgroundColor: "var(--bg)",
            boxShadow: state.isFocused ? "var(--input_box_shadow)" : undefined,
            "&:hover": {
                borderColor: "var(--border_color)",
                backgroundColor: "var(--bg)",
            },
        }),
        option: (styles: any, { data, isFocused }) => {
            if (isFocused && !loadedFonts.current.includes(data.value)) {
                loadedFonts.current.push(data.value);
                let link = document.createElement("Link");
                link.setAttribute(
                    "href",
                    encodeURI(
                        `https://fonts.googleapis.com/css?family=${data.label}`
                    )
                );
                link.setAttribute("rel", "stylesheet");
                link.setAttribute("type", "text/css");
                document.head.appendChild(link);
            }
            return { ...styles, fontFamily: `${data.label}, Sans-Serif` };
        },
    };

    if (!font) return null;

    return (
        <div className={`fontSelectContainer select-${name}`}>
            <h2
                className="font-preview"
                style={{
                    fontFamily: `"${font.family}"`,
                    fontWeight: font.weight === "regular" ? "400" : font.weight,
                    fontStyle: font.isItalic ? "italic" : "normal",
                }}
            >
                {previewText}
            </h2>
            <ButtonGroup className="left">
                <RadioButton
                    name={name + "-fontType"}
                    value="google"
                    checked={font.isGoogle}
                    onChange={(e) => setIsGoogle(true)}
                    label="Google Fonts"
                />
                <RadioButton
                    name={name + "-fontType"}
                    value="custom"
                    checked={!font.isGoogle}
                    onChange={(e) => setIsGoogle(false)}
                    label="Custom Font"
                />
            </ButtonGroup>

            {font.isGoogle ? (
                <>
                    <Select
                        defaultValue={{
                            value: font.index,
                            label: font.family,
                        }}
                        onChange={(e) => setGoogleFont(e as GoogleFont)}
                        styles={setFontStyle}
                        options={options}
                    />
                    <ButtonGroup className="left">
                        {fontWeights && fontWeights.hasItalic && (
                            <Button
                                className={`italic ${
                                    font.isItalic ? "active" : ""
                                }`}
                                onClick={() => setIsItalic()}
                                name="Italic"
                                type="button"
                                style={{ fontStyle: "italic" }}
                            />
                        )}
                        {fontWeights &&
                            fontWeights.variants?.length > 1 &&
                            fontWeights.variants.map((variant) => {
                                return (
                                    <RadioButton
                                        key={variant}
                                        name={name + "-variant"}
                                        value={variant}
                                        checked={font.weight === variant}
                                        onChange={() => setFontWeight(variant)}
                                        label={variant}
                                    />
                                );
                            })}
                    </ButtonGroup>
                </>
            ) : (
                <>
                    <FileUpload
                        name={name + "-font-file"}
                        label={fontFile ? font.family : "Upload Font File"}
                        accept=".ttf,.otf,.woff,.woff2"
                        onChange={(e) => handleFontUpload(e)}
                    />
                </>
            )}
        </div>
    );
};

export default SelectFont;
