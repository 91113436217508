import chroma from "chroma-js";
import React, { FC } from "react";
import { toLinearGradient } from "../../util/Color/getGradients";
import { copy } from "../../util/helper";
import Card from "../Card/Card";
import { switchColor } from "../../util/colorHelper";
import { Gradient } from "../../types/Project";
import "./GradientCard.css";
import Button from "../Buttons/Button";
import { useNotification } from "../../context/notificationContext";

type GradientCardProps = {
    gradient: Gradient;
};

const GradientCard: FC<GradientCardProps> = ({ gradient }) => {
    const colors = gradient.stops.map((stop) => {
        return stop.color;
    });

    const { sendNotification } = useNotification();

    return (
        <Card
            className="gradient floatingContainer"
            hero={
                <>
                    <div
                        className="preview"
                        style={{
                            backgroundImage: toLinearGradient(gradient),
                        }}
                    ></div>
                    <div className="variations">
                        {chroma
                            .scale(colors)
                            .colors(8)
                            .map((color, i) => {
                                return (
                                    <span
                                        key={color}
                                        className="copy"
                                        style={{
                                            backgroundColor: color,
                                        }}
                                        onClick={() =>
                                            copy(color, sendNotification)
                                        }
                                    ></span>
                                );
                            })}
                    </div>
                </>
            }
        >
            <Button
                tooltip="Copy CSS gradient"
                icon="code"
                onClick={() =>
                    copy(toLinearGradient(gradient), sendNotification)
                }
                className="floating top_right hidden"
            />
            {gradient.stops.map((stop, n) => {
                return (
                    <p
                        key={stop.color}
                        onClick={() => copy(stop.color, sendNotification)}
                        className="copy"
                    >
                        <span
                            style={{
                                background: stop.color,
                            }}
                        ></span>
                        {stop.color}
                    </p>
                );
            })}
        </Card>
    );
};

export default GradientCard;
