import { ReactSVG } from "react-svg";
import { optimize } from "svgo/dist/svgo.browser.js";
import chroma from "chroma-js";
import changeSvgColor from "./changeSvgColor";

export function RenderSVG({ classNames, src, style }) {
    return (
        <ReactSVG
            style={style}
            renumerateIRIElements={false}
            className={`svgContainer ${classNames}`}
            afterInjection={(error, svg) => {
                if (error) {
                    console.error(error);
                    return;
                }
            }}
            src={src}
        />
    );
}

export function optimizeSvg(svgString) {
    const optimizedSvg = optimize(svgString, {
        multipass: true,
        plugins: [
            {
                name: "inlineStyles",
                active: false,
            },
            {
                name: "prefixIds",
                active: true,
                params: {
                    prefix: Math.random()
                        .toString(36)
                        .replace(/[0-9]./g, ""),
                },
            },
        ],
    });
    return optimizedSvg.data;
}

export function encodeSVG(data) {
    // data = data.replace(/'/g, `"`);
    data = data.replace(/"/g, `'`);
    data = data.replace(/>\s{1,}</g, `><`);
    data = data.replace(/\s{2,}/g, ` `);
    return (
        "data:image/svg+xml;charset=utf-8," +
        data.replace(/[\r\n%#()<>?[\\\]^`{|}]/g, encodeURIComponent)
    );
}

export function svgToDataUri(svg) {
    const svgBlob = new Blob([svg], { type: "image/svg+xml;charset=utf-8" });
    const svgUrl = URL.createObjectURL(svgBlob);
    return svgUrl;
}

export async function optimizeSvgFile(file) {
    const svg = file;
    const name = file.name.split(".")[0];
    let svgString = await svg.text();
    svgString = optimizeSvg(svgString);
    svgString = convertSVGColorsTohex(svgString);
    const optimizedSvgFile = new File([svgString], name + ".svg", {
        type: "image/svg+xml",
    });
    return optimizedSvgFile;
}

export function convertSVGColorsTohex(svgString) {
    const colors = extractColors(svgString);
    colors.forEach((color) => {
        if (color[0] === "#") return;
        const hex = chroma(color).hex();
        svgString = findAndReplaceAll(svgString, color, hex);
    });
    return svgString;
}

export async function loadSVG(url) {
    const res = await fetch(url);
    return await res.text();
}

export async function loadSvgFile(name, url) {
    const svg = await loadSVG(url);
    return new File([svg], `${name}.svg`, {
        type: "image/svg+xml",
    });
}

export function generateSvgFile(
    name,
    svg,
    color,
    type,
    threshold,
    transparency
) {
    return new File(
        [
            optimizeSvg(
                changeSvgColor(svg, color, type, threshold, transparency)
            ),
        ],
        `${name}.svg`,
        {
            type: "image/svg+xml",
        }
    );
}

// export function extractColors(string) {
//     const colorPatterns = [
//         /#([\da-f]{3}){1,2}/gi, // Hexadecimal colors
//         /(rgb|hsl|hwb|lab|lch)a?\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)/gi, // RGB, HSL, HWB, LAB, LCH with alpha
//         /(rgb|hsl|hwb|lab|lch)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\)/gi, // RGB, HSL, HWB, LAB, LCH without alpha
//         /rgba\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)/gi, // RGBA color format
//         /hsla\(\d{1,3}%?(,\s?\d{1,3}%?){2}(,\s?(1|0?\.\d+))\)/gi, // HSLA color format
//         /(white|black|blue|red|green|yellow|magenta|cyan)/gi, // Named colors
//     ];

//     let colors = [];

//     for (const pattern of colorPatterns) {
//         const matches = string.match(pattern);
//         if (matches) {
//             colors.push(...matches);
//         }
//     }
//     return [...new Set([...colors])];
// }

export function extractColors(string) {
    const parser = new DOMParser();
    const parsedSvg = parser.parseFromString(string, "image/svg+xml");
    function extract(node, colors) {
        const fill = node.getAttributeNS(null, "fill");
        const stroke = node.getAttributeNS(null, "stroke");
        const style = node.getAttributeNS(null, "style");

        if (fill && fill !== "none") colors.add(fill);
        if (stroke && stroke !== "none") colors.add(stroke);

        const colorRegex = /(?:fill|stroke|color):\s*([^;}\s]+)/g;

        if (style) {
            const matches = style.match(colorRegex);
            if (matches) {
                matches.forEach((match) => {
                    const color = match.split(":")[1];
                    if (color !== "none") colors.add(color);
                });
            }
        }

        if (node.tagName === "stop") {
            const stopColor = node.getAttribute("stop-color");
            if (stopColor) colors.add(stopColor);
        }

        if (node.tagName === "style") {
            const matches = node.innerHTML.match(colorRegex);
            if (matches) {
                matches.forEach((match) => {
                    const color = match.split(":")[1];
                    if (color !== "none") colors.add(color);
                });
            }
        }

        const children = node.children;
        for (const child of children) {
            extract(child, colors);
        }
    }

    const colors = new Set();
    extract(parsedSvg.documentElement, colors);
    colors.forEach((color) => {
        if (!chroma.valid(color)) colors.delete(color);
    });
    return [...colors];
}

export function replaceAllColorSVG(string, color) {
    const re =
        /(#([\da-f]{3}){1,2}|(rgb|hsl)a\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)|(rgb|hsl)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\))/gi;
    return string.replace(re, color);
}

export function findAndReplaceAll(string, from, to) {
    const escapedFrom = from.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const re = new RegExp(escapedFrom, "ig");
    return string.replace(re, to);
}
