import React, { FC } from "react";
import "./ProjectBlock.css";
import ButtonGroup from "../Buttons/ButtonGroup";

type ProjectBlockProps = {
    children?: React.ReactNode;
    header?: React.ReactNode;
    options?: React.ReactNode;
    className?: string;
    headerC?: string;
    contentC?: string;
    boxed?: boolean;
};

const ProjectBlock: FC<ProjectBlockProps> = ({
    children,
    header,
    options,
    className = "",
    headerC = "",
    contentC = "",
    boxed = true,
}) => {
    return (
        <div className={`projectBlock ${className} ${boxed ? "boxed" : ""}`}>
            {header && (
                <div className={`projectBlock__header ${headerC}`}>
                    <div className="projectBlock__header__content">
                        {header}
                    </div>
                    {options && <ButtonGroup>{options}</ButtonGroup>}
                </div>
            )}
            <div className={`projectBlock__content ${contentC}`}>
                {children}
            </div>
        </div>
    );
};

export default ProjectBlock;
