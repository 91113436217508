import React, { FC } from "react";
import Button from "../Buttons/Button";
import ContextMenu from "../ContextMenu/ContextMenu";
import useContextMenu from "../ContextMenu/useContextMenu";
import {
    downloadImgSvg,
    downloadSvgToImg,
    downloadUrl,
} from "../../util/SVG/download";
import "./ImgBlock.css";

type ImgBlockProps = {
    src: string;
    alt?: string;
    className?: string;
    background?: string;
};

const ImgBlock: FC<ImgBlockProps> = ({
    src,
    alt = "image",
    className = "",
    background,
}) => {
    const [menu, openMenu] = useContextMenu();
    return (
        <div
            className={`imageBlock floatingContainer ${className}`}
            style={background ? { backgroundColor: background } : {}}
            onContextMenu={(e) => openMenu(e)}
        >
            <img src={src} alt={alt} />
            <Button
                className="floating"
                icon="download"
                tooltip="Download"
                onClick={(e) => openMenu(e, true)}
            />
            <ContextMenu menu={menu} className="col-2 center" width="120px">
                <Button name="SVG" onClick={() => downloadUrl(src, alt)} />
                <Button
                    name="PNG"
                    onClick={() =>
                        downloadSvgToImg({
                            src,
                            fileName: alt || "image",
                            type: "png",
                            background: background || "transparent",
                        })
                    }
                />
                <Button
                    name="JPG"
                    onClick={() => downloadImgSvg(src, alt, "jpeg")}
                />
                <Button name="PDF" />
            </ContextMenu>
        </div>
    );
};

export default ImgBlock;
