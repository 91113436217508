import React, { FC, useRef, useState, useEffect } from "react";
import CForm from "../../components/Inputs/CForm";
import { useUser } from "../../context/userContext";
import { useCompany } from "../../context/companyContext";
import { useCompanyStyle } from "../../context/styleContext";
import { useQueryClient } from "@tanstack/react-query";
import { toJson } from "../../util/helper";
import { updateCompanyStyle } from "../../api/companyApi";
import SimpleInput from "../../components/Inputs/SimpleInput";
import ImageDropZone from "../../components/DropZone/ImageDropZone";
import ColorInput from "../../components/Color/ColorInput";
import Page from "../../components/Templates/Page";
import Loading from "../../components/Elements/Loading";
import "./CustomizePortal.css";
import Setting from "../../components/Templates/Setting";

interface CustomizePortalProps {}

const CustomizePortal: FC<CustomizePortalProps> = ({}) => {
    const { company, isCompany } = useCompany();
    const queryClient = useQueryClient();

    const [primaryColor, setPrimaryColor] = useState<string>("#333333");
    const [secondaryColor, setSecondaryColor] = useState<string>("#333333");

    const metaTitle = useRef<HTMLInputElement>(null);
    const metaDescription = useRef<HTMLInputElement>(null);

    const [logo, setLogo] = useState<any>();
    const [logoInverse, setLogoInverse] = useState<any>();
    const [favicon, setFavicon] = useState<any>();

    useEffect(() => {
        if (isCompany && company) {
            setPrimaryColor(company.style.primaryColor);
            setSecondaryColor(company.style.secondaryColor);
        }
    }, [isCompany]);

    async function handleStyleUpdate() {
        if (!company) return;
        const style = {
            primaryColor: primaryColor,
            secondaryColor: secondaryColor,
            metaTitle: metaTitle.current?.value
                ? metaTitle.current.value
                : company.style.metaTitle,

            metaDescription: metaDescription.current?.value
                ? metaDescription.current.value
                : company.style.metaDescription,
        };

        const data = {
            companyID: company._id,
            style: toJson(style),
            logo,
            logoInverse,
            favicon,
        };

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        await updateCompanyStyle(data, config).then((res) => {
            if (res) {
                queryClient.invalidateQueries(["company"]);
                queryClient.invalidateQueries(["company-style"]);
            }
        });
    }

    if (!isCompany && !company) return <Loading />;

    return (
        <Setting title="Customize Portal">
            <CForm
                onSubmit={handleStyleUpdate}
                button="Save Changes"
                className="portalForm"
            >
                <h4>{company?.name} branding</h4>
                <p>Customize your portal to match your brand.</p>
                <div className="logos flex">
                    <ImageDropZone
                        currentUrl={company?.style.logo || null}
                        name="brand-logo"
                        text="Upload Logo"
                        img={logo}
                        setImg={setLogo}
                    />
                    <ImageDropZone
                        currentUrl={company?.style.logoInverse || null}
                        name="brand-logo-inverse"
                        text="Upload Inverse Logo"
                        img={logoInverse}
                        setImg={setLogoInverse}
                    />
                </div>

                <h4>Color Palette</h4>
                <div className="colorPaletteInput flex">
                    <ColorInput
                        label="Primary Color"
                        color={primaryColor}
                        setColor={setPrimaryColor}
                    />
                    <ColorInput
                        label="Secondary Color"
                        color={secondaryColor}
                        setColor={setSecondaryColor}
                    />
                </div>

                <h4>Meta Data</h4>
                <div className="metaData">
                    <ImageDropZone
                        currentUrl={company?.style.favicon || null}
                        name="brand-favicon"
                        text="Upload Favicon"
                        img={favicon}
                        setImg={setFavicon}
                    />
                    <div className="metaText">
                        <SimpleInput
                            label="Meta Title"
                            type="text"
                            inputRef={metaTitle}
                            defaultValue={company?.style?.metaTitle}
                        />
                        <SimpleInput
                            label="Meta Description"
                            type="text"
                            inputRef={metaDescription}
                            defaultValue={company?.style?.metaDescription}
                        />
                    </div>
                </div>
            </CForm>
        </Setting>
    );
};

export default CustomizePortal;
