import React, {
    FC,
    createContext,
    useContext,
    useEffect,
    useMemo,
} from "react";
import {
    InfiniteData,
    useInfiniteQuery,
    useQueryClient,
} from "@tanstack/react-query";
import {
    getAllProjects,
    getProject,
    getSharedProjects,
} from "../api/projectApi";
import { useCompany } from "./companyContext";
import { useUser } from "./userContext";
import { Project } from "./projectContext";

const ProjectContext = createContext<{
    projects: Project[] | undefined;
    fetchNextProjectsPage: () => void;
    projectsStatus: string;
    sharedProjects: Project[] | undefined;
    fetchNextSharedProjectsPage: () => void;
    sharedProjectsStatus: string;
    fetchProjectBySlug: (slug: string) => Promise<void>;
}>({
    projects: [],
    fetchNextProjectsPage: () => {},
    projectsStatus: "loading",
    sharedProjects: [],
    fetchNextSharedProjectsPage: () => {},
    sharedProjectsStatus: "loading",
    fetchProjectBySlug: async () => {},
});

type ProjectsProviderProps = {
    children: React.ReactNode;
};

const ProjectsProvider: FC<ProjectsProviderProps> = ({ children }) => {
    const queryClient = useQueryClient();

    const { isCompany, company } = useCompany();

    const {
        data: projectPages,
        fetchNextPage: fetchNextProjectsPage,
        status: projectsStatus,
    } = useInfiniteQuery<Project[]>({
        queryKey: ["projects"],
        queryFn: getAllProjects,
        enabled: isCompany,
        getNextPageParam: (lastPage, allPages) => {
            return lastPage.length && lastPage.length === 16
                ? allPages.length + 1
                : undefined;
        },
    });

    const {
        data: sharedProjectPages,
        fetchNextPage: fetchNextSharedProjectsPage,
        status: sharedProjectsStatus,
    } = useInfiniteQuery<Project[]>({
        queryKey: ["sharedProjects"],
        queryFn: getSharedProjects,
        enabled: isCompany,
        getNextPageParam: (lastPage, allPages) => {
            return lastPage.length && lastPage.length === 16
                ? allPages.length + 1
                : undefined;
        },
    });

    const projects = useMemo(() => projectPages?.pages.flat(), [projectPages]);

    const sharedProjects = useMemo(
        () => sharedProjectPages?.pages.flat(),
        [sharedProjectPages]
    );

    const fetchProjectBySlug = async (slug: string) => {
        if (!projects || projects.length === 0) {
            queryClient.invalidateQueries(["projects"]);
        } else {
            const project = (await getProject(slug)) as Project;
            if (project) {
                if (project.company === company?._id) {
                    queryClient.setQueryData(
                        ["projects"],
                        (oldData: InfiniteData<Project[]> | undefined) => {
                            if (!oldData) return;
                            if (projects.find((p) => p.slug === slug)) {
                                const newProjects = oldData.pages.map((page) =>
                                    page.map((p) =>
                                        p.slug === slug ? project : p
                                    )
                                );
                                return { pages: newProjects } as InfiniteData<
                                    Project[]
                                >;
                            } else {
                                const newProjects = [
                                    [project, ...oldData.pages[0]],
                                    ...oldData.pages.slice(1),
                                ];
                                return { pages: newProjects } as InfiniteData<
                                    Project[]
                                >;
                            }
                        }
                    );
                } else {
                    queryClient.setQueryData(
                        ["sharedProjects"],
                        (oldData: InfiniteData<Project[]> | undefined) => {
                            if (!oldData) return;
                            if (sharedProjects?.find((p) => p.slug === slug)) {
                                const newProjects = oldData.pages.map((page) =>
                                    page.map((p) =>
                                        p.slug === slug ? project : p
                                    )
                                );
                                return { pages: newProjects } as InfiniteData<
                                    Project[]
                                >;
                            } else {
                                const newProjects = [
                                    [project, ...oldData.pages[0]],
                                    ...oldData.pages.slice(1),
                                ];
                                return { pages: newProjects } as InfiniteData<
                                    Project[]
                                >;
                            }
                        }
                    );
                }
            }
        }
    };

    useEffect(() => {
        if (projects?.length === 0 && sharedProjects?.length === 0) {
            document.body.classList.add("hideNav");
        }
    }, [projects]);
    return (
        <ProjectContext.Provider
            value={{
                projects,
                fetchNextProjectsPage,
                projectsStatus,
                sharedProjects,
                fetchNextSharedProjectsPage,
                sharedProjectsStatus,
                fetchProjectBySlug,
            }}
        >
            {children}
        </ProjectContext.Provider>
    );
};

export default ProjectsProvider;

export function useProjects() {
    const context = useContext(ProjectContext);
    if (context === undefined) {
        throw new Error("useProjects in not within AllProjectProvider");
    }
    return context;
}
