import React, { FC, useEffect } from "react";
import "./SimpleCheckBox.css";

interface SimpleCheckBoxProps {
    name: string;
    label: string;
    value: string;
    inputRef: React.RefObject<HTMLInputElement>;
    defaultValue?: boolean;
    className?: string;
}

const SimpleCheckBox: FC<SimpleCheckBoxProps> = ({
    name,
    label,
    value,
    inputRef,
    defaultValue = false,
    className = "",
}) => {
    return (
        <label className={`inputWrapper simplecheckbox ${className}`}>
            {label}
            <input
                type="checkbox"
                name={name}
                value={value}
                ref={inputRef}
                defaultChecked={defaultValue}
            />
            <span></span>
        </label>
    );
};

export default SimpleCheckBox;
