import JSZip from "jszip";
import { encodeSVG } from "./svg";

export const downloadSvg = (svgString: string, fileName: string) => {
    const element = document.createElement("a");
    element.setAttribute("href", encodeSVG(svgString));
    element.setAttribute("download", fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    URL.revokeObjectURL(element.href);
};

export function downloadFile(file: File, fileName?: string) {
    const element = document.createElement("a");
    element.setAttribute("href", URL.createObjectURL(file));
    element.setAttribute("download", fileName || file.name);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    URL.revokeObjectURL(element.href);
}

export function downloadUrl(url: string, fileName: string) {
    const element = document.createElement("a");
    element.setAttribute("href", url);
    element.setAttribute("download", fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

export async function downloadZip(
    urls: string[],
    zipName: string,
    notify: any,
    updateNotify: any,
    rename?: string[]
) {
    if (!urls) return;
    if (urls.length === 0) {
        notify("No files to download", "error");
        return;
    }
    const nID = notify("Downloading files...", "loading");
    const zip = new JSZip();
    const downloadPromises = urls.map(async (url, i) => {
        const res = await fetch(url);
        const blob = await res.blob();
        if (rename && urls.length === rename.length) {
            const ext = url.split(".").pop();
            zip.file(rename[i] + ext, blob);
        } else {
            zip.file(url.split("/").pop() || "file", blob);
        }
    });

    await Promise.all(downloadPromises);
    const blob = await zip.generateAsync({ type: "blob" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${zipName}.zip`;
    a.click();
    URL.revokeObjectURL(url);
    updateNotify(nID, "success", "Files downloaded");
}

export function downloadImgSvg(
    url: string,
    fileName: string,
    type: "png" | "jpeg" = "png",
    size: number = 1200
) {
    const img = new Image();
    img.onload = () => {
        const canvas = document.createElement("canvas");
        const scale = Math.max(size / img.width, size / img.height);
        canvas.width = img.width * scale;
        canvas.height = img.height * scale;
        const ctx = canvas.getContext("2d");
        if (!ctx) return;
        if (type === "jpeg") {
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
        }
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        const a = document.createElement("a");
        a.download = fileName;
        a.href = canvas.toDataURL(`image/${type}`, 1);
        a.click();
        a.remove();
        canvas.remove();
    };
    img.src = url;
}

interface DownloadOptions {
    src: string;
    fileName: string;
    type?: "png" | "jpeg";
    size?: number;
    aspectRatio?: number;
    background?: string;
}

export function downloadSvgToImg({
    src,
    fileName,
    type = "png",
    size = 1200,
    aspectRatio = 0,
    background,
}: DownloadOptions) {
    const img = new Image();
    img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        const scale = Math.max(size / img.width, size / img.height);
        if (aspectRatio > 0) {
            canvas.width = size;
            canvas.height = size / aspectRatio;
        } else {
            canvas.width = img.width * scale;
            canvas.height = img.height * scale;
        }

        let x = 0;
        let y = 0;

        if (background) {
            ctx.fillStyle = background;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            x = (canvas.width - img.width * scale) / 2;
            y = (canvas.height - img.height * scale) / 2;
        }

        if (!background && type === "jpeg") {
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
        }
        ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
        const a = document.createElement("a");
        a.download = fileName;
        a.href = canvas.toDataURL(`image/${type}`, 1);
        a.click();
        a.remove();
        canvas.remove();
    };
    img.src = src;
}

// export default function createImageThumbnail(src, size = 300) {
//     return new Promise((resolve, reject) => {
//         if (!src) {
//             reject("Invalid arguments");
//             return;
//         }
//         const img = new Image();
//         img.onload = function () {
//             const canvas = document.createElement("canvas");
//             canvas.width = size;
//             canvas.height = size;
//             const ctx = canvas.getContext("2d");
//             const scale = Math.max(size / img.width, size / img.height);
//             const x = (size - img.width * scale) / 2;
//             const y = (size - img.height * scale) / 2;
//             ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
//             canvas.toBlob((blob) => resolve(blob), "image/jpeg", 0.8);
//         };
//         img.onerror = (e) => reject("Image load failed");
//         img.src = src;
//     });
// }
