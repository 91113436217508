import chroma from "chroma-js";
import React, { FC } from "react";
import {
    toLinearGradient,
    toLinearGradientColors,
} from "../../util/Color/getGradients";
import { debounce } from "../../util/helper";
import Card from "../Card/Card";
import RangeSlider from "../Inputs/RangeSlider";
import "./GradientsEditor.css";
import Button from "../Buttons/Button";
import ButtonGroup from "../Buttons/ButtonGroup";
import { Gradient } from "../../types/Project";

type GradientsEditorProps = {
    gradients: Array<Gradient>;
    setGradients: (gradients: Array<Gradient>) => void;
};

const GradientsEditor: FC<GradientsEditorProps> = ({
    gradients,
    setGradients,
}) => {
    const handleGradientColorChange = debounce(
        (i: number, j: number, val: string) => {
            gradients[i].stops[j].color = val;
            setGradients([...gradients]);
        },
        10
    );

    const handleGradientAngleChange = (i: number, val: number) => {
        gradients[i].angle = val;
        setGradients([...gradients]);
    };

    function handleGradientOffset(
        e: React.PointerEvent<HTMLDivElement>,
        i: number,
        j: number
    ) {
        e.preventDefault();
        e.stopPropagation();

        const target = e.target;
        (target as Element).classList.add("isDragging");

        document.addEventListener("pointermove", moveGradientStop);
        document.addEventListener("pointerup", endGradientOffset);

        function moveGradientStop(e: PointerEvent) {
            if (!(target instanceof HTMLElement)) return;
            if (!target.classList.contains("isDragging")) return;

            const track =
                target.parentElement?.parentElement?.getBoundingClientRect();
            if (!track) return;
            const dif = e.clientX - track.x;

            if (dif >= 0 && dif <= track.width) {
                const offset = Math.floor((dif / track.width) * 100) + "%";
                if (offset !== gradients[i].stops[j].offset) {
                    gradients[i].stops[j].offset = offset;
                    setGradients([...gradients]);
                }
            }
        }

        function endGradientOffset() {
            if (!(target instanceof HTMLElement)) return;
            target.classList.remove("isDragging");
            document.removeEventListener("pointermove", moveGradientStop);
            document.removeEventListener("pointerup", endGradientOffset);
        }
    }

    function addNewGradientStop(
        e: React.MouseEvent<HTMLSpanElement>,
        i: number
    ) {
        e.preventDefault();
        e.stopPropagation();
        const colorsArr = [] as Array<string>;
        const offsetArr = [] as Array<number>;
        gradients[i].stops.forEach((stop, j) => {
            colorsArr[j] = stop.color;
            offsetArr[j] = parseInt(stop.offset) / 100;
        });

        const gradient = chroma.scale(colorsArr).domain(offsetArr);
        const track = (
            e.target as Element
        ).parentElement?.parentElement?.getBoundingClientRect();
        if (!track) return;

        if (e.clientX !== 0) {
            const dif = e.clientX - track.x;
            const newOffset = parseFloat((dif / track.width).toFixed(2));

            gradients[i].stops.push({
                color: gradient(newOffset).toString(),
                offset: newOffset * 100 + "%",
            });

            gradients[i].stops.sort((a, b) => {
                return parseInt(a.offset) - parseInt(b.offset);
            });

            setGradients([...gradients]);
        }
    }

    function removeGradientStop(i: number, j: number) {
        if (gradients[i].stops.length > 2) {
            gradients[i].stops.splice(j, 1);
            setGradients([...gradients]);
        }
    }

    return (
        <div className="gradientPaletteInput col col-3">
            {gradients.map((gradient, i) => {
                return (
                    <Card
                        key={"gradient_" + i}
                        className="gradientEditor"
                        hero={
                            <span
                                className="preview"
                                style={{
                                    backgroundImage: toLinearGradient(gradient),
                                }}
                            ></span>
                        }
                        buttonGroup={
                            <Button
                                tooltip="Remove Gradient"
                                icon="delete"
                                onClick={(e) => {
                                    e.preventDefault();
                                    gradients.splice(i, 1);
                                    setGradients([...gradients]);
                                }}
                            />
                        }
                    >
                        <div className="stops">
                            <span
                                className="track"
                                onClick={(e) => addNewGradientStop(e, i)}
                                style={{
                                    background: `linear-gradient(90deg ${toLinearGradientColors(
                                        gradient
                                    )})`,
                                }}
                            ></span>
                            {gradient.stops.map((stop, j) => {
                                return (
                                    <div
                                        key={"stop_" + i + "_" + j}
                                        className="stop"
                                        onPointerDown={(e) =>
                                            handleGradientOffset(e, i, j)
                                        }
                                        style={{
                                            background: stop.color,
                                            left: stop.offset,
                                        }}
                                    >
                                        <ButtonGroup>
                                            <label className="button ">
                                                <span className="icon csic csic-edit"></span>
                                                <input
                                                    type="color"
                                                    value={stop.color}
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        handleGradientColorChange(
                                                            i,
                                                            j,
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </label>
                                            <Button
                                                tooltip="Remove Stop"
                                                icon="delete"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    removeGradientStop(i, j);
                                                }}
                                            />
                                        </ButtonGroup>
                                    </div>
                                );
                            })}
                        </div>
                        <RangeSlider
                            className="angleSlider"
                            name="Angle"
                            min={0}
                            max={360}
                            step={10}
                            value={gradient.angle}
                            onChange={(e) =>
                                handleGradientAngleChange(
                                    i,
                                    parseInt(e.target.value)
                                )
                            }
                        />
                    </Card>
                );
            })}
        </div>
    );
};

export default GradientsEditor;
