import { Route, Routes } from "react-router-dom";
import Clients from "../Clients/Clients";
import Home from "../Home/Home";
import CreateProject from "../Project/CreateProject";
import ProjectRouter from "../Project/ProjectRouter";
import { useProjects } from "../../context/projectsContext";
import Loading from "../../components/Elements/Loading";
import Settings from "../Settings/Settings";
import "./Dashboard.css";

export default function Dashboard() {
    const { projects, projectsStatus, sharedProjects, sharedProjectsStatus } =
        useProjects();

    if (!projects || projectsStatus === "loading") return <Loading />;
    if (!sharedProjects || sharedProjectsStatus === "loading")
        return <Loading />;

    return (
        <main className="dashboard">
            {projects.length === 0 && sharedProjects.length === 0 ? (
                <CreateProject />
            ) : (
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/clients" element={<Clients />} />
                    <Route path="/create" element={<CreateProject />} />
                    <Route
                        path="/project/:slug/*"
                        element={<ProjectRouter />}
                    />

                    <Route path="/settings/*" element={<Settings />} />
                    <Route path="*" element={<h1>Not Fount</h1>} />
                </Routes>
            )}
        </main>
    );
}
