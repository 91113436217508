import React, { FC } from "react";
import colorName from "../../util/Color/colorName";
import { debounce, throttle } from "../../util/helper";
import Card from "../Card/Card";
import Button from "../Buttons/Button";
import "./ColorPalette.css";
import { switchColor } from "../../util/colorHelper";
import { Color } from "../../types/Project";

type ColorPaletteProps = {
    colors: Array<Color>;
    setColors: (colors: Array<Color>) => void;
};

const ColorPalette: FC<ColorPaletteProps> = ({ colors, setColors }) => {
    const handleColorChange = debounce((code: string, i: number) => {
        const name = colorName(code);
        colors[i] = {
            code,
            name,
        };
        setColors([...colors]);
    }, 10);

    function shiftArr(
        e: React.MouseEvent<HTMLButtonElement>,
        i: number,
        n: number
    ) {
        e.preventDefault();
        if (n < 0 || n >= colors.length) return;
        const temp = colors.splice(i, 1)[0];
        colors.splice(n, 0, temp);
        setColors([...colors]);
    }

    return (
        <div className="colorPaletteEditors col col col-3">
            {colors.map((color, i) => {
                return (
                    <Card
                        key={"color-editor-" + i}
                        className="colorPaletteEditor"
                        hero={
                            <label
                                style={{
                                    backgroundColor: color.code,
                                }}
                            >
                                <input
                                    type="color"
                                    value={color.code}
                                    onChange={(e) =>
                                        handleColorChange(e.target.value, i)
                                    }
                                ></input>
                            </label>
                        }
                        buttonGroup={
                            <>
                                <Button
                                    tooltip="Shift Left"
                                    icon="arrow-l"
                                    onClick={(e) => shiftArr(e, i, i - 1)}
                                />
                                <Button
                                    tooltip="Shift Right"
                                    icon="arrow-r"
                                    onClick={(e) => shiftArr(e, i, i + 1)}
                                />
                                <Button
                                    tooltip="Delete"
                                    icon="delete"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        colors.splice(i, 1);
                                        setColors([...colors]);
                                    }}
                                />
                            </>
                        }
                    >
                        <div
                            style={
                                {
                                    "--text_color": switchColor(color.code),
                                } as React.CSSProperties
                            }
                        >
                            <p>{color.code}</p>
                            <span>{color.name}</span>
                        </div>
                    </Card>
                );
            })}
        </div>
    );
};

export default ColorPalette;
