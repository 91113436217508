import React from "react";
import { useUser } from "../../context/userContext";
import { useCompany } from "../../context/companyContext";
import Loading from "../Elements/Loading";
import Button from "../Buttons/Button";
import NavLinkButton from "../Buttons/NavLinkButton";
import "./UserSection.css";
import ContextMenu from "../ContextMenu/ContextMenu";
import useContextMenu from "../ContextMenu/useContextMenu";

const UserSection = () => {
    const { user, handleLogout, lvl } = useUser();
    const { company, isCompany } = useCompany();
    // const [isNavOpen, setIsNavOpen] = useState(false);
    const [userNav, openUserNav] = useContextMenu();

    if (!isCompany || !user || !company) return <Loading />;
    return (
        <section className="userSection">
            <h4>{isCompany ? company.name : ""}</h4>
            <Button
                className="profileIcon h5Text"
                onClick={(e) => openUserNav(e, true)}
                name={user.firstName ? user.firstName[0] : user.email[0]}
            />
            <ContextMenu menu={userNav}>
                <p className="padding_btn">
                    Hi!{" "}
                    {user.firstName
                        ? `${user.firstName} ${user.lastName}`
                        : user.email}
                    <span className="captionText">{user.email}</span>
                </p>
                <NavLinkButton link="/settings/user-profile" icon="user">
                    Edit Profile
                </NavLinkButton>
                <hr />{" "}
                {isCompany && (
                    <>
                        <p className="captionText">{company.name}</p>

                        {!company.isClientOrg && (
                            <>
                                <NavLinkButton
                                    link="/settings/org-profile"
                                    icon="settings"
                                >
                                    Admin
                                </NavLinkButton>

                                <NavLinkButton
                                    link="/settings/branding"
                                    icon="edit"
                                >
                                    Branding
                                </NavLinkButton>
                                <NavLinkButton
                                    link="/settings/billing"
                                    icon="card"
                                >
                                    Billing
                                </NavLinkButton>
                            </>
                        )}
                        <NavLinkButton link="/settings/team" icon="users">
                            Team
                        </NavLinkButton>
                    </>
                )}
                <hr />
                <Button icon="logout" onClick={handleLogout} name="Log Out" />
            </ContextMenu>
        </section>
    );
};

export default UserSection;
