import React, { useRef } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { updateUser } from "../../api/userApi";
import SimpleInput from "../../components/Inputs/SimpleInput";
import { useUser } from "../../context/userContext";
import CForm from "../../components/Inputs/CForm";
import Loading from "../../components/Elements/Loading";
import Setting from "../../components/Templates/Setting";
import OTPInput from "../../components/Inputs/OTPInput";

const EditProfile = () => {
    const { user } = useUser();
    const firstName = useRef<HTMLInputElement>(null);
    const lastName = useRef<HTMLInputElement>(null);
    const email = useRef<HTMLInputElement>(null);
    const otp = useRef<HTMLInputElement>(null);

    const queryClient = useQueryClient();

    async function handleUpdate() {
        if (!user) return;
        if (
            user.email !== email.current?.value ||
            user.firstName !== firstName.current?.value ||
            user.lastName !== lastName.current?.value
        ) {
            const data = {
                userID: user._id,
                firstName: firstName.current?.value
                    ? firstName.current.value
                    : user.firstName,
                lastName: lastName.current?.value
                    ? lastName.current.value
                    : user.lastName,
                email: email.current?.value ? email.current.value : user.email,
            };

            await updateUser(data).then((res) => {
                if (res) {
                    queryClient.invalidateQueries(["user"]);
                }
            });
        }
    }

    async function handleVerify() {
        if (!user) return;
    }

    if (!user) return <Loading />;

    return (
        <Setting title="Edit Profile">
            <CForm button="Update" onSubmit={handleUpdate} className="compact">
                <h4>
                    {!user.firstName
                        ? "Add your first and last name"
                        : "Your Account"}
                </h4>
                <div className="col col-2">
                    <SimpleInput
                        label="First Name"
                        type="text"
                        inputRef={firstName}
                        defaultValue={user.firstName}
                    />
                    <SimpleInput
                        label="Last Name"
                        type="text"
                        inputRef={lastName}
                        defaultValue={user.lastName}
                    />
                </div>
                <SimpleInput
                    label="Email"
                    type="email"
                    inputRef={email}
                    defaultValue={user.email}
                />
            </CForm>
            {!user.isVerified && (
                <CForm
                    button="Verify"
                    className="compact"
                    onSubmit={handleVerify}
                >
                    <br />
                    <h4>Verify your email</h4>
                    <p>
                        Your email is not verified check your inbox for a
                        verification code.
                    </p>
                    <OTPInput inputRef={otp} />
                </CForm>
            )}
        </Setting>
    );
};

export default EditProfile;
