import { Font } from "../types/Font";

export function toJson(arr: any) {
    return JSON.stringify(arr);
}

export async function loadFont(font: Font) {
    const fontFace = new FontFace(font.family, `url(${font.link})`, {
        style: font.isItalic ? "italic" : "normal",
        weight: font.weight === "regular" ? "normal" : font.weight,
    });

    await fontFace.load();
    document.fonts.add(fontFace);
}

export async function loadSVG(url: string) {
    const cache = await caches.open("svg-cache");
    const cached = await cache.match(url);

    if (cached) {
        return await cached.text();
    }

    const res = await fetch(url, { cache: "no-cache" });
    await cache.put(url, res.clone());
    const text = await res.text();
    return text;
}

export function formateDate(date: string) {
    var options = {
        hour: "numeric",
        minute: "numeric",
        hourCycle: "h12",
        year: "numeric",
        month: "short",
        day: "numeric",
    };
    const temp = new Date(parseInt(date));
    return temp.toLocaleString("en-US", options as any);
}

export function copy(data: string, notify: any) {
    navigator.clipboard.writeText(data);
    if (notify) {
        notify("Copied to clipboard");
    }
}

export function debounce(cb: (...args: any[]) => void, delay = 1000) {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    return (...args: any[]) => {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            cb(...args);
        }, delay);
    };
}

export function throttle(cb: (...args: any[]) => void, delay = 1000) {
    let shouldWait = false;
    let waitingArgs: any[] | null;

    const timeoutFunc = () => {
        if (waitingArgs == null) {
            shouldWait = false;
        } else {
            cb(...waitingArgs);
            waitingArgs = null;
            setTimeout(timeoutFunc, delay);
        }
    };

    return (...args: any[]) => {
        if (shouldWait) {
            waitingArgs = args;
            return;
        }

        cb(...args);
        shouldWait = true;

        setTimeout(timeoutFunc, delay);
    };
}

export function loadGoogleFont(name = "Roboto") {
    let link = document.createElement("Link");
    link.setAttribute(
        "href",
        encodeURI(`https://fonts.googleapis.com/css?family=${name}`)
    );
    link.setAttribute("rel", "stylesheet");
    link.setAttribute("type", "text/css");
    document.head.appendChild(link);
}

export function setLogedInCookie(remember = false) {
    const maxAge = remember ? 2592000 : 0;
    document.cookie = `loggedIn=true;max-age=${maxAge};path=/`;
}

export function kbToSize(kb: number) {
    const mbThreshold = 1024;
    const gbThreshold = 1024 * 1024;

    if (kb < gbThreshold) {
        return (kb / mbThreshold).toFixed(2) + " MB";
    } else {
        return (kb / gbThreshold).toFixed(2) + " GB";
    }
}

export function kbToGb(kb: number) {
    const gbThreshold: number = 1024 * 1024;
    return kb / gbThreshold;
}

export function getQueryParams() {
    const urlParams = new URLSearchParams(window.location.search);
    const params: any = {};
    urlParams.forEach((value, key) => {
        params[key] = value;
    });
    return params;
}

export function confirm(message: string) {
    return window.confirm(message);
}

export function comingSoon() {
    alert("Coming Soon");
}
