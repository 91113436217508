import React, { FC, createContext, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { getCompany, getClientCompanies } from "../api/companyApi";
import { useUser } from "./userContext";

interface Company {
    _id: string;
    admin: string;
    name: string;
    subdomain: string;
    plan: {
        name: string;
        limits: {
            clients: number;
            members: number;
            storage: number;
            projects: number;
            customDomain: boolean;
        };
        _id: string;
    };
    createdAt: string;
    updatedAt: string;
    members: string[];
    clients: string[];
    customDomain: string;
    storageUsage: number;
    style: {
        primaryColor: string;
        secondaryColor: string;
        metaTitle: string;
        metaDescription: string;
        favicon: string | null;
        logo: string | null;
        logoInverse: string | null;
    };
    isClientOrg: boolean;
}

const CompanyContext = createContext<{
    company: Company | undefined | null;
    companyIsLoading: boolean;
    isCompany: boolean;
    isClientOrgs: boolean;
    clientOrgs: Company[] | null;
}>({
    company: null,
    companyIsLoading: false,
    isCompany: false,
    isClientOrgs: false,
    clientOrgs: null,
});

interface CompanyProviderProps {
    children: React.ReactNode;
}

const CompanyProvider: FC<CompanyProviderProps> = ({ children }) => {
    const { user, isUser } = useUser();

    const {
        data: company,
        isLoading: companyIsLoading,
        isSuccess: isCompany,
    } = useQuery<Company>(["company"], getCompany, {
        enabled: isUser && !!user?.company,
    });

    const { data: clientOrgs, isSuccess: isClientOrgs } = useQuery(
        ["client-orgs"],
        getClientCompanies,
        {
            enabled: isCompany && company.clients.length > 0,
        }
    );

    return (
        <CompanyContext.Provider
            value={{
                company,
                companyIsLoading,
                isCompany,
                clientOrgs,
                isClientOrgs,
            }}
        >
            {children}
        </CompanyContext.Provider>
    );
};

export default CompanyProvider;

export function useCompany() {
    const context = useContext(CompanyContext);

    if (context === undefined) {
        throw new Error("useCompany in not within CompanyProvider");
    }

    return context;
}
