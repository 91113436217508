export default function createVideoThumbnail(src, size = 300) {
    return new Promise((resolve, reject) => {
        if (!src) {
            reject("Invalid arguments");
            return;
        }
        const video = document.createElement("video");
        video.preload = "metadata";
        video.onloadedmetadata = function () {
            const canvas = document.createElement("canvas");
            canvas.width = size;
            canvas.height = size;
            const ctx = canvas.getContext("2d");

            const min = Math.min(video.videoWidth, video.videoHeight);
            const x = (video.videoWidth - min) / 2;
            const y = (video.videoHeight - min) / 2;
            ctx.drawImage(
                video,
                x,
                y,
                min,
                min,
                0,
                0,
                canvas.width,
                canvas.height
            );

            canvas.toBlob((blob) => resolve(blob), "image/jpeg", 0.8);
        };
        video.onerror = (e) => reject("Vidoe load failed");
        video.onloadeddata = function () {
            // Metadata loaded successfully, but we still need to wait for the first frame to load
            video.currentTime = 1;
        };
        video.onseeked = function () {
            // First frame loaded successfully
            video.removeEventListener("onloadeddata", null);
            video.removeEventListener("onseeked", null);
        };
        video.src = src;
    });
}
