import React, { FC, useRef } from "react";
import Portal from "../../components/Templates/Portal";
import CForm from "../../components/Inputs/CForm";
import SimpleInput from "../../components/Inputs/SimpleInput";
import { useNotification } from "../../context/notificationContext";
import OTPInput from "../../components/Inputs/OTPInput";
import { resetPassword } from "../../api/userApi";
import { useNavigate } from "react-router-dom";

interface ResetPasswordProps {}

const ResetPassword: FC<ResetPasswordProps> = ({}) => {
    const otp = useRef<HTMLInputElement>(null);
    const userEmail = useRef<HTMLInputElement>(null);
    const newPassword = useRef<HTMLInputElement>(null);
    const confirmPassword = useRef<HTMLInputElement>(null);
    const { sendNotification } = useNotification();
    const navigate = useNavigate();

    async function handleResetPassword() {
        const data = {
            email: userEmail.current?.value ?? "",
            otp: otp.current?.value ?? "",
            password: newPassword.current?.value ?? "",
        };

        if (data.otp.length !== 6) {
            sendNotification("OTP must be 6 characters", "error");
            return;
        } else if (data.password !== confirmPassword.current?.value) {
            sendNotification("Passwords do not match", "error");
            return;
        } else if (data.password.length < 8) {
            sendNotification("Password must be at least 8 characters", "error");
            return;
        }

        await resetPassword(data).then((res) => {
            if (res) {
                navigate("/");
            }
        });
    }

    return (
        <Portal title="Reset Password">
            <CForm onSubmit={handleResetPassword} button="Reset Password">
                <SimpleInput
                    label="Email"
                    type="email"
                    inputRef={userEmail}
                    defaultValue=""
                />

                <SimpleInput
                    label="New Password"
                    type="password"
                    inputRef={newPassword}
                />

                <SimpleInput
                    label="Confirm Password"
                    type="password"
                    inputRef={confirmPassword}
                />
                <OTPInput inputRef={otp} />
            </CForm>
        </Portal>
    );
};

export default ResetPassword;
