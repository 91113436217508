import React, { FC } from "react";

interface IconProps {
    name: string;
    text?: string;
    className?: string;
}

const Icon: FC<IconProps> = ({ name, text, className = "" }) => {
    return <span className={`csic csic-${name} ${className}`}>{text}</span>;
};

export default Icon;
