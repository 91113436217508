import React, { FC } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { deleteCompany } from "../../api/companyApi";
import { cancelInvite, resendInvite } from "../../api/inviteApi";
import { deleteUserCompanyMember } from "../../api/userApi";
import { copy } from "../../util/helper";
import CreateClientInvite from "../../Popups/CreateClientInvite";
import Button from "../Buttons/Button";
import ButtonGroup from "../Buttons/ButtonGroup";
import { useNotification } from "../../context/notificationContext";
import { ClientInvite, User } from "../../types/User";
import List from "../Templates/List";
import "./ClientOrg.css";

type ClientOrgProps = {
    org: any;
    clients: User[];
    invites: ClientInvite[];
};

const ClientOrg: FC<ClientOrgProps> = ({ org, clients, invites }) => {
    const queryClient = useQueryClient();
    const { sendNotification } = useNotification();

    function handleCancelInvite(inviteID: string) {
        cancelInvite(inviteID).then((res) => {
            if (res) {
                queryClient.invalidateQueries(["client-invites"]);
            }
        });
    }

    function handleRemoveClient(userID: string) {
        const data = {
            userID,
            companyID: org._id,
        };

        deleteUserCompanyMember(data).then((res) => {
            if (res) {
                queryClient.invalidateQueries(["clients"]);
            }
        });
    }

    function handleDeleteCompany(companyID: string, name: string) {
        deleteCompany({ companyID }).then((res) => {
            if (res) {
                queryClient.invalidateQueries(["client-orgs"]);
            }
        });
    }

    return (
        <div className="clientOrg cardStyle">
            <List
                title={org.name}
                buttonGroup={
                    <>
                        <Button
                            icon="edit"
                            className="ttc"
                            tooltip="Edit Company"
                        />
                        <CreateClientInvite companyID={org._id} />
                        <Button
                            icon="delete"
                            tooltip="Delete Company"
                            onClick={() =>
                                handleDeleteCompany(org._id, org.name)
                            }
                        />
                    </>
                }
                listClassName="compact"
            >
                {clients.map((client) => {
                    return (
                        <li key={client._id}>
                            <p>{client.email}</p>
                            <ButtonGroup>
                                <Button
                                    icon="delete"
                                    onClick={() =>
                                        handleRemoveClient(client._id)
                                    }
                                    tooltip="Remove Client"
                                />
                            </ButtonGroup>
                        </li>
                    );
                })}
            </List>

            {invites.length > 0 && (
                <List
                    title="Invites"
                    headingClassName="invites"
                    listClassName="compact"
                >
                    {invites.map((invite) => (
                        <li key={invite._id}>
                            <p>{invite.email}</p>
                            <ButtonGroup>
                                <Button
                                    icon="link"
                                    className="ttc"
                                    onClick={() =>
                                        copy(
                                            `${window.location.origin}/?join=${invite._id}`,
                                            sendNotification
                                        )
                                    }
                                    tooltip="Copy Invite Link"
                                />
                                <Button
                                    icon="pointer"
                                    onClick={() =>
                                        resendInvite({ inviteID: invite._id })
                                    }
                                    tooltip="Resend Invite"
                                />
                                <Button
                                    icon="delete"
                                    onClick={() =>
                                        handleCancelInvite(invite._id)
                                    }
                                    tooltip="Cancel Invite"
                                />
                            </ButtonGroup>
                        </li>
                    ))}
                </List>
            )}
        </div>
    );
};

export default ClientOrg;
