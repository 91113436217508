import chroma from "chroma-js";

export function toLinearGradient(obj) {
    let res = `linear-gradient(${obj.angle}deg`;
    obj.stops.forEach((stop) => {
        res += `, ${stop.color} ${stop.offset}`;
    });
    res += `)`;
    return res;
}

export function toLinearGradientColors(obj) {
    let res = "";
    obj.stops.forEach((stop) => {
        res += `, ${stop.color} ${stop.offset}`;
    });
    return res;
}

export function getGradients(string) {
    const doc = new DOMParser().parseFromString(string, "image/svg+xml");
    const linearGradients = doc.querySelectorAll("linearGradient");
    let linearGradientsArr = [];

    linearGradients.forEach((gradient, i) => {
        const att = gradient.attributes;
        const stops = gradient.childNodes;
        if (stops.length > 2) {
            const x1 = att.x1 ? parseInt(att.x1.value) : 0;
            const x2 = att.x2 ? parseInt(att.x2.value) : 0;
            const y1 = att.y1 ? parseInt(att.y1.value) : 0;
            const y2 = att.y2 ? parseInt(att.y2.value) : 0;
            const angle = getGradientAngle(x1, x2, y1, y2).toFixed(2);

            linearGradientsArr[i] = {
                type: "linear",
                angle: angle,
                stops: [],
            };

            stops.forEach((stop, n) => {
                let color = "#000000";
                let offset = stop.attributes["offset"].value;

                if (stop.attributes["stop-color"]) {
                    color = chroma(stop.attributes["stop-color"].value).hex();
                } else {
                    color = chroma(stop.style["stop-color"]).hex();
                }

                if (!offset.endsWith("%")) {
                    offset = `${stop.attributes["offset"].value * 100}%`;
                }

                linearGradientsArr[i].stops[n] = {
                    color,
                    offset,
                };
            });
        }
    });
    return linearGradientsArr;
}

const formatPosition = (pos) =>
    Number(`${pos}`.trim().endsWith("%") ? pos.trim().replace("%", "") : pos);

function getGradientAngle(x1, x2, y1, y2) {
    [x1, x2, y1, y2] = [x1, x2, y1, y2].map(formatPosition);

    const x = x2 - x1;
    const y = y2 - y1;

    // Single axis
    if (y === 0) {
        return x1 > x2 ? 270 : 90;
    }

    if (x === 0) {
        return y1 > y2 ? 0 : 180;
    }

    // Converts angle in degrees
    const angleRad = Math.atan2(y, x);
    return clampAngle((angleRad * 180) / Math.PI + 90);
}

function clampAngle(angle, min = 0, max = 360) {
    if (angle < min) {
        return 360 + angle;
    }
    if (angle > max) {
        return angle - 360;
    }
    return angle;
}
