import React, { FC, useState } from "react";
import "./CForm.css";
import Button from "../Buttons/Button";

interface CFormProps {
    onSubmit: () => Promise<void>;
    children: React.ReactNode;
    button: string;
    className?: string;
    disabled?: boolean;
}

const CForm: FC<CFormProps> = ({
    onSubmit,
    children,
    button,
    className = "",
    disabled = false,
}) => {
    const [isSubmiting, setIsSubmiting] = useState(false);

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (isSubmiting) return;
        setIsSubmiting(true);
        try {
            await onSubmit();
        } catch (error) {
            console.log(error);
        }
        setIsSubmiting(false);
        // await onSubmit();
        // setIsSubmiting(false);
    }

    return (
        <form onSubmit={handleSubmit} className={`styledForm ${className}`}>
            {children}
            <Button
                name={button}
                type="submit"
                className="styled"
                loading={isSubmiting}
                disabled={isSubmiting || disabled}
            />
        </form>
    );
};

export default CForm;
