import React from "react";
import { useCompany } from "../../context/companyContext";
import { useNotification } from "../../context/notificationContext";
import { useQueryClient } from "@tanstack/react-query";
import Loading from "../../components/Elements/Loading";
import Page from "../../components/Templates/Page";
import Content from "../../components/Templates/Content";
import Progress from "../../components/Elements/Progress";
import { kbToSize } from "../../util/helper";
import Button from "../../components/Buttons/Button";
import Icon from "../../components/Templates/Icon";
import "./Billing.css";
import Setting from "../../components/Templates/Setting";

function Billing() {
    const { company } = useCompany();

    if (!company) return <Loading />;

    return (
        <Setting title="Billing">
            <Content>
                <div className="planCardContainer">
                    <div className="planCard">
                        <div className="hero">
                            <p>
                                Your Current Plan <br />
                                <h3>{company.plan.name}</h3>
                            </p>

                            <Button className="styled" name="Upgrade" />
                        </div>
                        <div className="info">
                            <div className="infoItem">
                                <Icon name="save" />
                                <div className="storage">
                                    <div className="col-2">
                                        <p>
                                            Used:{" "}
                                            <b>
                                                {kbToSize(company.storageUsage)}{" "}
                                            </b>
                                        </p>
                                        <p>
                                            Total:{" "}
                                            <b>
                                                {kbToSize(
                                                    company.plan.limits.storage
                                                )}
                                            </b>
                                        </p>
                                    </div>
                                    <Progress
                                        value={
                                            (company.storageUsage /
                                                company.plan.limits.storage) *
                                            100
                                        }
                                        disableLoading={true}
                                    />
                                </div>
                            </div>
                            <p className="infoItem">
                                <Icon name="users" />
                                Team Members: {company.plan.limits.members}
                            </p>
                            <p className="infoItem">
                                <Icon name="file" />
                                Projects: {company.plan.limits.projects}
                            </p>
                            <p className="infoItem">
                                <Icon name="building" />
                                Client Organizations:{" "}
                                {company.plan.limits.clients}
                            </p>
                        </div>
                    </div>
                </div>
            </Content>
        </Setting>
    );
}

export default Billing;
