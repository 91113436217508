import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createProject } from "../../api/projectApi";
import { toJson } from "../../util/helper";
import { extractColors } from "../../util/SVG/svg";
import colorName from "../../util/Color/colorName";
import chroma from "chroma-js";
import { getGradients } from "../../util/Color/getGradients";
import GradientsEditor from "../../components/Color/GradientsEditor";
import ColorPalette from "../../components/Color/ColorPalette";
import SelectFonts from "../../components/CreateProject/SelectFonts";
import TypeScale from "../../components/Font/TypeScale";
import Page from "../../components/Templates/Page";
import Button from "../../components/Buttons/Button";
import ButtonGroup from "../../components/Buttons/ButtonGroup";
import ProjectDetails from "../../components/CreateProject/ProjectDetails";
import LogoVariants from "../../components/CreateProject/LogoVariants";
import "./CreateProject.css";
import { Font, TypeScaleType } from "../../types/Font";
import Progress from "../../components/Elements/Progress";
import { Color, Gradient, InvertedLogo } from "../../types/Project";
import { useProjects } from "../../context/projectsContext";
import CForm from "../../components/Inputs/CForm";
import { useNotification } from "../../context/notificationContext";
import { isTextValid } from "../../util/validator";

const CreateProject = () => {
    const navigate = useNavigate();
    const { fetchProjectBySlug } = useProjects();
    const { sendNotification } = useNotification();
    const [step, setStep] = useState<number>(0);

    const name = useRef<HTMLInputElement>(null);
    const url = useRef<HTMLInputElement>(null);

    const [logo, setLogo] = useState<File | undefined>();
    const [logomark, setLogomark] = useState<File | undefined>();

    const [invertedLogo, setInvertedLogo] = useState<InvertedLogo>({
        type: "solid",
        threshold: 0.5,
        transparency: true,
        white: undefined,
        black: undefined,
    });

    const [invertedLogomark, setInvertedLogomark] = useState<InvertedLogo>({
        type: "solid",
        threshold: 0.5,
        transparency: true,
        white: undefined,
        black: undefined,
    });

    const [colors, setColors] = useState<Color[]>([]);
    const [gradients, setGradients] = useState<Gradient[]>([]);

    const [headingFont, setHeadingFont] = useState<Font>({
        isGoogle: true,
        family: "Poppins",
        weight: "700",
        isItalic: false,
        index: 1143,
    });
    const [headingFontFile, setHeadingFontFile] = useState<File | undefined>();

    const [subHeadingFont, setSubHeadingFont] = useState<Font>({
        isGoogle: true,
        family: "Poppins",
        weight: "600",
        isItalic: false,
        index: 1143,
    });
    const [subHeadingFontFile, setSubHeadingFontFile] = useState<
        File | undefined
    >();

    const [bodyFont, setBodyFont] = useState<Font>({
        isGoogle: true,
        family: "Poppins",
        weight: "regular",
        isItalic: false,
        index: 1143,
    });
    const [bodyFontFile, setBodyFontFile] = useState<File | undefined>();

    const [typescale, setTypescale] = useState<TypeScaleType>({
        fontSize: 16,
        scaleRatio: "1.125",
        previewText: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
    });

    const [pageState, setPageState] = useState({
        startedUpload: false,
        uploadProgress: 0,
    });

    async function handleCreateProject() {
        if (!name.current?.value || !url.current?.value) return;
        const data = {
            name: name.current.value,
            url: url.current.value,
            logo,
            logomark,
            invertedLogo: toJson({
                type: invertedLogo.type,
                threshold: invertedLogo.threshold,
            }),
            logoWhite: invertedLogo.white,
            logoBlack: invertedLogo.black,
            invertedLogomark: toJson({
                type: invertedLogomark.type,
                threshold: invertedLogomark.threshold,
            }),
            logomarkWhite: invertedLogomark.white,
            logomarkBlack: invertedLogomark.black,
            colors: toJson(colors),
            gradients: toJson(gradients),
            headingFont: toJson(headingFont),
            headingFontFile,
            subHeadingFont: toJson(subHeadingFont),
            subHeadingFontFile,
            bodyFont: toJson(bodyFont),
            bodyFontFile,
            typescale: toJson(typescale),
        };

        if (!isTextValid(data.name)) {
            sendNotification(
                "Project name must be between 3 to 20 characters",
                "error"
            );
            name.current?.focus();
            return;
        }

        if (!isTextValid(data.url)) {
            sendNotification(
                "Project url must be between 3 to 20 characters",
                "error"
            );
            url.current?.focus();
            return;
        }

        if (!data.logo) {
            sendNotification("Please upload a logo", "error");
            setStep(0);
            return;
        }

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: function (progressEvent: ProgressEvent) {
                pageState.uploadProgress =
                    (progressEvent.loaded / progressEvent.total) * 100;
                setPageState({ ...pageState });
            },
        };

        await createProject(data, config).then((res) => {
            if (res) {
                fetchProjectBySlug(data.url).then(() => {
                    navigate(`/project/${data.url}`);
                });
            }
        });
    }

    useEffect(() => {
        const updateColors = async () => {
            const svgString = await logo?.text();
            if (!svgString) return;
            let colorsArr = extractColors(svgString);
            if (colorsArr.length === 0) {
                colorsArr = ["#000000", "#ffffff"];
            } else if (colorsArr.length === 1) {
                colorsArr.push("#ffffff");
            }

            let colorsObjArr = [] as Color[];

            const gradientsArr = getGradients(svgString);
            setGradients(gradientsArr);
            colorsArr.forEach((color) => {
                colorsObjArr.push({
                    code: chroma(color).hex(),
                    name: colorName(color),
                });
            });
            setColors(colorsObjArr);
        };
        updateColors();
    }, [logo]);

    return (
        <Page title="Create Project">
            <CForm
                button="Create Project"
                onSubmit={handleCreateProject}
                className="styledForm createProject"
            >
                <ProjectDetails
                    name={name}
                    url={url}
                    logo={logo}
                    setLogo={setLogo}
                    logomark={logomark}
                    setLogomark={setLogomark}
                />
                <fieldset>
                    <legend>Logo variations</legend>
                    <p>
                        We have generated black and white variations of the logo
                        you uploade. but you can also customize them.
                    </p>
                    <LogoVariants
                        logo={logo}
                        inverted={invertedLogo}
                        setInverted={setInvertedLogo}
                    />
                </fieldset>

                {logomark && (
                    <fieldset>
                        <legend>Logomark variations</legend>
                        <p>
                            We have generated black and white variations of the
                            logomark you uploade. but you can also customize
                            them.
                        </p>
                        <LogoVariants
                            logo={logomark}
                            inverted={invertedLogomark}
                            setInverted={setInvertedLogomark}
                        />
                    </fieldset>
                )}
                {/* )}
                {step === 2 && ( */}
                <>
                    <fieldset>
                        <div className="col col-2">
                            <legend>Color palette</legend>
                            <ButtonGroup>
                                <Button
                                    name="Add Color"
                                    icon="plus"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setColors([
                                            ...colors,
                                            {
                                                code: "#000000",
                                                name: "Black",
                                            },
                                        ]);
                                    }}
                                />
                            </ButtonGroup>
                        </div>
                        <p>
                            We have generated a color palette from the logo you
                            uploaded, but you can also customize or add more
                            colors.
                        </p>
                        <ColorPalette colors={colors} setColors={setColors} />
                    </fieldset>

                    <fieldset>
                        <div className="col col-2">
                            <legend>Gradients</legend>
                            <ButtonGroup>
                                <Button
                                    name="Add Gradient"
                                    icon="plus"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setGradients([
                                            ...gradients,
                                            {
                                                type: "linear",
                                                angle: 135,
                                                stops: [
                                                    {
                                                        color:
                                                            colors[0]?.code ||
                                                            "#000000",
                                                        offset: "0%",
                                                    },
                                                    {
                                                        color:
                                                            colors[1]?.code ||
                                                            "#ffffff",
                                                        offset: "100%",
                                                    },
                                                ],
                                            },
                                        ]);
                                    }}
                                />
                            </ButtonGroup>
                        </div>
                        <p>
                            You can add gradients to your color palette. If you
                            dont want to use gradients, you can skip this step.
                        </p>
                        <GradientsEditor
                            gradients={gradients}
                            setGradients={setGradients}
                        />
                    </fieldset>
                </>
                {/* )}
                {step === 3 && ( */}
                <>
                    <fieldset>
                        <legend>Select Fonts</legend>
                        <SelectFonts
                            headingFont={headingFont}
                            setHeadingFont={setHeadingFont}
                            headingFontFile={headingFontFile}
                            setHeadingFontFile={setHeadingFontFile}
                            subHeadingFont={subHeadingFont}
                            setSubHeadingFont={setSubHeadingFont}
                            subHeadingFontFile={subHeadingFontFile}
                            setSubHeadingFontFile={setSubHeadingFontFile}
                            bodyFont={bodyFont}
                            setBodyFont={setBodyFont}
                            bodyFontFile={bodyFontFile}
                            setBodyFontFile={setBodyFontFile}
                        />
                    </fieldset>
                    <fieldset>
                        <legend>Type Scale</legend>
                        <p>
                            Select a type scale to make sure your tyopgraphy
                            scales well and always looks consistent.
                        </p>
                        <TypeScale
                            typescale={typescale}
                            setTypescale={setTypescale}
                            headingFont={headingFont}
                            subHeadingFont={subHeadingFont}
                            bodyFont={bodyFont}
                        />
                    </fieldset>
                </>
                {/* )} */}
            </CForm>
            <Progress value={pageState.uploadProgress} />
            <ButtonGroup>
                {step > 0 && (
                    <Button
                        name="Back"
                        icon="arrow-l2"
                        onClick={(e) => {
                            e.preventDefault();
                            setStep((n) => n - 1);
                        }}
                    />
                )}
                {step < 2 && (
                    <Button
                        name="Next"
                        icon="arrow-r2"
                        className="reverse"
                        onClick={(e) => {
                            e.preventDefault();
                            setStep((n) => n + 1);
                        }}
                    />
                )}
            </ButtonGroup>
        </Page>
    );
};

export default CreateProject;
