import React from "react";
import Page from "../../components/Templates/Page";
import { useProjects } from "../../context/projectsContext";
import Button from "../../components/Buttons/Button";
import Card from "../../components/Card/Card";
import { useNavigate } from "react-router-dom";
import SVG from "../../components/Elements/SVG";
import Loading from "../../components/Elements/Loading";
import { deleteProject } from "../../api/projectApi";
import { useQueryClient } from "@tanstack/react-query";
import "./Home.css";
import Content from "../../components/Templates/Content";
import Header from "../../components/Header/Header";
import Heading from "../../components/Templates/Heading";
import { comingSoon } from "../../util/helper";
import ShareProject from "../../Popups/ShareProject";

function Home() {
    const { projects, projectsStatus, sharedProjects, sharedProjectsStatus } =
        useProjects();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    if (
        (!projects || projectsStatus === "loading") &&
        (!sharedProjects || sharedProjectsStatus === "loading")
    ) {
        return <Loading />;
    }

    if (projectsStatus === "error") {
        return <div>Error</div>;
    }

    return (
        <Page title="Home">
            {!!projects?.length && (
                <Content className="col-3 projectCards">
                    {projects.map((project, i) => {
                        return (
                            <Card
                                className="projectCard"
                                key={project._id}
                                name={project.name}
                                hero={
                                    <img
                                        src={project.logo}
                                        alt={project.name}
                                    />
                                }
                                onClick={() =>
                                    navigate(`/project/${project.slug}/`)
                                }
                                buttonGroup={
                                    <>
                                        <Button
                                            icon="edit"
                                            className="ttc"
                                            tooltip="Edit Project"
                                            onClick={comingSoon}
                                        />
                                        <Button
                                            icon="share"
                                            className="ttc"
                                            tooltip="Share Brand Guide"
                                            onClick={comingSoon}
                                        />

                                        <ShareProject
                                            project={project}
                                            icon="adduser"
                                        />
                                        <Button
                                            icon="delete"
                                            tooltip="Delete Project"
                                            onClick={() => {
                                                deleteProject(project._id).then(
                                                    () => {
                                                        queryClient.invalidateQueries(
                                                            ["projects"]
                                                        );
                                                    }
                                                );
                                            }}
                                        />
                                    </>
                                }
                            />
                        );
                    })}
                </Content>
            )}

            {!!sharedProjects?.length && (
                <>
                    {!!projects?.length && (
                        <Heading>
                            <h4 className="inline">Shared Projects</h4>
                        </Heading>
                    )}
                    <Content className="col-3 projectCards">
                        {sharedProjects.map((project, i) => {
                            return (
                                <Card
                                    className="projectCard"
                                    key={project._id}
                                    name={project.name}
                                    hero={
                                        <img
                                            src={project.logo}
                                            alt={project.name}
                                        />
                                    }
                                    onClick={() =>
                                        navigate(`/project/${project.slug}/`)
                                    }
                                    buttonGroup={
                                        <>
                                            <Button
                                                icon="share"
                                                className="ttc"
                                                tooltip="Share Project"
                                                onClick={comingSoon}
                                            />
                                        </>
                                    }
                                />
                            );
                        })}
                    </Content>
                </>
            )}
        </Page>
    );
}

export default Home;
