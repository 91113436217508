import React, {
    FC,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useProject } from "./projectContext";
import {
    createFolder,
    deleteFile,
    getFile,
    getFolder,
    uploadFile,
} from "../api/folderApi";
import createImageThumbnail from "../util/createImageThumbnail";
import createVideoThumbnail from "../util/createVideoThumbnail";
import Button from "../components/Buttons/Button";
import Progress from "../components/Elements/Progress";
import { Folder, FolderRef } from "../types/Folder";

const FolderContext = createContext<{
    folder: Folder | undefined;
    parentFolder: FolderRef | null;
    subFolders: FolderRef[] | null;
    loadingFolder: boolean;
    isValidFolder: boolean;
    addToUploadQueue: (
        e: React.ChangeEvent<HTMLInputElement>,
        folderID: string,
        tags?: string[]
    ) => void;
    handleDeleteFile: (fileID: string[]) => Promise<void>;
    UploadQueue: () => JSX.Element;
}>({
    folder: undefined,
    parentFolder: null,
    subFolders: null,
    loadingFolder: false,
    isValidFolder: false,
    addToUploadQueue: () => {},
    handleDeleteFile: async () => {},
    UploadQueue: () => <></>,
});

type FolderProviderProps = {
    children: React.ReactNode;
};

const FolderProvider: FC<FolderProviderProps> = ({ children }) => {
    const { project, logos, isValidSlug } = useProject();
    const queryClient = useQueryClient();
    const { slug, folderSlug, subFolderSlug } = useParams();
    const [isValidFolder, setIsValidFolder] = useState<boolean>(false);
    const [folderID, setFolderID] = useState<string>("");
    const [parentFolder, setParentFolder] = useState<FolderRef | null>(null);
    const [subFolders, setSubFolders] = useState<FolderRef[] | null>(null);
    const [uploadQueue, setUploadQueue] = useState<
        {
            file: File;
            folderID: string;
            tags: string[];
        }[]
    >([]);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [uploadIndex, setUploadIndex] = useState<number>(0);
    const [uploadFailed, setUploadFailed] = useState<number[]>([]);

    useEffect(() => {
        setFolderID("");
        setParentFolder(null);
        setSubFolders(null);
        if (project) {
            const temp = project.folder.find((folder) => {
                if (subFolderSlug) {
                    return folder.slug === subFolderSlug;
                } else {
                    return folder.slug === folderSlug;
                }
            });
            if (temp) {
                setFolderID(temp.folderID);
                setIsValidFolder(true);
                setParentFolder(() => {
                    return (
                        project.folder.find(
                            (f) => f.folderID === temp.parentFolderID
                        ) || null
                    );
                });
                setSubFolders(
                    project.folder.filter(
                        (f) => f.parentFolderID === temp.folderID
                    )
                );
            } else {
                setIsValidFolder(false);
            }
        }
    }, [project, slug, folderSlug, subFolderSlug]);

    useEffect(() => {
        setUploadQueue([]);
        setIsUploading(false);
        setUploadProgress(0);
        setUploadIndex(0);
        setUploadFailed([]);
    }, [slug]);

    const { data: folder, isLoading: loadingFolder } = useQuery<Folder>({
        queryKey: ["folder", folderID],
        queryFn: () => getFolder(folderID),
        enabled: isValidSlug && folderID !== "",
    });

    const addToUploadQueue = (
        e: React.ChangeEvent<HTMLInputElement>,
        folderID: string,
        tags: string[] = []
    ) => {
        e.preventDefault();
        e.stopPropagation();
        const files = e.target.files as FileList;
        if (files.length === 0) return;
        const newUploadQueue = [...uploadQueue];
        Array.from(files).forEach((file) => {
            let exists = false;
            newUploadQueue.forEach((upload) => {
                if (upload.file.name === file.name) {
                    exists = true;
                }
            });
            if (exists) return;
            newUploadQueue.push({ file, folderID, tags });
        });
        setUploadQueue(newUploadQueue);
        e.target.value = "";
    };

    useEffect(() => {
        if (!isUploading && uploadQueue.length > 0) {
            setIsUploading(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadQueue]);

    useEffect(() => {
        const handleUploadFile = async () => {
            if (!project || uploadQueue.length === 0 || !folder) return;
            const data = {
                companyID: project.company,
                folderID: uploadQueue[uploadIndex].folderID,
                file: uploadQueue[uploadIndex].file,
                uploaded: Date.now().toString(),
                lastModified:
                    uploadQueue[uploadIndex].file.lastModified.toString(),
                thumbnail: null,
                tags: uploadQueue[uploadIndex].tags,
            };

            if (data.file.type.includes("image")) {
                data.thumbnail = await createImageThumbnail(
                    URL.createObjectURL(data.file)
                );
            } else if (data.file.type.includes("video")) {
                data.thumbnail = await createVideoThumbnail(
                    URL.createObjectURL(data.file)
                );
            }

            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent: ProgressEvent) => {
                    const progress = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setUploadProgress(progress);
                },
            };

            uploadFile({
                data,
                config,
            })
                .then((res) => {
                    const json = res.data;
                    if (json.success) {
                        if (folder.files.length === 0) {
                            queryClient.invalidateQueries(["folder", folderID]);
                        } else {
                            getFile({
                                folderID: folder._id,
                                fileID: json.data.fileID,
                            }).then((newFile) => {
                                queryClient.setQueryData<Folder>(
                                    ["folder", folderID],
                                    (oldData) => {
                                        if (!oldData) return;
                                        const updatedData = { ...oldData };
                                        updatedData.files = [
                                            ...oldData.files,
                                            newFile,
                                        ];
                                        return updatedData;
                                    }
                                );
                            });
                        }

                        if (uploadIndex === uploadQueue.length - 1) {
                            setUploadIndex((prevState) => prevState + 1);
                            setUploadProgress(0);
                            setIsUploading(false);
                        } else {
                            setUploadIndex((prevState) => prevState + 1);
                            setUploadProgress(0);
                        }
                    }
                })
                .catch((err) => {
                    setUploadIndex((prevState) => prevState + 1);
                    setUploadFailed((prevState) => [...prevState, uploadIndex]);
                    setUploadProgress(0);
                });
        };

        if (isUploading && uploadIndex < uploadQueue.length) {
            handleUploadFile();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUploading, uploadIndex]);

    const handleDeleteFile = async (fileID: string[]) => {
        if (!project || !folder) return;
        const res = await deleteFile({
            fileID,
            companyID: project.company,
            folderID,
        });

        if (!res) return;

        if (folder.files.length === 0) {
            queryClient.invalidateQueries(["folder", folderID]);
        } else {
            queryClient.setQueryData<Folder>(
                ["folder", folderID],
                (oldData) => {
                    if (!oldData) return;
                    const updatedData = { ...oldData };
                    updatedData.files = updatedData.files.filter(
                        (file) => !fileID.includes(file._id)
                    );
                    return updatedData;
                }
            );
        }
    };

    const UploadQueue = () => {
        if (uploadQueue.length === 0) return <></>;
        return (
            <ul className="uploadqueue floatingContainer">
                <Button
                    icon="close"
                    className="floating"
                    tooltip={
                        isUploading ? "Cancel Upload" : "Clear Upload Queue"
                    }
                    onClick={() => {
                        isUploading && setIsUploading(false);
                        setUploadProgress(0);
                        setUploadIndex(0);
                        setUploadFailed([]);
                        setUploadQueue([]);
                    }}
                />
                {uploadQueue.map((file, index) => (
                    <li key={file.file.name}>
                        <p>{file.file.name}</p>
                        {index > uploadIndex && (
                            <Button
                                icon="close"
                                tooltip="Remove File"
                                onClick={() => {
                                    uploadQueue.splice(index, 1);
                                    setUploadQueue([...uploadQueue]);
                                }}
                            />
                        )}
                        <Progress
                            className={
                                uploadFailed.includes(index) ? "error" : ""
                            }
                            value={
                                !isUploading
                                    ? 100
                                    : index === uploadIndex
                                    ? uploadProgress
                                    : index < uploadIndex
                                    ? 100
                                    : 0
                            }
                        />
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <FolderContext.Provider
            value={{
                folder,
                parentFolder,
                subFolders,
                loadingFolder,
                isValidFolder,
                addToUploadQueue,
                handleDeleteFile,
                UploadQueue,
            }}
        >
            {children}
        </FolderContext.Provider>
    );
};

export default FolderProvider;

export function useFolder() {
    const context = useContext(FolderContext);
    if (context === undefined) {
        throw new Error("useFolder in not within FolderProvider");
    }
    return context;
}
