import React, {
    FC,
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { useQuery } from "@tanstack/react-query";
import { getCompanyStyle } from "../api/companyApi";

type Brand = {
    name: string;
    style: {
        primaryColor: string;
        secondaryColor: string;
        logo: string;
        logoInverse: string;
        favicon: string;
        headingFont: string;
        bodyFont: string;
        metaTitle: string;
        metaDescription: string;
    };
};

const defaultBrand = {
    name: "Brand Set",
    style: {
        primaryColor: "#5eadfb",
        secondaryColor: "#988afc",
        logo: "/img/logo.svg",
        logoInverse: "",
        favicon: "",
        headingFont: "Poppins",
        bodyFont: "Poppins",
        metaTitle: "Brand Set",
        metaDescription: "Brand Set App",
    },
};

const StyleContext = createContext<{
    brand: Brand;
    isBrandLoading: boolean;
    isBrand: boolean;
}>({
    brand: defaultBrand,
    isBrandLoading: false,
    isBrand: false,
});

type StyleProviderProps = {
    children: React.ReactNode;
};

const domain = new URL(window.location.href).hostname;
const subdomain = domain.split(".")[0];

const StyleProvider: FC<StyleProviderProps> = ({ children }) => {
    const [customDomain, setCustomDomain] = useState<string>();
    const isDomain = useRef(true);

    useEffect(() => {
        if (domain !== "localhost" && subdomain !== "app") {
            if (domain.includes("brandset.xyz")) {
                isDomain.current = false;
                setCustomDomain(domain.split(".")[0]);
            } else {
                isDomain.current = true;
                setCustomDomain(domain);
            }
        }
    }, []);

    const {
        data: brand,
        isLoading: isBrandLoading,
        isSuccess: isBrand,
    } = useQuery({
        queryKey: ["company-style", customDomain],
        queryFn: () =>
            getCompanyStyle({
                isDomain: isDomain.current,
                customDomain: customDomain as string,
            }),
        enabled: !!customDomain,
    });

    return (
        <StyleContext.Provider
            value={{
                brand: isBrand ? brand : defaultBrand,
                isBrandLoading,
                isBrand,
            }}
        >
            {children}
        </StyleContext.Provider>
    );
};

export default StyleProvider;

export function useCompanyStyle() {
    const context = useContext(StyleContext);

    if (context === undefined) {
        throw new Error("useCompanyStyle in not within StyleProvider");
    }
    return context;
}
