import React, { FC } from "react";
import "./SelectDropdown.css";

type SelectDropdownProps = {
    name: string;
    options: Array<Array<string>>;
    value: string;
    onChange: (value: string) => void;
    className?: string;
    label?: string;
};

const SelectDropdown: FC<SelectDropdownProps> = ({
    name,
    options,
    value,
    onChange,
    className = "",
    label,
}) => {
    return (
        <div className={`inputWrapper selectDropdown ${className}`}>
            <select
                name={name}
                id={name}
                value={value}
                onChange={(e) => onChange(e.target.value)}
            >
                {options.map((option, i) => {
                    return (
                        <option key={`option-${i}`} value={option[1]}>
                            {option[0]}
                        </option>
                    );
                })}
            </select>
            {label && <label htmlFor={name}>{label}</label>}
        </div>
    );
};

export default SelectDropdown;
