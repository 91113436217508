import React, { useEffect, useState } from "react";
import { calculateTypeStyle } from "../../util/Font/calculateTypeStyle";
import TypeScalePreview from "./TypeScalePreview";
import Input from "../Inputs/Input";
import SelectDropdown from "../Inputs/SelectDropdown";
import { Font, TypeScaleType } from "../../types/Font";

export const typeScales = [
    ["Minor Second - 1.067", "1.067"],
    ["Major Second - 1.125", "1.125"],
    ["Minor Third - 1.2", "1.2"],
    ["Major Third - 1.25", "1.25"],
    ["Perfect Fourth - 1.333", "1.333"],
    ["Augmented Fourth - 1.414", "1.414"],
    ["Perfect Fifth - 1.5", "1.5"],
    ["Golden Ratio - 1.618", "1.618"],
];

type TypeScaleProps = {
    typescale: TypeScaleType;
    setTypescale: (typescale: TypeScaleType) => void;
    headingFont: Font;
    subHeadingFont: Font;
    bodyFont: Font;
};

const TypeScale: React.FC<TypeScaleProps> = ({
    typescale,
    setTypescale,
    headingFont,
    subHeadingFont,
    bodyFont,
}) => {
    const [typeStyle, setTypeStyle] = useState({});

    useEffect(() => {
        setTypeStyle(calculateTypeStyle(typescale));
    }, [typescale]);

    return (
        <div
            className="typeScale"
            style={
                {
                    "--heading_font": `${headingFont.family}`,
                    "--subheading_font": `${subHeadingFont.family}`,
                    "--body_font": `${bodyFont.family}`,
                    "--heading_font_weight": `${headingFont.weight}`,
                    "--subheading_font_weight": `${subHeadingFont.weight}`,
                    "--body_font_weight": `${bodyFont.weight}`,
                    "--heading_font_style": `${
                        headingFont.isItalic ? "italic" : "normal"
                    }`,
                    "--subheading_font_style": `${
                        subHeadingFont.isItalic ? "italic" : "normal"
                    }`,
                    "--body_font_style": `${
                        bodyFont.isItalic ? "italic" : "normal"
                    }`,
                    display: "flex",
                    flexDirection: "column",
                    gap: "var(--gap_min)",
                    padding: "var(--gap_min) 0",
                } as React.CSSProperties
            }
        >
            <Input
                value={typescale.fontSize}
                handleChange={(value) => {
                    typescale.fontSize = value;
                    setTypescale({ ...typescale });
                }}
                label="Font Size"
                type="number"
            />

            <SelectDropdown
                name="scaleRatio"
                value={typescale.scaleRatio}
                onChange={(value) => {
                    typescale.scaleRatio = value;
                    setTypescale({ ...typescale });
                }}
                label="Scale Ratio"
                options={typeScales}
            />

            <Input
                value={typescale.previewText}
                handleChange={(value) => {
                    typescale.previewText = value;
                    setTypescale({ ...typescale });
                }}
                label="Preview Text"
            />
            <TypeScalePreview typeStyle={typeStyle} typescale={typescale} />
        </div>
    );
};

export default TypeScale;
