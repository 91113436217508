import React, { FC, useState } from "react";
import "./DropZone.css";
// import { getBase64 } from "../../util/helper";

interface ImageDropZoneProps {
    currentUrl: string | null;
    text?: string;
    isRequired?: boolean;
    img: File | null;
    setImg: React.Dispatch<React.SetStateAction<File | null>>;
    name: string;
}

const ImageDropZone: FC<ImageDropZoneProps> = ({
    currentUrl,
    text,
    isRequired,
    img,
    setImg,
    name,
}) => {
    const [zoneActive, setZoneActive] = useState(false);
    const handleDrag = function (e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setZoneActive(true);
        } else if (e.type === "dragleave") {
            setZoneActive(false);
        }
    };
    const handleDrop = function (e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        setZoneActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setImg(e.dataTransfer.files[0]);
        }
    };
    return (
        <div
            className={`imgDropZone dropZone${
                zoneActive ? " active" : ""
            } ${name}`}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
        >
            <input
                required={isRequired}
                id={"img-" + name}
                type="file"
                accept="image/png"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files && e.target.files[0]) {
                        setImg(e.target.files[0]);
                    }
                }}
            ></input>
            <label htmlFor={"img-" + name}>
                {img ? (
                    <img className="hero" src={URL.createObjectURL(img)} />
                ) : currentUrl ? (
                    <img className="hero" src={currentUrl} />
                ) : (
                    <p>{text ? text : "Drop file here or click to upload"}</p>
                )}
            </label>
        </div>
    );
};

export default ImageDropZone;
