import React, { FC, useState, useRef } from "react";
import Button from "../components/Buttons/Button";
import Popup from "../components/Popup/Popup";
import SimpleInput from "../components/Inputs/SimpleInput";
import CForm from "../components/Inputs/CForm";
import { createFolder } from "../api/folderApi";
import { useProjects } from "../context/projectsContext";
import { useUser } from "../context/userContext";

type AddFolderProps = {
    projectID: string;
    projectSlug: string;
    parentID?: string;
    className?: string;
};

const AddFolder: FC<AddFolderProps> = ({
    projectID,
    projectSlug,
    parentID,
    className,
}) => {
    const { fetchProjectBySlug } = useProjects();
    const { user, lvl } = useUser();
    const folderName = useRef<HTMLInputElement>(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    async function handleCreateFolder() {
        if (!folderName.current) return;
        const data = {
            name: folderName.current.value,
            projectID,
            parentID: parentID ? parentID : undefined,
        };
        await createFolder(data).then((res) => {
            if (res) {
                fetchProjectBySlug(projectSlug);
                setIsPopupOpen(false);
            }
        });
    }

    if (user?.role === lvl.client) return null;

    return (
        <>
            <Button
                icon="plus"
                onClick={() => setIsPopupOpen(true)}
                name="Add Folder"
                className={className}
            />
            <Popup
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
                title="Add Folder"
                className="small"
            >
                <CForm onSubmit={handleCreateFolder} button="Add Folder">
                    <SimpleInput
                        required
                        label="Folder Name"
                        inputRef={folderName}
                    />
                </CForm>
            </Popup>
        </>
    );
};

export default AddFolder;
