import React, { FC } from "react";
import "./Content.css";

interface ContentProps {
    children?: React.ReactNode;
    className?: string;
}

const Content: FC<ContentProps> = ({ children, className = "" }) => {
    return <div className={`content ${className}`}>{children}</div>;
};

export default Content;
