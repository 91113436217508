import React, { FC, useState, useEffect, useRef } from "react";
import "./ImageEditor.css";
import getImageData from "../../util/Image/getImageData";
import { encodeSVG, svgToDataUri } from "../../util/SVG/svg";
import Button from "../Buttons/Button";
import SimpleInput from "../Inputs/SimpleInput";
import ButtonGroup from "../Buttons/ButtonGroup";
import Input from "../Inputs/Input";

const options = [
    {
        Name: "Brightness",
        css: "brightness",
        default: 100,
        min: 0,
        max: 200,
        step: 1,
    },
    {
        Name: "Contrast",
        css: "contrast",
        default: 100,
        min: 0,
        max: 200,
        step: 1,
    },
    {
        Name: "Grayscale",
        css: "grayscale",
        default: 0,
        min: 0,
        max: 100,
        step: 1,
    },
    {
        Name: "Hue Rotate",
        css: "hue-rotate",
        default: 0,
        min: 0,
        max: 360,
        step: 1,
    },
    {
        Name: "Invert",
        css: "invert",
        default: 0,
        min: 0,
        max: 100,
        step: 1,
    },
    {
        Name: "Saturate",
        css: "saturate",
        default: 100,
        min: 0,
        max: 200,
        step: 1,
    },
    {
        Name: "Sepia",
        css: "sepia",
        default: 0,
        min: 0,
        max: 100,
        step: 1,
    },
    {
        Name: "Blur",
        css: "blur",
        default: 0,
        min: 0,
        max: 10,
        step: 0.1,
    },
];

interface ImageEditorProps {
    src: string;
    onClose: () => void;
}

interface Filter {
    name: string;
    value: number;
}

interface Overlay {
    src: string;
    width: number;
    height: number;
    position: string;
}

const ImageEditor: FC<ImageEditorProps> = ({ src }) => {
    const [imageData, setImageData] = useState<{
        type: string;
        svgString?: string;
        blob?: Blob;
    } | null>(null);

    const [svgData, setSvgData] = useState<{
        new?: string;
        svgUri: string;
    } | null>(null);

    const [state, setState] = useState<{
        filters?: Array<Filter>;
        overlay?: Overlay;
        colors?: Array<string>;
        fill?: string;
        background?: string;
        width?: number;
        height?: number;
    }>({ filters: [], colors: [], fill: "", background: "" });

    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        getImageData(src).then((data) => setImageData(data));
    }, [src]);

    useEffect(() => {
        if (!imageData) return;
        if (imageData?.type === "svg") {
            setSvgData({
                new: imageData.svgString,
                svgUri: encodeSVG(imageData.svgString),
            });
        }
        const canvas = canvasRef.current;
        const ctx = canvas?.getContext("2d");
        const img = new Image();
        img.onload = () => {
            ctx?.drawImage(img, 0, 0);
        };
    }, [imageData]);

    function renderCanvas(
        src: string,
        height: number = 300,
        width: number = 300,
        fileType: string = "image/png",
        filter: string,
        overlay: Overlay,
        cover: boolean = true
    ) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement("canvas");
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext("2d");
                if (!ctx) return;

                const scale = Math.max(width / img.width, height / img.height);
                const xOffset = (width - img.width * scale) / 2;
                const yOffset = (height - img.height * scale) / 2;
                if (!!filter) {
                    ctx.filter = filter;
                }
                ctx.drawImage(
                    img,
                    xOffset,
                    yOffset,
                    img.width * scale,
                    img.height * scale
                );
                ctx.filter = "none";

                if (!!overlay) {
                    const overlayImg = new Image();
                    overlayImg.onload = () => {
                        const overlayScale = Math.max(
                            overlay.width / overlayImg.width,
                            overlay.height / overlayImg.height
                        );
                        const overlayXOffset =
                            (width - overlayImg.width * overlayScale) / 2;
                        const overlayYOffset =
                            (height - overlayImg.height * overlayScale) / 2;
                        ctx.drawImage(
                            overlayImg,
                            overlayXOffset,
                            overlayYOffset,
                            overlayImg.width * overlayScale,
                            overlayImg.height * overlayScale
                        );
                    };
                    overlayImg.src = overlay.src;
                }
                canvas.toBlob((blob) => resolve(blob), fileType, 1);
            };
            img.onerror = (err) => reject(err);
            img.src = src;
        });
    }

    // function updateState(e){
    //     const name = e.target.name;
    //     const value = e.target.value;
    //     if(name)
    // }

    if (!imageData) return <div>Loading...</div>;

    return (
        <div className="imageEditor">
            <div className={`preview ${imageData.type}`}>
                {imageData.type === "svg" ? (
                    <img src={svgData?.svgUri} />
                ) : (
                    <img src={src} />
                )}
            </div>
            <div className="options">
                {imageData.type === "svg" && <p>SVG Options</p>}
                <p>Filters</p>
                <div className="filters">
                    {options.map((option) => (
                        <div className="filter">
                            <p>{option.Name}</p>
                            <input
                                type="range"
                                min={option.min}
                                max={option.max}
                                step={option.step}
                                defaultValue={option.default}
                            />
                        </div>
                    ))}
                </div>
                <p>Overlay</p>
                <div className="overlay">
                    <input type="file" />
                </div>
                <p>Aspect Ratio</p>
                <div className="aspectRatio">
                    <input type="radio" name="aspectRatio" value="1:1" />

                    <input type="radio" name="aspectRatio" value="4:3" />
                    <input type="radio" name="aspectRatio" value="16:9" />
                    <input type="radio" name="aspectRatio" value="custom" />
                </div>
                <p>Dimension in PX</p>
                <div className="dimension">
                    <Input
                        type="number"
                        label="Width"
                        value={state.width}
                        handleChange={(width) => setState({ ...state, width })}
                    />
                    <Input
                        type="number"
                        label="Height"
                        value={state.height}
                        handleChange={(height) =>
                            setState({ ...state, height })
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default ImageEditor;
