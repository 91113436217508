import React, { useRef } from "react";
import Setting from "../../components/Templates/Setting";
import Content from "../../components/Templates/Content";
import { useCompany } from "../../context/companyContext";
import { useNotification } from "../../context/notificationContext";
import { useQueryClient } from "@tanstack/react-query";
import { updateDomain } from "../../api/companyApi";
import Loading from "../../components/Elements/Loading";
import CForm from "../../components/Inputs/CForm";
import SimpleInput from "../../components/Inputs/SimpleInput";
import { copy } from "../../util/helper";
import Button from "../../components/Buttons/Button";

function CustomDomain() {
    const { company, isCompany } = useCompany();
    const { sendNotification } = useNotification();
    const queryClient = useQueryClient();

    const customDomain = useRef<HTMLInputElement>(null);

    async function handleDomainUpdate() {
        if (!company || !customDomain.current) return;
        const domain = customDomain.current.value;
        if (domain === "") {
            return;
        }
        // check if valid domain with regex
        if (!domain.match(/^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}$/i)) {
            sendNotification("Invalid domain", "error");
            return;
        }

        // check domain cname record on https://networkcalc.com/api/dns/lookup/example.com
        const cname = await fetch(
            `https://networkcalc.com/api/dns/lookup/${domain}`
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.records !== null) {
                    return data.records.A[0]?.address ?? "";
                } else {
                    return "";
                }
            });

        if (cname !== "204.10.78.169") {
            sendNotification("invalid cname", "error");
            return;
        }

        await updateDomain({
            companyID: company._id,
            domain: domain,
        }).then((res) => {
            if (res) {
                queryClient.invalidateQueries(["company"]);
            }
        });
    }

    if (!isCompany || !company) return <Loading />;

    return (
        <Setting title="Custom Domain">
            <Content
                className={`featureForm ${
                    company.plan.limits.customDomain ? "disabled" : ""
                }`}
            >
                <h4>Custom Domain</h4>
                <p>
                    You can host your Brandset on your own (sub)domain. To do
                    this you need to add your domain to the field below (Eg.{" "}
                    <b>mydomain.com</b> or <b>subdomain.mydomain.com</b> ) and
                    and create an <b>A record</b> in your DNS settings pointing
                    to this IP address:{" "}
                    <Button
                        onClick={() => copy("204.10.78.169", sendNotification)}
                        name="204.10.78.169"
                        className="inline"
                        icon="clipboard"
                    />
                </p>
                <CForm onSubmit={handleDomainUpdate} button="Update Domain">
                    <SimpleInput
                        label="Custom Domain"
                        inputRef={customDomain}
                        defaultValue={company.customDomain}
                    />
                </CForm>
            </Content>
        </Setting>
    );
}

export default CustomDomain;
