import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { loginUser } from "../../api/userApi";
import SimpleInput from "../../components/Inputs/SimpleInput";
import { useUser } from "../../context/userContext";
import Portal from "../../components/Templates/Portal";
import CForm from "../../components/Inputs/CForm";
import Content from "../../components/Templates/Content";
import { useNotification } from "../../context/notificationContext";
import { isEmailValid, isPasswordValid } from "../../util/validator";
import SimpleCheckBox from "../../components/Inputs/SimpleCheckBox";

export default function Login() {
    const userEmail = useRef<HTMLInputElement>(null);
    const userPassword = useRef<HTMLInputElement>(null);
    const userRemember = useRef<HTMLInputElement>(null);

    const { setToken } = useUser();
    const { sendNotification } = useNotification();

    async function handleLogin() {
        const data = {
            email: userEmail.current?.value ?? "",
            password: userPassword.current?.value ?? "",
            remember: userRemember.current?.checked ?? false,
        };

        if (!isEmailValid(data.email) || !isPasswordValid(data.password)) {
            sendNotification(
                "Please enter a valid email and password",
                "error"
            );
            return;
        }

        await loginUser(data).then((res) => {
            if (!res) return;
            if (res.data.token) {
                setToken(res.data.token);
            }
        });
    }

    return (
        <Portal title="Login to Brand Set">
            <Content>
                <h3>Welcome back 👋</h3>
            </Content>
            <CForm onSubmit={handleLogin} button="Log In">
                <SimpleInput label="Email" type="email" inputRef={userEmail} />
                <SimpleInput
                    label="Password"
                    type="password"
                    inputRef={userPassword}
                />
                <div className="col col-2 captionText">
                    <SimpleCheckBox
                        name="remember"
                        label="Remember me"
                        value="remember"
                        inputRef={userRemember}
                    />
                    <Link style={{ textAlign: "right" }} to="/forgot-password">
                        Forgot your password?
                    </Link>
                </div>
            </CForm>
            <Content>
                <p>
                    New to Brand Set <Link to="/signup">Create an account</Link>
                </p>
            </Content>
        </Portal>
    );
}
