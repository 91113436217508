import axios from "axios";
import { clear } from "console";
import React, {
    FC,
    useEffect,
    useState,
    useCallback,
    createContext,
    useContext,
} from "react";

const NotificationContext = createContext<{
    notifications: Notification[];
    sendNotification: (message: string, type?: string) => number;
    removeNotification: (id: number) => void;
    updateNotification: (id: number, type: string, message?: string) => void;
}>({
    notifications: [],
    sendNotification: () => 0,
    removeNotification: () => {},
    updateNotification: () => {},
});

interface NotificationProviderProps {
    children: React.ReactNode;
}

interface Notification {
    id: number;
    message: string;
    type: string;
    timeoutId?: NodeJS.Timeout;
}

const MAX_NOTIFICATIONS = 6;
const NOTIFICATION_DURATION = 5000;
let nextID = 0;

const NotificationProvider: FC<NotificationProviderProps> = ({ children }) => {
    const [notifications, setNotifications] = useState<Notification[]>([]);

    const sendNotification = useCallback(
        (message: string, type = "success") => {
            const id = nextID++;
            const newNotification = {
                message,
                type,
                id,
                timeoutId:
                    type !== "loading"
                        ? setTimeout(() => {
                              removeNotification(id);
                          }, NOTIFICATION_DURATION)
                        : undefined,
            };

            if (notifications.length < MAX_NOTIFICATIONS) {
                setNotifications((prev) => [...prev, newNotification]);
            } else {
                setNotifications((prev) => {
                    const temp = [...prev];
                    const index = temp.findIndex((n) => n.type !== "loading");
                    if (index !== -1) {
                        clearTimeout(temp[index].timeoutId!);
                        temp.splice(index, 1);
                    }
                    temp.push(newNotification);
                    return temp;
                });
            }
            return id;
        },
        [notifications]
    );

    function removeNotification(id: number) {
        setNotifications((prev) => {
            const temp = [...prev];
            const index = temp.findIndex((n) => n.id === id);
            if (index !== -1) {
                clearTimeout(temp[index].timeoutId!);
                temp.splice(index, 1);
            }
            return temp;
        });
    }

    function updateNotification(id: number, type: string, message?: string) {
        setNotifications((prev) => {
            const temp = [...prev];
            const index = temp.findIndex((n) => n.id === id);
            if (index === -1) return temp;
            temp[index].message = message || temp[index].message;
            if (temp[index].type === "loading" && type !== "loading") {
                temp[index].timeoutId = setTimeout(() => {
                    removeNotification(id);
                }, NOTIFICATION_DURATION);
            }
            temp[index].type = type || temp[index].type;
            return temp;
        });
    }

    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            (res) => {
                if (res.data.message) {
                    sendNotification(res.data.message, "success");
                }
                return res;
            },
            (err) => {
                if (err.response.data.message) {
                    sendNotification(err.response.data.message, "error");
                }
                return Promise.reject(err);
            }
        );

        return () => {
            axios.interceptors.response.eject(interceptor);
        };
    }, [sendNotification]);

    return (
        <NotificationContext.Provider
            value={{
                notifications,
                sendNotification,
                removeNotification,
                updateNotification,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationProvider;

export function useNotification() {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error(
            "useNotification must be used within a NotificationProvider"
        );
    }
    return context;
}
