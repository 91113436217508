import React, { FC } from "react";
import { Helmet } from "react-helmet";
import "./Setting.css";

type SettingProps = {
    title: string;
    children: React.ReactNode;
    classsName?: string;
};

const Setting: FC<SettingProps> = ({ title, children, classsName = "" }) => {
    return (
        <div className={`setting cardStyle ${classsName}`}>
            <Helmet>
                <title>{title} - Setting</title>
            </Helmet>

            {children}
        </div>
    );
};

export default Setting;
