import React, { FC } from "react";
import "./Heading.css";

interface HeadingProps {
    className?: string;
    children?: React.ReactNode;
}

const Heading: FC<HeadingProps> = ({ children, className = "" }) => {
    return <div className={`heading ${className}`}>{children}</div>;
};

export default Heading;
