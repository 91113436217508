import React, { FC, useState, useRef } from "react";
import NavLinkButton from "../Buttons/NavLinkButton";
import { FolderRef } from "../../types/Folder";
import Button from "../Buttons/Button";
import ContextMenu from "../ContextMenu/ContextMenu";
import useContextMenu from "../ContextMenu/useContextMenu";
import Popup from "../Popup/Popup";
import CForm from "../Inputs/CForm";
import SimpleInput from "../Inputs/SimpleInput";
import "./FolderLink.css";
import { useUser } from "../../context/userContext";

type FolderLinkProps = {
    folder: FolderRef;
    projectSlug: string;
    isOwner?: boolean;
};

const FolderLink: FC<FolderLinkProps> = ({
    folder,
    projectSlug,
    isOwner = false,
}) => {
    const [menu, openMenu] = useContextMenu();
    const [renamePopup, setRenamePopup] = useState(false);
    const folderName = useRef<HTMLInputElement>(null);

    async function handleRename() {
        const data = {
            name: folderName.current?.value,
            folderID: folder._id,
        };
    }

    return (
        <div
            className="folderLink"
            onContextMenu={(e) => {
                e.preventDefault();
                openMenu(e);
            }}
        >
            <NavLinkButton
                key={folder._id}
                link={`/project/${projectSlug}/${folder.slug}`}
                icon="file"
            >
                {folder.name}
            </NavLinkButton>
            <Button
                icon="menu-v"
                onClick={(e) => {
                    e.preventDefault();
                    openMenu(e, true);
                }}
            />
            <ContextMenu menu={menu}>
                <Button icon="download" name="Download" />
                {isOwner && (
                    <>
                        <hr />
                        <Button
                            icon="edit"
                            name="Rename"
                            onClick={() => setRenamePopup(true)}
                        />
                        <Button icon="delete" name="Delete" />
                    </>
                )}
            </ContextMenu>
            <Popup
                title="Rename"
                isOpen={renamePopup}
                onClose={() => setRenamePopup(false)}
            >
                <CForm onSubmit={handleRename} button="Rename">
                    <SimpleInput
                        type="text"
                        inputRef={folderName}
                        label="Folder Name"
                        defaultValue={folder.name}
                        required={true}
                    />
                </CForm>
            </Popup>
        </div>
    );
};

export default FolderLink;
