import React, { useRef } from "react";
import Loading from "../../components/Elements/Loading";
import SimpleInput from "../../components/Inputs/SimpleInput";
import { useCompany } from "../../context/companyContext";
import { updateCompany, updateDomain } from "../../api/companyApi";
import { useQueryClient } from "@tanstack/react-query";
import { kbToGb, kbToSize, copy } from "../../util/helper";
import Page from "../../components/Templates/Page";
import CForm from "../../components/Inputs/CForm";
import Content from "../../components/Templates/Content";
import Progress from "../../components/Elements/Progress";
import { useNotification } from "../../context/notificationContext";
import "./CompanyAdmin.css";
import Setting from "../../components/Templates/Setting";

function CompanyAdmin() {
    const { company, isCompany } = useCompany();
    const queryClient = useQueryClient();
    const companyName = useRef<HTMLInputElement>(null);

    async function handleNameUpdate() {
        if (!company || !companyName.current) return;

        await updateCompany({
            companyID: company._id,
            name: companyName.current.value,
        }).then((res) => {
            if (res) {
                queryClient.invalidateQueries(["company"]);
            }
        });
    }

    if (!isCompany || !company) return <Loading />;

    return (
        <Setting title="Organization">
            <CForm
                onSubmit={handleNameUpdate}
                button="Save Changes"
                className="compact"
            >
                <SimpleInput
                    label="Company Name"
                    inputRef={companyName}
                    defaultValue={company.name}
                />
                <p>
                    <b>Sub Domain</b> {company.subdomain}.brandset.io
                </p>
            </CForm>
        </Setting>
    );
}

export default CompanyAdmin;
