import axios from "axios";
import React, {
    FC,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { deleteUser, getUser, userLogout } from "../api/userApi";
import { useNavigate } from "react-router-dom";
import { CurrentUser } from "../types/User";

const UserContext = createContext<{
    user: CurrentUser | null | undefined;
    fetchingUser: boolean;
    isUser: boolean;
    setEnableQuery: (enable: boolean) => void;
    setToken: (token: string) => void;
    handleLogout: () => void;
    handleDelete: () => void;
    lvl: any;
}>({
    user: null,
    fetchingUser: true,
    isUser: false,
    setEnableQuery: (enable: boolean) => {},
    setToken: (token: string) => {},
    handleLogout: () => {},
    handleDelete: () => {},
    lvl: {},
});

type UserProviderProps = {
    children: React.ReactNode;
};

const UserProvider: FC<UserProviderProps> = ({ children }) => {
    const navigate = useNavigate();
    const [enableQuery, setEnableQuery] = useState(false);
    const [token, setToken] = useState<String | null>();
    const queryClient = useQueryClient();

    const {
        data: user,
        isSuccess: isUser,
        isFetching: fetchingUser,
        isError,
    } = useQuery({
        queryKey: ["user"],
        queryFn: getUser,
        enabled: enableQuery,
    });

    useEffect(() => {
        if (isUser) {
            console.log(`Welcome back ${user.firstName}`);
        }
    }, [isUser, user]);

    useEffect(() => {
        if (isError) {
            localStorage.removeItem("brandset_token");
            sessionStorage.removeItem("brandset_token");
            setToken(null);
            navigate("/");
        }
    }, [isError]);

    useEffect(() => {
        getToken();
    }, []);

    useEffect(() => {
        if (token) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }
        setEnableQuery(!!token);
    }, [token]);

    function getToken() {
        let token = localStorage.getItem("brandset_token");
        if (!token) token = sessionStorage.getItem("brandset_token");
        token = decodeURIComponent(token || "");
        if (token) {
            if (token.length < 10) {
                token = null;
                localStorage.removeItem("brandset_token");
                sessionStorage.removeItem("brandset_token");
            }
            setToken(token);
        }
    }

    function handleLogout() {
        setEnableQuery(false);
        queryClient.clear();
        userLogout();
        navigate("/");
    }

    function handleDelete() {
        setEnableQuery(false);
        queryClient.clear();
        deleteUser();
        navigate("/");
    }

    const lvl = {
        admin: 0,
        supportL1: 1,
        supportL2: 2,
        userAdmin: 3,
        userMember: 4,
        client: 5,
    };

    return (
        <UserContext.Provider
            value={{
                user,
                fetchingUser,
                isUser,
                setEnableQuery,
                setToken,
                handleLogout,
                handleDelete,
                lvl,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;

export function useUser() {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error("useUser in not within UserProvider");
    }
    return context;
}
