import React, { FC } from "react";
import "./CheckBox.css";

interface CheckBoxProps {
    name: string;
    checked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    tooltip?: string;
}

const CheckBox: FC<CheckBoxProps> = ({ name, checked, onChange, tooltip }) => {
    return (
        <label
            className={`checkboxswitch ${tooltip ? " hasTooltip" : ""}`}
            data-tooltip={tooltip}
        >
            <input
                type="checkbox"
                name={name}
                checked={checked}
                onChange={onChange}
            />
            <span></span>
        </label>
    );
};

export default CheckBox;
