import React, { useRef } from "react";
import Setting from "../../components/Templates/Setting";
import { useUser } from "../../context/userContext";
import { useNotification } from "../../context/notificationContext";
import { useQueryClient } from "@tanstack/react-query";
import { isPasswordValid } from "../../util/validator";
import { setPassword } from "../../api/userApi";
import Loading from "../../components/Elements/Loading";
import CForm from "../../components/Inputs/CForm";
import SimpleInput from "../../components/Inputs/SimpleInput";

function Security() {
    const { user } = useUser();
    const { sendNotification } = useNotification();

    const currentPassword = useRef<HTMLInputElement>(null);
    const newPassword = useRef<HTMLInputElement>(null);
    const confirmPassword = useRef<HTMLInputElement>(null);

    const queryClient = useQueryClient();

    async function handlePasswordUpdate() {
        if (
            !currentPassword.current?.value ||
            !newPassword.current?.value ||
            !confirmPassword.current?.value
        ) {
            sendNotification("Please fill out all fields", "error");
            return;
        }

        if (newPassword.current.value !== confirmPassword.current.value) {
            sendNotification("Passwords do not match", "error");
            return;
        }

        if (!isPasswordValid(newPassword.current.value)) {
            sendNotification(
                "Password must be at least 8 characters long",
                "error"
            );
            return;
        }

        const data = {
            password: newPassword.current.value,
            confirmPassword: confirmPassword.current.value,
        };

        await setPassword(data).then((res) => {
            if (res) {
                queryClient.invalidateQueries(["user"]);
            }
        });
    }

    async function handleSetPassword() {
        if (!newPassword.current?.value || !confirmPassword.current?.value) {
            sendNotification("Please fill out all fields", "error");
            return;
        }

        if (newPassword.current.value !== confirmPassword.current.value) {
            sendNotification("Passwords do not match", "error");
            return;
        }

        if (!isPasswordValid(newPassword.current.value)) {
            sendNotification(
                "Password must be at least 8 characters long",
                "error"
            );
            return;
        }

        const data = {
            password: newPassword.current.value,
        };

        await setPassword(data).then((res) => {
            if (res) {
                queryClient.invalidateQueries(["user"]);
            }
        });
    }

    if (!user) return <Loading />;

    return (
        <Setting title="Security">
            {user.isGeneratedPassword ? (
                <CForm
                    button="Set Password"
                    onSubmit={handleSetPassword}
                    className="compact"
                >
                    <h5>Set your password</h5>
                    <SimpleInput
                        label="New Password"
                        type="password"
                        inputRef={newPassword}
                    />
                    <SimpleInput
                        label="Confirm New Password"
                        type="password"
                        inputRef={confirmPassword}
                    />
                </CForm>
            ) : (
                <CForm
                    button="Update Password"
                    onSubmit={handlePasswordUpdate}
                    className="compact"
                >
                    <h5>Change your password</h5>
                    <SimpleInput
                        label="Current Password"
                        type="password"
                        inputRef={currentPassword}
                    />
                    <SimpleInput
                        label="New Password"
                        type="password"
                        inputRef={newPassword}
                    />
                    <SimpleInput
                        label="Confirm New Password"
                        type="password"
                        inputRef={confirmPassword}
                    />
                </CForm>
            )}
        </Setting>
    );
}

export default Security;
