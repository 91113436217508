import { convertSVGColorsTohex, optimizeSvg } from "../SVG/svg";

async function getImageData(src: string) {
    const response = await fetch(src);
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("image/svg+xml")) {
        let svgString = await response.text();
        svgString = optimizeSvg(svgString);
        svgString = convertSVGColorsTohex(svgString);
        return {
            type: "svg",
            svgString: svgString,
        };
    } else {
        const blob = await response.blob();
        return {
            type: "image",
            blob: blob,
        };
    }
}

export default getImageData;
