import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createUser, getNewLemonsqueezeUser } from "../../api/userApi";
import SimpleInput from "../../components/Inputs/SimpleInput";
import { useUser } from "../../context/userContext";
import Portal from "../../components/Templates/Portal";
import Content from "../../components/Templates/Content";
import CForm from "../../components/Inputs/CForm";
import {
    isEmailValid,
    isPasswordValid,
    isTextValid,
} from "../../util/validator";
import { useNotification } from "../../context/notificationContext";
import Loading from "../../components/Elements/Loading";

export default function SignUp() {
    const { setToken } = useUser();
    const { sendNotification } = useNotification();
    const [checkedParams, setCheckedParams] = useState(false);
    const newFirstName = useRef<HTMLInputElement>(null);
    const newLastName = useRef<HTMLInputElement>(null);
    const newEmail = useRef<HTMLInputElement>(null);
    const newPassword = useRef<HTMLInputElement>(null);

    // const checkedParams = useRef(false);

    const urlParams = new URLSearchParams(window.location.search);
    const lmq_key = urlParams.get("lmq_key");

    useEffect(() => {
        if (!checkedParams) {
            if (lmq_key) {
                getNewLemonsqueezeUser(lmq_key).then((res) => {
                    if (!res) return;
                    setToken(res.data.token);
                });
                window.history.replaceState({}, document.title, "/");
            }
            setCheckedParams(true);
        }
    }, []);

    const navigate = useNavigate();

    async function handleSignUp() {
        const data = {
            firstName: newFirstName.current?.value ?? "",
            lastName: newLastName.current?.value ?? "",
            email: newEmail.current?.value ?? "",
            password: newPassword.current?.value ?? "",
        };

        if (!isTextValid(data.firstName) || !isTextValid(data.lastName)) {
            sendNotification(
                "Please enter a valid first and last name",
                "error"
            );
            return;
        }

        if (!isEmailValid(data.email)) {
            sendNotification("Please enter a valid email", "error");
            return;
        }

        if (!isPasswordValid(data.password)) {
            sendNotification(
                "Please enter a valid password 8 to 20 characters long",
                "error"
            );
            return;
        }

        await createUser(data).then((res) => {
            if (!res) return;
            setToken(res.data.token);
            // setLogedInCookie();
            // setEnableQuery(true);
            navigate("/");
        });
    }

    if (!checkedParams) return <Loading />;

    return (
        <Portal title="Create a Brand Set account">
            <Content>
                <h4>Create a Free Account</h4>
                <p>
                    One project free forever and all other Solo Features
                    included.
                </p>
                <p>
                    <b>No credit card needed.</b>
                </p>
            </Content>
            <CForm onSubmit={handleSignUp} button="Sign Up">
                <SimpleInput label="Email" type="email" inputRef={newEmail} />
                <div className="col col-2">
                    <SimpleInput
                        label="First Name"
                        type="text"
                        inputRef={newFirstName}
                    />
                    <SimpleInput
                        label="Last Name"
                        type="text"
                        inputRef={newLastName}
                    />
                </div>
                <SimpleInput
                    label="Password"
                    type="password"
                    inputRef={newPassword}
                />
            </CForm>
            <Content>
                <p>By continuing, you agree to our Terms Of Use</p>
                <p>
                    Already have an account? <Link to="/">Log in</Link>
                </p>
            </Content>
        </Portal>
    );
}
