import axios from "axios";
import { BsFile } from "../types/Folder";
import { confirm } from "../util/helper";

type CreateFolderRequest = {
    name: string;
    projectID: string;
    parentID?: string;
};
export const createFolder = async (data: CreateFolderRequest) => {
    return await axios.post(`/folder/`, data);
};

type UpdateFolderRequest = {
    name: string;
    folderID: string;
    projectID: string;
};
export const updateFolder = async (data: UpdateFolderRequest) => {
    return await axios.put(`/folder/update`, data);
};

export const getFolder = async (folderID: String) => {
    const res = await axios.get(`/folder/${folderID}`);
    return res.data;
};

export const deleteFolder = async (folderID: String) => {
    if (!confirm("Are you sure you want to delete this folder?")) return;
    return await axios.delete(`/folder/delete/${folderID}`);
};

type UploadFileRequest = {
    file: File;
    thumbnail: File | null;
    folderID: string;
    companyID: string;
    uploaded: string;
    lastModified: string;
    tags?: string[];
};
export const uploadFile = async ({
    data,
    config,
}: {
    data: UploadFileRequest;
    config: any;
}) => {
    return await axios.post("/folder/upload-file", data, config);
};

export const getFile = async ({
    fileID,
    folderID,
}: {
    fileID: string;
    folderID: string;
}) => {
    const res = await axios.get(`/folder/file/${folderID}/${fileID}`);
    return res.data as BsFile;
};

type DeleteFileRequest = {
    fileID: string[];
    folderID: string;
    companyID: string;
};
export const deleteFile = async (data: DeleteFileRequest) => {
    if (!confirm("Are you sure you want to delete this file?")) return;
    return await axios.put("/folder/delete-file", data);
};

export default axios;
