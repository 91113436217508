import React, { FC } from "react";
import ReactDOM from "react-dom";
import "./ContextMenu.css";
import { MenuState } from "./useContextMenu";

interface ContextMenuProps {
    children: React.ReactNode;
    menu: MenuState;
    width?: string;
    className?: string;
}

const ContextMenu: FC<ContextMenuProps> = ({
    children,
    menu,
    width = "180px",
    className = "",
}) => {
    if (!menu.enabled) return null;
    return ReactDOM.createPortal(
        <div
            id="contextmenu"
            className={`contextMenu dark ${className}`}
            style={
                {
                    left: `${menu.x}px`,
                    top: `${menu.y}px`,
                    visibility: `${menu.show ? "visible" : "hidden"}`,
                    "--width": `${width}`,
                } as React.CSSProperties
            }
        >
            {children}
        </div>,
        document.getElementById("contextmenuContainer") as Element
    );
};

export default ContextMenu;
