import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { deleteProject, removeAccess } from "../../api/projectApi";
import Loading from "../../components/Elements/Loading";
import Page from "../../components/Templates/Page";
import { useCompany } from "../../context/companyContext";
import Button from "../../components/Buttons/Button";
import ButtonGroup from "../../components/Buttons/ButtonGroup";
import { useProject } from "../../context/projectContext";
import Content from "../../components/Templates/Content";
import "./ProjectInfo.css";
import { toLinearGradient } from "../../util/Color/getGradients";
import { switchColor } from "../../util/colorHelper";
import { copy } from "../../util/helper";
import { useNotification } from "../../context/notificationContext";
import chroma from "chroma-js";
import List from "../../components/Templates/List";
import ShareProject from "../../Popups/ShareProject";

function ProjectInfo() {
    const { project, isValidSlug, isLoading, brandguide, logos } = useProject();
    const { clientOrgs } = useCompany();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { sendNotification } = useNotification();

    const [isOrgInviteFormOpen, setIsOrgInviteFormOpen] = useState(false);

    function handleRemoveAccess(clientID: string) {
        if (!project) return;
        const data = {
            companyID: clientID,
            projectID: project._id,
        };

        removeAccess(data).then(() => {
            queryClient.invalidateQueries(["projects"]);
        });
    }

    async function handleDeleteProject() {
        if (!project) return;
        deleteProject(project._id).then(() => {
            queryClient.invalidateQueries(["projects"]);
            navigate("/");
        });
    }

    if (isLoading || !project || !brandguide || logos.length === 0) {
        return <Loading />;
    }

    if (!isValidSlug) {
        return <Page title="404">Project not found</Page>;
    }

    return (
        <Page
            title={project.name}
            subtitle={"Project Overview"}
            buttons={
                <>
                    {/* <CloneProject /> */}
                    <Button
                        onClick={handleDeleteProject}
                        icon="delete"
                        tooltip="Delete Project"
                    />
                </>
            }
        >
            <Content className="projectSummery">
                <div className="logosPreview primary">
                    <img src={logos[0].fileUrl} alt="Primary Logo" />
                    <img src={logos[1].fileUrl} alt="Dark Logo" />
                    <img src={logos[2].fileUrl} alt="Light Logo" />
                </div>
                {logos.length > 3 && (
                    <div className="logosPreview logomark">
                        <img src={logos[3].fileUrl} alt="Secondary logomark" />
                        <img src={logos[4].fileUrl} alt="Dark logomark" />
                        <img src={logos[5].fileUrl} alt="Light logomark" />
                    </div>
                )}
                <div className="colorsPreview">
                    {brandguide.colors.map((color, i) => {
                        const rgb = chroma(color.code).rgb().join(", ");
                        const oklch = chroma(color.code)
                            .oklch()
                            .map((v) => v.toFixed(2))
                            .join(", ");

                        return (
                            <div
                                className="color"
                                key={"color-" + i}
                                style={
                                    {
                                        backgroundColor: color.code,
                                        "--text_color": switchColor(color.code),
                                    } as React.CSSProperties
                                }
                            >
                                <div className="colorDetails">
                                    <h5>{color.name}</h5>
                                    <p
                                        className="uc copy"
                                        onClick={() =>
                                            copy(color.code, sendNotification)
                                        }
                                    >
                                        {color.code}
                                    </p>
                                    <p
                                        className="uc copy"
                                        onClick={() =>
                                            copy(rgb, sendNotification)
                                        }
                                    >
                                        RGB {rgb}
                                    </p>
                                    <p
                                        className="uc copy"
                                        onClick={() =>
                                            copy(oklch, sendNotification)
                                        }
                                    >
                                        OKLCH {oklch}
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </div>
                {brandguide.gradients.length > 0 && (
                    <div className="gradientPreview">
                        {brandguide.gradients.map((g, i) => {
                            return (
                                <div
                                    className="gradient floatingContainer"
                                    key={"gradient-" + i}
                                    style={{
                                        backgroundImage: toLinearGradient(g),
                                    }}
                                >
                                    <Button
                                        icon="code"
                                        className="floating top_right"
                                        onClick={() =>
                                            copy(
                                                toLinearGradient(g),
                                                sendNotification
                                            )
                                        }
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
            </Content>
            <Content className="cardStyle">
                <List
                    title={
                        !!project.sharedWith.length
                            ? "Shared With"
                            : "Share your project"
                    }
                    buttonGroup={
                        <ShareProject
                            project={project}
                            btnName="Invite Org"
                            btnTooltip=""
                        />
                    }
                    listClassName="compact"
                >
                    {clientOrgs &&
                        !!project.sharedWith.length &&
                        project.sharedWith.map((org) => {
                            const orgData = clientOrgs.find(
                                (orgData) => orgData._id === org
                            );
                            if (orgData) {
                                return (
                                    <li className="org" key={org}>
                                        <p className="orgName">
                                            {orgData.name}
                                        </p>
                                        <ButtonGroup>
                                            <Button
                                                icon="delete"
                                                name="Remove"
                                                onClick={() =>
                                                    handleRemoveAccess(org)
                                                }
                                            />
                                        </ButtonGroup>
                                    </li>
                                );
                            }
                        })}
                </List>
            </Content>
        </Page>
    );
}

export default ProjectInfo;
