import React from "react";
import Button from "../Buttons/Button";
import { useNotification } from "../../context/notificationContext";
import "./Notifications.css";

const Notifications = () => {
    const { notifications, removeNotification } = useNotification();

    return (
        <div className="notifications">
            {notifications.map((n: any) => {
                return (
                    <div key={n.id} className={n.type + " notification"}>
                        <span className={`icon csic csic-${n.type}`}></span>
                        <p>{n.message}</p>
                        <Button
                            icon="close"
                            className="close floating"
                            onClick={() => removeNotification(n.id)}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default Notifications;
