import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import "./Button.css";

interface NavLinkButtonProps {
    link: string;
    className?: string;
    icon?: string;
    imageIcon?: string;
    iconOnly?: boolean;
    tooltip?: string;
    children?: string;
}

const NavLinkButton: FC<NavLinkButtonProps> = ({
    link,
    icon,
    imageIcon,
    className = "",
    children,
    tooltip = "",
}) => {
    return (
        <NavLink
            className={`button navLinkButton ${className} ${
                !!tooltip ? "hasTooltip" : ""
            }`}
            to={link}
            aria-label={tooltip}
            data-tooltip={tooltip}
        >
            {!!imageIcon && (
                <img className="imageIcon" src={imageIcon} alt={children} />
            )}
            {!!icon && <span className={`icon csic csic-${icon}`}></span>}
            {!!children && <span className="text">{children}</span>}
        </NavLink>
    );
};

export default NavLinkButton;
